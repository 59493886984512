import getConfig from "@/utils/getConfig";
import {OverwolfApi, OWGame, OWGames} from "@devour/client";
import {useQuery} from "@tanstack/react-query";

async function fetchOverwolfGameInfo(gameId: string): Promise<OWGames> {
    return await new OverwolfApi(getConfig()).getOverwolfGame({
        id: gameId,
    });
}

function useGetOverwolfGameInfoQuery(gameId: string) {
    return {
        queryKey: [
            "overwolf-game-info",
            gameId,
        ],
        queryFn: () => gameId ? fetchOverwolfGameInfo(gameId) : null,
        staleTime: 30 * 24 * 60 * 60000,
        enabled: !!gameId,
    } as const;
}

export default function useGetOverwolfGameInfo(gameId: string) {
    return useQuery<OWGame>(useGetOverwolfGameInfoQuery(gameId));
}
