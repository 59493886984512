import { useSelector } from "react-redux";
import useGetOverwolfGameInfo from "./useGetOverwolfGameInfo";
import { IStore } from "@/redux/defaultStore";
import { isMobile } from "react-device-detect";

export enum OverwolfArtType {
    logo = "logo",
    devourLogo = "devourLogo",
    bannerImage = "bannerImage",
    landingImage = "landingImage",
    thumbnailImage = "thumbnailImage",
}


const defaultAssets = {
    logo: `${import.meta.env.VITE_CDN_URL}/images/DevourPlayLogoWhite.webp`,
    devourLogo: `${import.meta.env.VITE_CDN_URL}/images/DevourPlayLogoWhite.webp`,
    bannerImage: `${import.meta.env.VITE_CDN_URL}/images/ow-time-govip-bg${isMobile ? "-sm" : ""}.webp`,
    landingImage: `${import.meta.env.VITE_CDN_URL}/images/Time-Based-Game-Landing-${isMobile ? "Sm" : "Lg"}.webp`,
    thumbnailImage: "",
};


export default function useGetOverwolfArt() {
    const owGameInfo = useSelector((store: IStore) => store.metaStore.overwolfGameInfo);
    const {
        data: overwolfGameInfo,
    } = useGetOverwolfGameInfo(owGameInfo?.gameId);
    const hasCustomLogo = !!overwolfGameInfo?.logo;
    const getOverwolfArt = (artType: OverwolfArtType) => {

        if (!overwolfGameInfo?.gameId) {
            if (defaultAssets[artType]) {
                return defaultAssets[artType];
            }
            return "";
        }

        switch (artType) {
            case OverwolfArtType.bannerImage:
                return overwolfGameInfo?.bannerImage[isMobile ? "small" : "large"] ?? defaultAssets.bannerImage;
            case OverwolfArtType.landingImage:
                return overwolfGameInfo?.landingImage[isMobile ? "small" : "large"] ?? defaultAssets.landingImage;
            case OverwolfArtType.logo:
                return overwolfGameInfo?.logo ?? defaultAssets.logo;
            case OverwolfArtType.devourLogo:
                return overwolfGameInfo?.devourGameLogo ?? defaultAssets.devourLogo;
            case OverwolfArtType.thumbnailImage:
                return overwolfGameInfo?.thumbnailImage ?? defaultAssets.thumbnailImage;
            default:
                return "";
        }

    };
    return { getOverwolfArt,
        hasCustomLogo };
}
