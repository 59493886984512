import {ReactElement, useState} from "react";
import GoVipAllPlay2EarnGamesModal from "../modals/GoVipAllPlay2EarnGamesModal";
import GoVipGames from "./GoVipGames";

function GoVipPlay2Earn(): ReactElement {
    const [
        showAllGamesModal,
        setShowAllGamesModal,
    ] = useState<boolean>(false);

    function toggleAllGamesModal() {
        setShowAllGamesModal(!showAllGamesModal);
    }

    return (
        <>
            <GoVipAllPlay2EarnGamesModal
                isOpen={showAllGamesModal}
                toggle={toggleAllGamesModal}
            />
            <div className="go-vip-play2earn">
                <div className="go-vip-play2earn_header">
                    <div className="go-vip-play2earn_header_row">
                        <h2>
							Play2Earn
                        </h2>
                        <div
                            className="go-vip-play2earn_header_show-all-link-container"
                            onClick={toggleAllGamesModal}
                        >
                            <p className="go-vip-play2earn_header_show-all-link-container_link">
								Show all
                            </p>
                        </div>
                    </div>
                    <p className="go-vip-play2earn_header_subheader">
						Progress in each game to boost your XP!
                    </p>
                </div>

                <div className="go-vip-play2earn_body">
                    <GoVipGames/>
                </div>
            </div>
        </>
    );
}

export default GoVipPlay2Earn;
