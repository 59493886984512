/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MenuOrderStatus {
    CART = 'CART',
    CHECKOUT = 'CHECKOUT',
    PAYMENT = 'PAYMENT',
    ERRORPAYMENT = 'ERROR_PAYMENT',
    PAID = 'PAID',
    PROCESSING = 'PROCESSING',
    RECEIVED = 'RECEIVED',
    DELIVERYSERVICE = 'DELIVERY_SERVICE',
    DRIVERASSIGNED = 'DRIVER_ASSIGNED',
    DRIVERPICKUP = 'DRIVER_PICKUP',
    OUTFORDELIVERY = 'OUT_FOR_DELIVERY',
    DRIVERATDESTINATION = 'DRIVER_AT_DESTINATION',
    DELIVERED = 'DELIVERED',
    CUSTOMERPICKUP = 'CUSTOMER_PICKUP',
    CUSTOMERPICKEDUP = 'CUSTOMER_PICKED_UP',
    OPENISSUE = 'OPEN_ISSUE',
    RESOLVED = 'RESOLVED',
    ERRORDEVOUR = 'ERROR_DEVOUR',
    ERRORDEVOURIQ = 'ERROR_DEVOURIQ',
    ERRORRESTAURANT = 'ERROR_RESTAURANT',
    ERRORDELIVERY = 'ERROR_DELIVERY',
    SYSTEMCANCELLED = 'SYSTEM_CANCELLED',
    CUSTOMERCANCELLED = 'CUSTOMER_CANCELLED',
    CHATINITIATED = 'CHAT_INITIATED',
    REFUNDED = 'REFUNDED',
    AUTOREFUNDED = 'AUTO_REFUNDED'
}

export function MenuOrderStatusFromJSON(json: any): MenuOrderStatus {
    return MenuOrderStatusFromJSONTyped(json, false);
}

export function MenuOrderStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): MenuOrderStatus {
    return json as MenuOrderStatus;
}

export function MenuOrderStatusToJSON(value?: MenuOrderStatus | null): any {
    return value as any;
}

