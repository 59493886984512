import {ButtonHTMLAttributes, ReactElement, useContext, useEffect, useState} from "react";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "@/components/modals/autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import {
    ActiveDpay,
    ActivePaymentMethod,
    getMenuOrderPaymentMethod,
} from "@/utils/getMenuOrderPaymentMethod";
import Spacer from "@/components/Spacer";
import FrameButton from "@/components/buttons/FrameButton";
import {isDesktop, isTablet} from "react-device-detect";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {roundNumber} from "@/utils/roundNumber";
import CheckoutPaymentDpaySkeleton
    from "@/components/checkout/checkoutPayments/CheckoutPaymentDpaySkeleton";
import CheckoutPaymentInsufficientDpayModal
    from "@/components/checkout/checkoutPayments/CheckoutPaymentInsufficientDpayModal";
import {useAccount} from "wagmi";
import CheckoutWalletConnectModal
    from "@/components/checkout/checkoutPayments/CheckoutWalletConnectModal";
import {truncateMiddle} from "@/utils/truncateMiddle";
import CheckoutPaymentVdpayCard
    from "@/components/checkout/checkoutPayments/CheckoutPaymentVdpayCard";
import CheckoutPaymentMagicCard
    from "@/components/checkout/checkoutPayments/CheckoutPaymentMagicCard";
import CheckoutPaymentExternalCard
    from "@/components/checkout/checkoutPayments/CheckoutPaymentExternalCard";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {useGetTransactions} from "@/hooks/useGetTransactions";
import CheckoutPaymentLoadDpayModal
    from "@/components/checkout/checkoutPayments/CheckoutPaymentLoadDPayModal";
import {addError} from "@/redux/meta/metaActions";
import {MenuOrdersApi} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import {useGate} from "statsig-react";

interface Props {
    isOpen: boolean;
    toggle: () => void;
    showDpayContent: boolean;
    magicBalance: number;
    externalDpayBalance: number;
    resetCart: () => Promise<void>;
    isMagicConnected: boolean;
}

export const WALLET_ADDRESS_TRUNCATION = 12;

function CheckoutPaymentDpayModal(props: Props): ReactElement {

    const {value: onChainStatus} = useGate(import.meta.env.VITE_TOKEN_STATSIG_ONCHAIN_STATUS);
    const {menuOrderId} = useContext(RestaurantContext);

    const account = useAccount();
    const dispatch = useDispatch();

    const currentUser = useSelector((store: IStore) => store.metaStore?.currentUser);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    const [activeDpayMethod, setActiveDpayMethod] = useState<ActiveDpay>(undefined);
    const [showInsufficientModal, setShowInsufficientModal] = useState<boolean>(false);
    const [showWalletConnectModal, setShowWalletConnectModal] = useState<boolean>(false);
    const [showLoadDpayModal, setShowDpayLoadModal] = useState<boolean>(false);
    const [isUpdateActive, setIsUpdateActive] = useState<boolean>(false);

    const {data: menuOrder, refetch: refetchMenuOrder, isLoading: menuOrderIsLoading} = useMenuOrder(menuOrderId);
    const {data: transactionData, isLoading: transactionDataIsLoading} = useGetTransactions(fullToken, currentUser?.user?.id);
    const menuOrderPaymentMethod = getMenuOrderPaymentMethod(menuOrder, undefined, account, transactionData);

    const insufficientVdpay = transactionData && menuOrder
        ? convertDpayToFiat(transactionData.balance) < menuOrder.grandTotal
        : true;

    const insufficientMagic = menuOrder && props.magicBalance != null // loose equality to check if props.magicBalance is not null or undefined
        ? menuOrder.grandTotal > convertDpayToFiat(props.magicBalance)
        : true;

    const insufficientExternal = menuOrder && props.externalDpayBalance != null && account.isConnected
        ? menuOrder.grandTotal > convertDpayToFiat(props.externalDpayBalance)
        : true;

    useEffect(() => {
        // Set initial DPAY method
        if (!activeDpayMethod && menuOrderPaymentMethod?.dpayChain !== undefined) {
            setActiveDpayMethod(menuOrderPaymentMethod.dpayChain);
        } else if (!menuOrderPaymentMethod?.dpayChain) {
            // Reset when countdown is reset, which resets menu order's payment method
            setActiveDpayMethod(undefined);
        }
    }, [menuOrderPaymentMethod]);

    /*
     * When user disconnects wallet, close the wallet connect modal
     * so that user can't switch to a different wallet in the same modal session.
     *
     */
    useEffect(() => {
        if (!account.isConnected) {
            setShowWalletConnectModal(false);
        }
    }, [account.isConnected, props.externalDpayBalance]);

    /**
     * Checks to see if the saved DPAY method is still valid, if not then reset card and remove.
     * This function only runs on modal toggle close, due to the latency it takes on-chain wallets
     * to populate with balance.
     */
    async function removeSavedPayment(): Promise<void> {
        if (menuOrderPaymentMethod && menuOrderPaymentMethod.method === ActivePaymentMethod.DPAY && menuOrder?.dpay) {
            if (!menuOrder.onChainDpay && insufficientVdpay) {
                await resetCartWrapper();

            } else if (menuOrder.onChainDpay) {
                if (menuOrder.isMagicWallet && insufficientMagic || !menuOrder.isMagicWallet && insufficientExternal) {
                    await resetCartWrapper();
                }
            }
        }
    }

    /**
     * Wrapper for toggle function.
     */
    async function onToggle(): Promise<void> {
        props.toggle();
        await removeSavedPayment();
    }

    /**
     * Handles the onClick event when a DPAY method is selected.
     * @param method
     */
    async function handleMethodOnClick(method: ActiveDpay): Promise<void> {

        if (method === ActiveDpay.EXTERNAL) {
            if (!account.isConnected) {
                toggleWalletConnectModal();
            } else if (!insufficientExternal) {
                await handleUpdateCart(method);
            }

        } else {
            let insufficient: boolean;

            if (method === ActiveDpay.MAGIC) {
                insufficient = insufficientMagic;
            } else {
                insufficient = insufficientVdpay;
            }

            if (insufficient && !transactionDataIsLoading && !menuOrderIsLoading) {
                if (method !== ActiveDpay.MAGIC) {
                    void onToggle();
                    toggleInsufficientModal(); // Insufficient On-account DPAY modal
                }
            } else {
                await handleUpdateCart(method);
            }
        }
    }

    async function resetCartWrapper() {
        try {
            setIsUpdateActive(true);
            await props.resetCart();

        } catch (e) {
            dispatch(await addError(e));
        } finally {
            setIsUpdateActive(false);
        }
    }

    async function updateCartWithDpayMethod(method: ActiveDpay): Promise<void> {
        try {
            setIsUpdateActive(true);
            let balance;
            switch (method) {
                case ActiveDpay.VDPAY:
                    balance = transactionData.balance;
                    break;
                case ActiveDpay.MAGIC:
                    balance = props.magicBalance;
                    break;
                default:
                    balance = props.externalDpayBalance;
                    break;
            }

            const totalStripeTotalInDpay = convertFiatToDpay(menuOrder.grandTotal, balance);

            await new MenuOrdersApi(getConfig()).updateMenuOrder({
                id: menuOrder.id,
                createMenuOrderBody: {
                    paymentMethodId: "",
                    isCoinbase: false,
                    dpay: totalStripeTotalInDpay,
                    vdpay: method === ActiveDpay.VDPAY
                        ? totalStripeTotalInDpay
                        : 0,
                    onChainDpay: method !== ActiveDpay.VDPAY
                        ? totalStripeTotalInDpay
                        : 0,
                    isMagicWallet: method === ActiveDpay.MAGIC,
                },
            });

            await refetchMenuOrder();

        } catch (e) {
            dispatch(await addError(e));
        } finally {
            setIsUpdateActive(false);
        }
    }

    async function handleUpdateCart(method: ActiveDpay): Promise<void> {
        if (activeDpayMethod === method) {
            await resetCartWrapper();
            setActiveDpayMethod(undefined);
        } else {
            await updateCartWithDpayMethod(method);
            setActiveDpayMethod(method);
        }
    }

    /**
     * Converts DPAY value to USD.
     * @param dpay
     */
    function convertDpayToFiat(dpay: number): number {
        return roundNumber(dpay * menuOrder.dpayFiatAtOrderTime);
    }

    /**
     * Converts fiat to DPAY by flooring the number to an integer.
     * If the calculated DPAY exceeds the balance by a cent or less, then return the balance instead.
     * This is to prevent rounding locking users out of completing their purchase.
     *
     * @param fiat
     * @param dpayBalance
     */
    function convertFiatToDpay(fiat: number, dpayBalance: number) {

        const calculatedDpay = fiat / menuOrder.dpayFiatAtOrderTime;
        const dpayPerCent = 0.01 / menuOrder.dpayFiatAtOrderTime;

        if (calculatedDpay &&
            calculatedDpay - dpayBalance > 0 &&
            calculatedDpay - dpayBalance <= dpayPerCent) {
            return dpayBalance;
        }

        return calculatedDpay;
    }

    function toggleInsufficientModal(): void {
        setShowInsufficientModal(!showInsufficientModal);
    }

    function toggleWalletConnectModal(): void {
        setShowWalletConnectModal(!showWalletConnectModal);
    }

    function toggleLoadDpayModal(): void {
        setShowDpayLoadModal(!showLoadDpayModal);
    }

    return (
        <>
            <CheckoutPaymentInsufficientDpayModal
                isOpen={showInsufficientModal}
                toggle={toggleInsufficientModal}
                toggleLoadDpayModal={toggleLoadDpayModal}
            />
            <CheckoutWalletConnectModal
                isOpen={showWalletConnectModal}
                toggle={toggleWalletConnectModal}
                resetCartWrapper={resetCartWrapper}
                insufficientExternal={insufficientExternal}
                setActiveDpayMethod={setActiveDpayMethod}
            />
            <CheckoutPaymentLoadDpayModal
                isOpen={showLoadDpayModal}
                toggle={toggleLoadDpayModal}
            />

            <FrameOneAutoPanel
                isOpen={props.isOpen}
                toggle={onToggle}
                contentClassName="checkout-payments-dpay-modal"
                modalOnTablet={true}
                size="sm2"
                disableOverlayDismiss={isUpdateActive}
            >
                <FrameAutoPanelHeader
                    title={`Select ${import.meta.env.VITE_TOKEN_NAME} Method`}
                    isTitleBold={true}
                    toggle={isUpdateActive
                        ? null
                        : onToggle}
                    tooltipContent={`You can choose ${import.meta.env.VITE_TOKEN_NAME} as your payment method either through your account or on-chain.
                        In case of refunds, the ${import.meta.env.VITE_TOKEN_NAME} will always be returned to your account balance, regardless of the
                        option you initially selected.`}
                >
                    <hr/>
                </FrameAutoPanelHeader>

                <FrameAutoPanelBody className="checkout-payments-dpay-modal_body">

                    {!props.showDpayContent || isUpdateActive
                        ? <CheckoutPaymentDpaySkeleton/>
                        : <>
                            <div className="checkout-payments-dpay-modal_body_title-row">
                                <p className="checkout-payments-dpay-modal_body_title-row_subheader">
                                    On-Account {import.meta.env.VITE_TOKEN_NAME}
                                </p>
                                {(isDesktop || isTablet) &&
                                    <div
                                        className="checkout-payments-dpay-modal_body_title-row_tag">
                                        <p className="checkout-payments-dpay-modal_body_title-row_subheader">
                                            Default
                                        </p>
                                    </div>
                                }
                                <Spacer/>
                                <FrameButton
                                    <ButtonHTMLAttributes<HTMLButtonElement>>
                                    color="purple"
                                    size="narrow"
                                    onClick={toggleLoadDpayModal}
                                >
                                    Load {import.meta.env.VITE_TOKEN_NAME}
                                </FrameButton>
                            </div>

                            <CheckoutPaymentVdpayCard
                                insufficientVdpay={insufficientVdpay}
                                activeDpayMethod={activeDpayMethod}
                                handleMethodOnClick={async () => {
                                    await handleMethodOnClick(ActiveDpay.VDPAY);
                                }}
                                dpayFiat={convertDpayToFiat(transactionData.balance)}
                            />

                            {onChainStatus &&
                                <>
                                    <p className="checkout-payments-dpay-modal_body_title-row_subheader">
                                        On-Chain {import.meta.env.VITE_TOKEN_NAME}
                                    </p>

                                    <div
                                        className="checkout-payments_method-container checkout-payments-dpay-modal_body_dpay-container"
                                    >
                                        <CheckoutPaymentMagicCard
                                            insufficientMagic={insufficientMagic}
                                            activeDpayMethod={activeDpayMethod}
                                            handleMethodOnClick={async () => {
                                                await handleMethodOnClick(ActiveDpay.MAGIC);
                                            }}
                                            isUnavailable={!props.isMagicConnected}
                                        />

                                        <div
                                            className="checkout-payments-dpay-modal_body_wc-container">

                                            <CheckoutPaymentExternalCard
                                                insufficientExternal={insufficientExternal}
                                                activeDpayMethod={activeDpayMethod}
                                                handleMethodOnClick={async () => {
                                                    await handleMethodOnClick(ActiveDpay.EXTERNAL);
                                                }}
                                                wcTag={account?.address
                                                    ? truncateMiddle(account.address, WALLET_ADDRESS_TRUNCATION)
                                                    : ""}
                                            />

                                            {(activeDpayMethod === ActiveDpay.EXTERNAL || insufficientExternal) &&
                                                <FrameButton
                                                    <ButtonHTMLAttributes<HTMLButtonElement>>
                                                    color="gray"
                                                    size="narrow"
                                                    className="checkout-payment-option_button"
                                                    onClick={toggleWalletConnectModal}
                                                >
                                                    Edit
                                                </FrameButton>
                                            }

                                        </div>

                                    </div>
                                </>
                            }
                        </>
                    }

                </FrameAutoPanelBody>

            </FrameOneAutoPanel>
        </>
    );
}

export default CheckoutPaymentDpayModal;
