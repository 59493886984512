import {ReactElement, useState} from "react";
import {Address, AddressBook, HandoffOptions} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import RestaurantAddressAutoPanelAddressBook from "./RestaurantAddressAutoPanelAddressBook";
import RestaurantAddressAutoPanelAutocomplete from "./RestaurantAddressAutoPanelAutocomplete";
import ManageAddressBookUpdateAddressModal
    from "@/components/modals/ManageAddressBookUpdateAddressModal";
import RestaurantAddressAutoPanelGeolocate
    from "@/pages/restaurants/components/RestaurantAddressAutoPanelGeolocate";
import Skeleton from "@/components/skeletons/Skeleton";
import HandoffToggle from "@/components/HandoffToggle";
import {isMobile} from "react-device-detect";
import {toggleOrderHandoff} from "@/redux/meta/metaActions";
import FrameOneModal from "@/components/modals/modalComponents/FrameOneModal";
import FrameModalHeader from "@/components/modals/modalComponents/FrameModalHeader";
import FrameModalBody from "@/components/modals/modalComponents/FrameModalBody";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onAddressSubmit?: (address: Address | AddressBook) => void;
    defaultAddressBook?: AddressBook;
    title?: string;
    showToggleDeliveryMode?: boolean;
    modalOnTablet?: boolean;
    modalOnMobile?: boolean;
}

function RestaurantAddressAutoPanel(props: Props): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const { isOnOverwolf } = useOverwolfInterop();
    const [
        isAddressUpdating,
        setIsAddressUpdating,
    ] = useState<boolean>(false);
    const [
        updateFormModal,
        setUpdateFormModal,
    ] = useState<boolean>(false);
    const [
        selectedAddressBook,
        setSelectedAddressBook,
    ] = useState<AddressBook>();

    const handoff = useSelector((store: IStore) => store.metaStore.handoff);
    const dispatch = useDispatch();

    function autocompleteCallback(newAddress: Address) {
        if (fullToken) {
            setSelectedAddressBook({
                ...newAddress,
                isDefault: false,
                id: undefined,
                handoffInstructions: undefined,
                user: undefined,
            });
            setUpdateFormModal(true);
        } else {
            props.onAddressSubmit?.(newAddress);
        }
    }

    async function onSaveAddressBook(address: AddressBook): Promise<void> {
        setUpdateFormModal(false);
        setIsAddressUpdating(true);
        await props.onAddressSubmit?.(address);
        setIsAddressUpdating(false);
    }

    function onUpdateAddress(address: AddressBook): void {
        setSelectedAddressBook(address);
        setUpdateFormModal(true);
    }

    function submitAddressCallback(address: Address): void {
        props.onAddressSubmit?.(address);
    }

    return (
        <>
            <ManageAddressBookUpdateAddressModal
                isOpen={updateFormModal}
                onClose={() => setUpdateFormModal(false)}
                onSave={onSaveAddressBook}
                addressBook={selectedAddressBook}
            />

            <FrameOneModal
                size="sm"
                isOpen={props.isOpen}
                toggle={props.onClose}
                contentClassName="restaurant-search-address-bottom-panel"
            >
                <FrameModalHeader
                    title={props.title || handoff === HandoffOptions.DELIVERY
                        ? "Deliver to"
                        : "Pickup from"}
                    toggle={props.onClose}
                    showLeftChevron={true}
                />

                <FrameModalBody className="restaurant-search-address-bottom-panel_body">

                    <div className="restaurant-search-address-bottom-panel_body_divider"/>

                    {props.showToggleDeliveryMode && isMobile &&
                        <div className="restaurant-search-address-bottom-panel_toggle">
                            <HandoffToggle
                                onHandoffToggle={(option: HandoffOptions) => dispatch(toggleOrderHandoff(option))}
                            />
                        </div>
                    }

                    <RestaurantAddressAutoPanelAutocomplete
                        autocompleteCallback={autocompleteCallback}
                    />
                    {!isOnOverwolf && <RestaurantAddressAutoPanelGeolocate
                        submitAddressCallback={submitAddressCallback}
                    />}
                    {isAddressUpdating
                        ? <Skeleton
                            rows={3}
                            classes="checkout-details_content_skeleton"
                        />
                        : <RestaurantAddressAutoPanelAddressBook
                            onAddressBookSubmit={onSaveAddressBook}
                            defaultSelectedAddressId={props.defaultAddressBook?.id}
                            onUpdateAddress={onUpdateAddress}
                        />
                    }

                </FrameModalBody>
            </FrameOneModal>
        </>
    );
}

export default RestaurantAddressAutoPanel;
