import {ReactElement, useEffect, useState} from "react";
import CountUp from "react-countup";
import {useSelector, useDispatch} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import {v4 as uuid} from "uuid";
import GoVipHeaderDpayCard from "./GoVipHeaderDpayCard";
import GoVipLevelsModal from "../modals/GoVipLevelsModal";
import GoVipHeaderUserDetails from "./GoVipHeaderUserDetails";
import GoVipExtendedExperienceBar from "./GoVipExtendedExperienceBar";
import GoVipRankButton from "./GoVipRankButton";
import SignUpToast from "../SignUpToast";
import {useGate} from "statsig-react";

function GoVipHeaderAccountLevel(): ReactElement {

    const accountLevel = useSelector((store: IStore) => store.metaStore.accountLevel);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {value: dpayStatus} = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);

    const [
        counterStart,
        setCounterStart,
    ] = useState<number>(accountLevel
        ? accountLevel.experienceGainedAtThisLevel
        : 0);
    const [
        counterEnd,
        setCounterEnd,
    ] = useState<number>(accountLevel
        ? accountLevel.experienceGainedAtThisLevel
        : 0);
    const [
        pointGain,
        setPointGain,
    ] = useState<number>(0);
    const [
        showGoVipLevelsModal,
        setShowGoVipLevelsModal,
    ] = useState<boolean>(false);

    const [
        showToast,
        setShowToast,
    ] = useState<boolean>(false);

    function handleToastDismissal() {
        setShowToast(false);
    }

    useEffect(() => {
        if (fullToken && accountLevel) {
            setPointGain(accountLevel.experienceGainedAtThisLevel - counterEnd);
            setCounterStart(counterEnd);
            setCounterEnd(accountLevel.experienceGainedAtThisLevel);
        }
    }, [
        fullToken?.token,
        accountLevel,
    ]);

    useEffect(() => {
        // reset state after showing so animation can replay
        if (pointGain > 0) {
            setTimeout(() => setPointGain(0), 1000);
        }
    }, [pointGain]);

    function toggleGoVipPrizesModal(): void {
        setShowGoVipLevelsModal(!showGoVipLevelsModal);
    }

    return (
        <>
            <SignUpToast
                isOpen={showToast}
                onDismiss={handleToastDismissal}
            />
            <GoVipLevelsModal
                isOpen={showGoVipLevelsModal}
                toggle={toggleGoVipPrizesModal}
            />

            <div className="go-vip-top_header_exp">

                <div className="go-vip-top_header_desktop-adjust">

                    <div className="go-vip-top_header_desktop-adjust_left-container">
                        <GoVipHeaderUserDetails/>
                        <div className="go-vip-top_header_desktop-adjust_left-container_progress">
                            <div className="go-vip-top_header_exp_counter-container">
                                <CountUp
                                    className="go-vip-top_header_exp_counter-container_counter"
                                    start={counterStart}
                                    end={counterEnd}
                                />
                                <p className="go-vip-top_header_exp_counter-container_points">
                                    XP
                                </p>

                                {pointGain > 0 &&
                                    <p key={uuid()}
                                        className="go-vip-top_header_exp_counter-container_animated-points">
                                        {`+${pointGain}`}
                                    </p>
                                }
                                <div className="go-vip-top_header_top-row-container_spacer"/>
                                <GoVipRankButton/>
                            </div>
                            <GoVipExtendedExperienceBar/>
                        </div>

                    </div>

                    {(isDesktop || isTablet) && dpayStatus &&
												<div className="go-vip-top_header_desktop-adjust_right-container">
												    <GoVipHeaderDpayCard/>
												</div>
                    }

                </div>

                {isMobile && !isTablet && <GoVipHeaderDpayCard/>}
            </div>
        </>
    );
}

export default GoVipHeaderAccountLevel;
