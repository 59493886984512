/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccountExperienceTransactionSubject,
    AccountExperienceTransactionSubjectFromJSON,
    AccountExperienceTransactionSubjectFromJSONTyped,
    AccountExperienceTransactionSubjectToJSON,
    AccountExperienceTransactionType,
    AccountExperienceTransactionTypeFromJSON,
    AccountExperienceTransactionTypeFromJSONTyped,
    AccountExperienceTransactionTypeToJSON,
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Represents a transaction in the account experience section.
 * @export
 * @interface AccountExperienceTransaction
 */
export interface AccountExperienceTransaction {
    /**
     * 
     * @type {string}
     * @memberof AccountExperienceTransaction
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof AccountExperienceTransaction
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof AccountExperienceTransaction
     */
    updatedAt: number;
    /**
     * 
     * @type {AccountExperienceTransactionSubject}
     * @memberof AccountExperienceTransaction
     */
    subject: AccountExperienceTransactionSubject;
    /**
     * 
     * @type {string}
     * @memberof AccountExperienceTransaction
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountExperienceTransaction
     */
    adminNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountExperienceTransaction
     */
    user: string;
    /**
     * 
     * @type {AccountExperienceTransactionType}
     * @memberof AccountExperienceTransaction
     */
    type: AccountExperienceTransactionType;
    /**
     * 
     * @type {number}
     * @memberof AccountExperienceTransaction
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof AccountExperienceTransaction
     */
    community?: string;
    /**
     * The ID of the Overwolf reward that triggered this transaction.
     * @type {string}
     * @memberof AccountExperienceTransaction
     */
    overwolfRewardId: string;
}

export function AccountExperienceTransactionFromJSON(json: any): AccountExperienceTransaction {
    return AccountExperienceTransactionFromJSONTyped(json, false);
}

export function AccountExperienceTransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountExperienceTransaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'subject': AccountExperienceTransactionSubjectFromJSON(json['subject']),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'adminNotes': !exists(json, 'adminNotes') ? undefined : json['adminNotes'],
        'user': json['user'],
        'type': AccountExperienceTransactionTypeFromJSON(json['type']),
        'amount': json['amount'],
        'community': !exists(json, 'community') ? undefined : json['community'],
        'overwolfRewardId': json['overwolfRewardId'],
    };
}

export function AccountExperienceTransactionToJSON(value?: AccountExperienceTransaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'subject': AccountExperienceTransactionSubjectToJSON(value.subject),
        'notes': value.notes,
        'adminNotes': value.adminNotes,
        'user': value.user,
        'type': AccountExperienceTransactionTypeToJSON(value.type),
        'amount': value.amount,
        'community': value.community,
        'overwolfRewardId': value.overwolfRewardId,
    };
}


