import {ReactElement, ReactNode, useState} from "react";
import FrameButton from "@/components/buttons/FrameButton";
import {AddressBook, OrderDiscount, Restaurant} from "@devour/client";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import FrameOneSwitchInput from "@/components/inputs/FrameOneSwitchInput";
import {useGetGoFrensRewards} from "@/hooks/useGetGoFrensRewards";
import {isDesktop, isTablet} from "react-device-detect";
import GoFrensCardsSkeleton from "@/components/skeletons/GoFrensPage/GoFrensCardsSkeleton";
import Skeleton from "react-loading-skeleton";
import Toast from "@/components/Toast";
import {useNavigate} from "react-router";
import classNames from "classnames";
import GoFrensRestaurantPromoCard from "@/components/goFrens/GoFrensRestaurantPromoCard";
import {useGetGlobalOrderDiscountsForUser} from "@/hooks/useGetGlobalOrderDiscountsForUser";
import GoFrensGlobalPromoCard from "@/components/goFrens/GoFrensGlobalPromoCard";
import {useGetNftOwnershipsForUser} from "@/hooks/useGetNftOwnershipsForUser";
import DragScroll from "@/components/DragScroll";

function GoFrensRewards(): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const lastSearchedPlaceId = useSelector((store: IStore) => store.metaStore.lastSearchedPlaceId);
    const [
        useAddress,
        setUseAddress,
    ] = useState<boolean>(false);
    const [
        showToast,
        setShowToast,
    ] = useState<boolean>(false);

    const history = useNavigate();

    const address: AddressBook =
        currentUser?.user?.addresses?.find(a => a.isDefault) ||
        currentUser?.user?.addresses && currentUser.user.addresses[0];

    const {data: allRestaurantsRes} = useGetGoFrensRewards(fullToken, false);
    const {data: nearbyRestaurantsRes} = useGetGoFrensRewards(fullToken, true, address);
    const {data: globalOrderDiscounts} = useGetGlobalOrderDiscountsForUser(fullToken);
    const {data: nftOwnershipResponse} = useGetNftOwnershipsForUser(fullToken);
    const restaurantRes = useAddress
        ? nearbyRestaurantsRes
        : allRestaurantsRes;

    function updateRestaurantQuery(value: 0 | 1): void {
        setUseAddress(value === 1);
    }

    function renderNavigationButton(url: string, business?: string): ReactElement {
        return (
            <div className="gofrens-rewards_body_cards_button">
                <FrameButton
                    color="gray"
                    size="narrow"
                    to={url}
                    className="gofrens-rewards_body_cards_button_inner"
                >
                    <p>Order {business
                        ? `from ${business}`
                        : "Now"}</p>
                </FrameButton>
            </div>
        );
    }
    function renderRestaurantCards(restaurant: Restaurant): ReactNode {
        if (!restaurant || !restaurant.promos || restaurant.promos.length === 0) {
            return null;
        }

        return (
            <GoFrensRestaurantPromoCard
                key={restaurant.id}
                nftOwnershipResponse={nftOwnershipResponse}
                restaurant={restaurant}
                children={renderNavigationButton(`${restaurant.url}/${lastSearchedPlaceId
                    ? lastSearchedPlaceId
                    : ""}`, restaurant.name)}
                width={!isDesktop && "18rem"}
            />
        );
    }

    function renderGlobalPromoRestaurantCards(orderDiscount: OrderDiscount): ReactNode {

        return (
            <GoFrensGlobalPromoCard
                key={orderDiscount.id}
                nftOwnershipResponse={nftOwnershipResponse}
                promo={orderDiscount}
                width={!isDesktop && "18rem"}
                children={renderNavigationButton("/restaurants")}
            />
        );
    }


    if (!currentUser || restaurantRes?.restaurantsGoFrens?.length === 0 && globalOrderDiscounts?.orderDiscounts?.length === 0) {
        return null;
    }

    if (!nftOwnershipResponse || !restaurantRes && !globalOrderDiscounts) {
        return (
            <div className="gofrens-rewards">
                <div className="gofrens-rewards_header">
                    <div className="gofrens-rewards_title gofrens-rewards_title_skeleton-adjust">
                        <h3><Skeleton/></h3>
                        <p><Skeleton/></p>
                    </div>
                    <div className="gofrens-rewards_title_buttons">
                        <h4><Skeleton/></h4>
                    </div>
                </div>
                <div className="gofrens-rewards_body">
                    <div className="gofrens-rewards_body_cards">
                        <GoFrensCardsSkeleton width={!isDesktop && "15rem"}/>
                    </div>
                </div>
            </div>
        );
    }

    const switchInput: ReactElement =
        <div className={classNames(
            "gofrens-rewards_title_switch-container",
            !isDesktop && "gofrens-rewards_title_switch-container_mobile",
        )}>
            <div onClick={() => {
                if (!address) {
                    setShowToast(true);
                }
            }}>
                <FrameOneSwitchInput
                    <0 | 1>
                    name="gofrens-rewards-use-address"
                    value={useAddress
                        ? 1
                        : 0}
                    disabled={!address}
                    options={[
                        {
                            render: "All",
                            value: 0,
                        },
                        {
                            render: "Near You",
                            value: 1,
                        },
                    ]}
                    onToggle={updateRestaurantQuery}
                />
            </div>
        </div>;
    function handleToastDismissal() {
        setShowToast(false);
    }

    function isEligibleNftOwned(): boolean {
        return nftOwnershipResponse?.myGroupings.length > 0 && nftOwnershipResponse?.nftOwnerships?.[0].length > 0;
    }

    return (
        <>
            <Toast
                duration={5000}
                forceWidth="30rem"
                message="Your location isn't set up yet. Please enter your address on the Account page."
                isOpen={showToast}
                showButton={true}
                buttonMessage={"Your Account"}
                buttonFunction={() => {
                    history("/account");
                }}
                onDismiss={handleToastDismissal}
            />

            {(currentUser?.user?.community || isEligibleNftOwned()) && <div className="gofrens_divider"/>}
            <div className="gofrens-rewards">
                <div className="gofrens-rewards_header gofrens-rewards_header_padding">
                    <div className="gofrens-rewards_title">
                        <h3>Rewards</h3>
                        <p>Claim your community benefits!</p>
                    </div>
                    <div className="gofrens-rewards_title_buttons">
                        {isDesktop && switchInput}
                        <FrameButton
                            color="white-drop-shadow"
                            size={isDesktop || isTablet
                                ? "narrow"
                                : "pill"}
                            className="gofrens-rewards_title_view-all-button"
                            to="/gofriends-rewards"
                        >
                            View all
                        </FrameButton>
                    </div>
                </div>
                {!isDesktop && switchInput}
                <div className="gofrens-rewards_body">
                    <DragScroll className="gofrens-rewards_body_cards">
                        <>
                            {globalOrderDiscounts?.orderDiscounts?.map(renderGlobalPromoRestaurantCards)}
                            {restaurantRes?.restaurantsGoFrens?.map(renderRestaurantCards)}
                        </>
                    </DragScroll>
                </div>
            </div>
        </>
    );
}

export default GoFrensRewards;
