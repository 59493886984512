import {useRestaurant} from "@/hooks/useRestaurant";
import {ButtonHTMLAttributes, useContext, useEffect, useState} from "react";
import {
    BrandMap,
    BrandMapColorTheme,
    BusinessesApi,
    HandoffOptions,
    MenuOrder,
    MenuOrdersApi,
    ValidateDeliveryDistanceResponse,
} from "@devour/client";
import classNames from "classnames";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import BrandLandingOrderHeader from "@/components/brands/BrandLandingOrderHeader";
import useWindowSize from "@/hooks/useWindowSize";
import FrameButton from "@/components/buttons/FrameButton";
import BrandOrderMobileDrawer from "@/components/brands/BrandOrderMobileDrawer";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {
    addError,
    decrementLoading,
    incrementLoading,
    refreshMenuOrderCart,
    toggleOrderHandoff,
} from "@/redux/meta/metaActions";
import getConfig from "@/utils/getConfig";
import RestaurantMenus from "@/pages/restaurants/components/RestaurantMenus";
import {IoClose, IoGiftSharp} from "react-icons/io5";
import {IoIosWarning} from "react-icons/io";
import RestaurantHeaderDefault from "@/components/restaurants/RestaurantHeaderDefault";
import {FaShoppingCart} from "react-icons/fa";
import {BrandMapContext} from "@/pages/brandMap/context/BrandMapContext";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";

interface ApplyOrderDiscountResponse {
    success: boolean;
    content: string;
}

interface Props {
    brandMap: BrandMap;
    restaurantId: string;
    toggleCartPanel: () => void;
    showCartPanel: boolean;
    showMobileDrawer: boolean;
    toggleMobileDrawer: () => void;
}

const BrandLandingRestaurantMenu = (props: Props) => {
    const {
        showMobileDrawer,
        toggleMobileDrawer,
        restaurantId,
        toggleCartPanel,
        showCartPanel,
        brandMap,
    } = props;

    const {selectedPromo} = useContext(BrandMapContext);
    const brandPromos = brandMap?.promos;

    const dispatch = useDispatch();

    const lastSearchedPlaceId = useSelector((store: IStore) => store.metaStore.lastSearchedPlaceId);
    const menuOrders = useSelector((store: IStore) => store.metaStore.menuOrders);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: restaurantData, refetch: restaurantDataRefetch } = useRestaurant(restaurantId, lastSearchedPlaceId);
    const existingMenuOrderId = menuOrders?.[restaurantId];
    const {data: menuOrder} = useMenuOrder(existingMenuOrderId);
    const [validateDeliveryDistanceResponse, setValidateDeliveryDistanceResponse] = useState<ValidateDeliveryDistanceResponse>(null);
    const [applyOrderDiscountResponse, setApplyOrderDiscountResponse] = useState<ApplyOrderDiscountResponse>(undefined);
    const [mobileSearchMode, setMobileSearchMode] = useState(false);


    const windowSize = useWindowSize()[0];
    const isWindowMobileView = windowSize < 576;
    // Need to check if the window is mobile view or if the user is on a mobile device to conditionally render mobile drawer
    const isMobileView = isMobile && !isTablet || isWindowMobileView;

    const sumQuantity: number = menuOrder?.orderItems.reduce((accumulator, object) => {
        return accumulator + object.quantity;
    }, 0);

    const isBrandMapColorThemeLight = brandMap?.colorTheme === BrandMapColorTheme.LIGHT;

    /**
     * Apply system dark mode theme to restaurant menu page if the brand map color theme is light
     */
    useEffect(() => {
        if (brandMap?.colorTheme && isBrandMapColorThemeLight) {
            const brandRestaurantContainer = document.querySelector(".brand-map-restaurant_container");

            if (brandRestaurantContainer) {
                brandRestaurantContainer.setAttribute("data-theme", "dark");
            }
        }
    }, [brandMap?.colorTheme, showMobileDrawer, isMobileView]);

    useEffect(() => {
        if (lastSearchedPlaceId && restaurantData) {
            void validateDeliveryDistance();
        }
    }, [
        lastSearchedPlaceId,
        restaurantData,
    ]);

    useEffect(() => {
        if (restaurantData && selectedPromo && menuOrder) {
            void applyOrderDiscountOnOrder();
        } else {
            setApplyOrderDiscountResponse(undefined);
        }
    }, [
        menuOrder?.id,
        restaurantData,
        selectedPromo,
    ]);

    async function validateDeliveryDistance(): Promise<void> {
        dispatch(incrementLoading());
        try {
            const res = await new BusinessesApi(getConfig(fullToken)).validateDeliveryDistance({
                id: lastSearchedPlaceId,
                restaurant: restaurantId,
            });

            setValidateDeliveryDistanceResponse(res);
            if (!res.canDeliver && restaurantData?.handoffOptions.includes(HandoffOptions.PICKUP)) {
                dispatch(toggleOrderHandoff(HandoffOptions.PICKUP));
            }
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    async function applyOrderDiscountOnOrder(): Promise<void> {
        const appliedDiscount = menuOrder.discounts.find(discount => discount.reference === selectedPromo);
        if (appliedDiscount) {
            setApplyOrderDiscountResponse({
                success: true,
                content: `Awesome! Your reward has been been loaded: ${appliedDiscount.label}`,
            });
            return;
        }

        // attempt applying the discount
        try {
            const updatedOrder: MenuOrder = await new MenuOrdersApi(getConfig()).updateMenuOrder({
                id: menuOrder.id,
                createMenuOrderBody: {
                    discountSelected: selectedPromo,
                },
            });

            const appliedDiscount = updatedOrder.discounts.find(discount => discount.reference === selectedPromo);
            if (appliedDiscount) {
                dispatch(refreshMenuOrderCart());
            } else {
                const promo = brandPromos.find(promo => promo.id === selectedPromo);
                setApplyOrderDiscountResponse({
                    success: false,
                    content: `Promotion ${promo
                        ? `"${promo.label}"`
                        : ""} is not available for this restaurant.`,
                });
            }

        } catch (e) {
            const error = await e.json();
            setApplyOrderDiscountResponse({
                success: false,
                content: error?.message,
            });
        }
    }

    function renderRestaurantMenuContent() {
        return <div id="restaurant-map-landing_menu_rah" className="brand-map-restaurant_container">
            <div className="restaurant-page_header">
                <div className="restaurant-page_header_image">
                    <img
                        src={restaurantData?.headerImage?.url || `${import.meta.env.VITE_CDN_URL}/images/placeholderitem.webp`}
                        alt={restaurantData?.name}
                    />
                </div>
                <div className="restaurant-page_logo-container restaurant-page_section-margin">
                    <div className="restaurant-page_header_logo">
                        <img
                            src={restaurantData?.icon?.url || `${import.meta.env.VITE_CDN_URL}/images/placeholderitem.webp`}
                            alt={restaurantData?.name}
                        />
                    </div>
                </div>
            </div>
            <div className="restaurant-page_content">
                {!restaurantData || restaurantData?.isOpen
                    ? <RestaurantMenus
                        mobileSearchMode={mobileSearchMode}
                        setMobileSearchMode={setMobileSearchMode}
                        placeId={lastSearchedPlaceId}
                    >
                        {applyOrderDiscountResponse &&
                            <div className={classNames(
                                "applied-promo-banner",
                                {"promo-inapplicable": !applyOrderDiscountResponse.success},
                            )}
                            >
                                <div className="applied-promo-banner_left">
                                    {applyOrderDiscountResponse.success
                                        ? <IoGiftSharp/>
                                        : <IoIosWarning/>}
                                    <p>{applyOrderDiscountResponse.content}</p>
                                </div>
                                <button
                                    className="reset-button"
                                    onClick={() => setApplyOrderDiscountResponse(undefined)}
                                >
                                    <IoClose className="applied-promo-banner_close-btn"/>
                                </button>

                            </div>
                        }
                    </RestaurantMenus>
                    : <>
                        <RestaurantHeaderDefault
                            data={restaurantData}
                            placeId={lastSearchedPlaceId}
                            validateDeliveryDistanceResponse={validateDeliveryDistanceResponse}
                        />
                        {isDesktop && <hr/>}
                        {restaurantData?.isOpen === false &&
                            <div className="restaurant-page_closed">
                                <img
                                    src={`${import.meta.env.VITE_CDN_URL}/images/restaurant-closed-clock.webp`}
                                    alt="restaurant-closed-icon"
                                />
                                <div className="restaurant-page_closed_title">
                                    Sorry, we're closed!
                                </div>
                                <div className="restaurant-page_closed_description">
                                    <p>
                                        This restaurant is closed right now. Please check back later.
                                    </p>
                                </div>
                            </div>
                        }
                    </>
                }

                {sumQuantity > 0 && isMobile &&
                    <div className="restaurant-page_cart-button">
                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            onClick={toggleCartPanel}
                            color="purple"
                            size="normal"
                            forwardProps={{type: "button"}}
                            className={classNames("restaurant-page_view-cart-button-rounded", {
                                "hide-cart-button": showCartPanel || !showMobileDrawer,
                            })}
                        >
                            <div className="restaurant-page_view-cart-button">

                                <FaShoppingCart/>

                                <div>
                                    View Cart
                                </div>

                                <div className="restaurant-page_quantity">
                                    {sumQuantity || 0}
                                </div>

                            </div>

                        </FrameButton>
                    </div>
                }
            </div>
        </div>;
    }

    return (
        <>
            {/* Ordering drawer for mobile */}
            {isMobileView && <BrandOrderMobileDrawer
                isOpen={showMobileDrawer}
                toggle={toggleMobileDrawer}
                content={renderRestaurantMenuContent()}
            />
            }

            <div
                className={classNames("restaurant-page brand-map-restaurant restaurant-map-landing_ordering_step", {
                    "is-mobile-search": mobileSearchMode,
                    "is-mobile": isMobileView,
                })}
            >
                <BrandLandingOrderHeader
                    title={isMobileView ? "ORDER" : "ORDER NOW"}
                    description={"Add items to your cart and continue to checkout."}
                    showChevron={true}
                    showPromoLabel={true}
                    restaurantId={restaurantId}
                />

                {isMobileView &&
                    <>
                        <FrameButton
                            color={isBrandMapColorThemeLight ? "brand-dark" : "brand-light"}
                            size="large"
                            className="brand-map-restaurant_mobile_button"
                            onClick={() => toggleMobileDrawer()}
                        >
                            View Restaurant
                        </FrameButton>
                    </>
                }

                {/* RESTAURANT PAGE CONTENT */}
                {!isMobileView &&
                    renderRestaurantMenuContent()
                }
            </div>
        </>
    );
};

export default BrandLandingRestaurantMenu;
