import { FaDiscord } from "react-icons/fa";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {handleDiscordAuthenticate, useDisconnectDiscordAccount, useGetDiscordAccount} from "@/hooks/useDiscordGO";

const DiscordConnect = () => {
    const {data: discordAccount, isLoading} = useGetDiscordAccount();
    const discordDetails = discordAccount?.discord;

    return (
        <div className="discord-connect">
            <div className="discord-connect_text">
                <p className="discord-connect_text_title">
                    <strong>Discord Authentication</strong>
                </p>
                <p className="discord-connect_text_description">
                    Connect to have direct communication with our community members and opportunities to earn rewards. Stay informed, get involved, and earn just by being a part of our Discord family!
                </p>
            </div>
            <div className="discord-connect_button-container">
                {
                    discordDetails ? <DisconnectFromDiscordButton /> : <ConnectToDiscordButton loading={isLoading} />
                }
            </div>
        </div>
    );
};

const ConnectToDiscordButton = ({loading}: { loading: boolean }) => {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const handleDiscordSignIn = () => {
        void handleDiscordAuthenticate(fullToken);
    };

    return (
        <button
            disabled={loading}
            className="discord-connect_button"
            onClick={handleDiscordSignIn}
        >
            {!loading && (<FaDiscord className="discord-icon"/>)}
            <span>{loading ? "Loading Discord Connect" : "Sign in with Discord"}</span>
        </button>
    );
};

const DisconnectFromDiscordButton = () => {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const { mutateAsync: disconnectFromDiscord} = useDisconnectDiscordAccount(fullToken);

    return (
        <button
            className="discord-disconnect_button"
            onClick={() => disconnectFromDiscord()}
        >
            <span><strong>Disconnect</strong></span>
        </button>
    );
};

export default DiscordConnect;
