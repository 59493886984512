import {ReactElement, useContext} from "react";
import Skeleton from "react-loading-skeleton";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";

interface Props {
    subtotal?: number;
}

function CheckoutSummaryTotals(props: Props): ReactElement {
    const {menuOrderId} = useContext(RestaurantContext);
    const {data: menuOrder} = useMenuOrder(menuOrderId);

    return (
        <>
            <div className="checkout-summary_totals_row checkout-summary_totals_total">
                <span>
                    <strong>Subtotal</strong>
                </span>
                <span>
                    {props.subtotal !== undefined || menuOrder?.subtotal
                        ? <strong>${(props.subtotal !== undefined ? props.subtotal : menuOrder?.subtotal).toFixed(2)}</strong>
                        : <Skeleton height={17.5} width={40}/>
                    }
                </span>
            </div>
        </>

    );
}

export default CheckoutSummaryTotals;
