import {ReactElement} from "react";
import {Restaurant} from "@devour/client";

interface Props {
    restaurant: Restaurant;
}

function RestaurantImageHeader(props: Props): ReactElement {

    if (!props.restaurant) {
        return null;
    }
    return (
        <div className="restaurant-image-header">
            <div className="restaurant-image-header_bg">
                <img
                    src={props.restaurant?.headerImage?.url || import.meta.env.VITE_CDN_URL + "/images/placeholderitem.webp"}
                    alt={`${props.restaurant?.name} banner`}
                />
            </div>

            <div className="restaurant-image-header_icon-pos">
                <div className="restaurant-image-header_icon-pos_icon">
                    <img
                        src={props.restaurant?.icon?.url || import.meta.env.VITE_CDN_URL + "/images/placeholderitem.webp"}
                        alt={`${props.restaurant?.name} icon`}
                    />
                </div>
            </div>
        </div>
    );
}

export default RestaurantImageHeader;
