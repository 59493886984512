import classNames from "classnames";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";
import useThemePreference from "@/hooks/useThemePreference";

interface props {
    current: number;
    total: number;
    showGlow?: boolean;
}

function CircularProgressIndicator({current, total, showGlow = false}: props) {
    const percentage = current / total * 100;
    const strLength = total.toString().length + current.toString().length;
    const {isOnOverwolf} = useOverwolfInterop();
    const {isOnDarkMode} = useThemePreference();

    return (
        <div className={classNames("circular-progress-indicator", {
            "glow": showGlow && isOnOverwolf,
            "completed": current >= total,
            "has-long-text": strLength >= 4,
        })}>
            <svg viewBox="0 0 32 32">
                {/* Permanent Stroke Glow Filter for the GoVIP dual reward modal
                (filter does not apply on the Overwolf side)
                */}
                <defs>
                    <filter id="glow" x="-30%" y="-30%" width="200%" height="200%">
                        <feGaussianBlur stdDeviation="2" result="coloredBlur"/>
                        <feFlood floodColor={isOnDarkMode ? "#7F56D9" : "#7859E6"} result="floodColor"/>
                        <feComposite in="floodColor" in2="coloredBlur" operator="in"/>
                        <feMerge>
                            <feMergeNode/>
                            <feMergeNode in="SourceGraphic"/>
                        </feMerge>
                    </filter>
                </defs>

                <circle className="background-circle" cx="16" cy="16" r="15"/>
                {percentage > 0 &&
                    <circle className="progress-circle" cx="16" cy="16" r="15" strokeDasharray={`${percentage} 100`}/>
                }
            </svg>
            <div className="circular-progress-indicator-text">
                <span className={classNames("circular-progress-indicator-text_current", {
                    "purple-font": current > 0,
                    "smaller-font": current > 99,
                })}
                >{current}</span>
                <span className={classNames("circular-progress-indicator-text_total", {
                    "smaller-font": total > 99,
                })}>
                    {" / "}{total}
                </span>
            </div>
        </div>
    );
}

export default CircularProgressIndicator;
