export function validateEmail(email: unknown, required: boolean = true): boolean {
    if (required && !email) {
        return false;
    }

    if (email) {
        if (typeof email !== "string") {
            return false;
        }

        // check the email with regex
        // from emailregex.com
        const emailRegex = RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        if (!emailRegex.test(email)) {
            return false;
        }
    }

    return true;

}
