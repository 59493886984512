import {FC, useEffect, useState} from "react";
import {PhoneNumberBody, UpdateProfileBody, UsersApi} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";
import FrameOnePhoneNumberInput from "./inputs/FrameOnePhoneNumberInput";
import FrameButton from "./buttons/FrameButton";
import {addError, decrementLoading, incrementLoading, updateCurrentUser} from "../redux/meta/metaActions";
import getConfig from "../utils/getConfig";
import {formatOptionalPhoneNumberForApiSubmission} from "../utils/formatOptionalPhoneNumberForApiSubmission";
import {genericOnChangeHelper} from "../utils/genericOnChangeHelper";
import AccountPageManageAddressBook from "./AccountPageManageAddressBook";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {sendMessageToOW} from "@/hooks/useOverwolfInterop";
import {magic} from "@/utils/magic";

const defaultValues: UpdateProfileBody = {
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: {
        countryCode: "US",
        nationalNumber: "",
    },
};

const UserEdit: FC = () => {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: userProfileData} = useGetUserProfile(fullToken);
    const user = userProfileData?.user;

    const [
        updateProfileForm,
        setUpdateProfileForm,
    ] = useState<UpdateProfileBody>(defaultValues);
    const {refetch: refetchUserProfile} = useGetUserProfile(fullToken);

    useEffect(() => {
        if (user) {
            setUpdateProfileForm({
                email: user?.email || "",
                phoneNumber: {
                    countryCode: user?.phoneNumber?.countryCode || "US",
                    nationalNumber: user?.phoneNumber?.nationalNumber || "",
                },
                firstName: user?.firstName || "",
                lastName: user?.lastName || "",
            });
        }
    }, [user]);

    function onPhoneNumberChange(phoneNumber: PhoneNumberBody) {
        setUpdateProfileForm({
            ...updateProfileForm,
            phoneNumber: phoneNumber,
        });
    }

    async function submitHandler(e) {
        e.preventDefault();
        dispatch(incrementLoading());
        try {
            if (user.email !== updateProfileForm.email) {
                const res = await new UsersApi(getConfig()).validateUserExistence({
                    email: updateProfileForm.email,
                });
                if (res._exists) {
                    throw new Error(`${updateProfileForm.email} already exists.`);
                }

                await magic.auth.updateEmailWithUI({
                    email: updateProfileForm.email,
                });
            }
            await new UsersApi(getConfig(fullToken)).updateProfile({
                updateProfileBody: {
                    email: updateProfileForm.email,
                    phoneNumber: formatOptionalPhoneNumberForApiSubmission(updateProfileForm.phoneNumber),
                    firstName: updateProfileForm.firstName,
                    lastName: updateProfileForm.lastName,
                },
            });

            const userRes = await new UsersApi(getConfig(fullToken)).getProfile();
            dispatch(updateCurrentUser(userRes));
            // Tell Overwolf to update the user data
            sendMessageToOW({ type: "de:update-user",
                payload: { userData: userRes } });
        } catch (err) {
            dispatch(await addError(err));
        } finally {
            await refetchUserProfile();
            dispatch(decrementLoading());
        }
    }

    return (
        <section className="user-edit">
            <h3>Profile</h3>

            <div className="user-edit_container">
                <form
                    className="user-edit_user-form"
                    onSubmit={submitHandler}
                >
                    <div className="user-edit_user-form_element">
                        <div>
                            <label>First Name</label>
                            <input
                                placeholder="First name..."
                                value={updateProfileForm.firstName}
                                onChange={genericOnChangeHelper(updateProfileForm, setUpdateProfileForm, "firstName")}
                            />
                        </div>

                        <div>
                            <label>Last Name</label>
                            <input
                                placeholder="Last name..."
                                value={updateProfileForm.lastName}
                                onChange={genericOnChangeHelper(updateProfileForm, setUpdateProfileForm, "lastName")}
                            />
                        </div>
                    </div>

                    <div className="user-edit_user-form_element">
                        <div>
                            <label>Email</label>
                            <input
                                type={"email"}
                                value={updateProfileForm.email}
                                onChange={genericOnChangeHelper(updateProfileForm, setUpdateProfileForm, "email")}
                            />
                        </div>
                    </div>

                    <div className="user-edit_user-form_element">
                        <div>
                            <FrameOnePhoneNumberInput
                                value={updateProfileForm.phoneNumber}
                                onChange={onPhoneNumberChange}
                            />
                        </div>
                        <div className="user-edit_user-form_element_spacer"/>
                    </div>

                    <div className="user-edit_user-form_submit">
                        <FrameButton
                            color="purple"
                            size="normal"
                            forwardProps={{type: "submit"}}
                        >
							Save Changes
                        </FrameButton>
                    </div>
                </form>

                <hr/>

                <div className="user-edit_user-form_address-book">
                    <AccountPageManageAddressBook/>
                </div>

            </div>
        </section>
    );
};

export default UserEdit;
