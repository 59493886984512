import {ReactElement, useEffect, useState} from "react";
import classNames from "classnames";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import {isDesktop, isTablet} from "react-device-detect";
import {FiX} from "react-icons/fi";
import Spacer from "@/components/Spacer";
import FrameButton from "@/components/buttons/FrameButton";
import {BsChevronLeft} from "react-icons/bs";
import useWindowSize from "@/hooks/useWindowSize";


interface GoVipOWTutorialStepMetadata {
    title: string;
    description: string;
    image: ReactElement;
}

const goVipOWTutorialSteps: Array<GoVipOWTutorialStepMetadata> = [
    {
        title: "Download and Install",
        description: "Head over to Overwolf Appstore and download DevourPlay",
        image: <img src={`${import.meta.env.VITE_CDN_URL}/images/govip-ow-tutorial-step1.webp`} alt="Download and Install"/>,
    },
    {
        title: "Launch and Login",
        description: "Open the DevourPlay app you installed and log in to your account.",
        image: <img src={`${import.meta.env.VITE_CDN_URL}/images/govip-ow-tutorial-step2.webp`} alt="Launch and Login"/>,
    },
    {
        title: "Play and Earn",
        description: "Start playing games and boost your XP to level up!",
        image: <img
            className="go-vip-ow_tutorial_body_step_rewards-img"
            src={`${import.meta.env.VITE_CDN_URL}/images/govip-ow-tutorial-step3.webp`}
            alt="Play and Earn"/>,
    },
];

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

export default function GoVipOWTutorialModal(props: Props) {
    const [currentStep, setCurrentStep] = useState<number>(0);
    const windowSize = useWindowSize();
    const isOnMobile = !isDesktop && !isTablet;

    useEffect(() => {
        preloadImages(); // Preload images to fix flickering
    }, []);

    useEffect(() => {
        if (props.isOpen) {
            setCurrentStep(0);
        }
    }, [props.isOpen]);

    function preloadImages() {
        goVipOWTutorialSteps.forEach(step => {
            const img = new Image();
            img.src = step.image.props.src;
        });
    }

    function getImageUrl(url: string): string {
        if (isOnMobile && windowSize[1] < 800) {
            return `${import.meta.env.VITE_CDN_URL}/images/${url}-sm.webp`;
        } else if (isOnMobile) {
            return `${import.meta.env.VITE_CDN_URL}/images/${url}-sm2.webp`;
        }

        return `${import.meta.env.VITE_CDN_URL}/images/${url}.webp`;
    }

    function renderStep(): ReactElement {
        const step = goVipOWTutorialSteps[currentStep - 1];
        const isLastStep = currentStep === goVipOWTutorialSteps.length;

        return (
            <div className="go-vip-ow_tutorial_body_step-container">
                <div className="go-vip-ow_tutorial_body_step">
                    <b className="go-vip-ow_tutorial_body_step_number">Step {currentStep}</b>
                    <div className="go-vip-ow_tutorial_body_step_info">
                        <h3>{step.title}</h3>
                        <div>{step.description}</div>
                    </div>
                    {step.image}
                </div>

                <div className="go-vip-ow_tutorial_body_step_stepper">
                    {goVipOWTutorialSteps.map((step, index) =>
                        <div
                            key={step.title}
                            className={classNames("go-vip-ow_tutorial_body_step_stepper_step", {
                                active: currentStep === index + 1,
                            })}
                            onClick={() => setCurrentStep(index + 1)}
                        />)}
                </div>

                <Spacer />

                <FrameButton
                    size="large"
                    className="go-vip-ow_tutorial_body_step_next-btn"
                    onClick={() => {
                        if (isLastStep) {
                            window.open("https://www.overwolf.com/app/Devour-DevourPlay", "_blank");
                        } else {
                            setCurrentStep(currentStep + 1);
                        }
                    }}
                >
                    {isLastStep ? "Download to PC" : "Next"}
                </FrameButton>
            </div>
        );
    }

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            toggle={props.onClose}
            contentClassName="go-vip-ow_tutorial"
            size="xs2"
            modalOnTablet={true}
            fullScreenHeight={true}
        >
            <div
                className={classNames(
                    "go-vip-ow_tutorial_body",
                    `step-${currentStep}`,
                )}
            >
                <div
                    style={{backgroundImage: `url(${getImageUrl("govip-ow-tutorial-initial-bg")})`}}
                    className="go-vip-ow_tutorial_body_bg-initial"
                />
                <div
                    style={{backgroundImage: `url(${getImageUrl("govip-ow-tutorial-bg")})`}}
                    className="go-vip-ow_tutorial_body_bg"
                />
                <div className="go-vip-ow_tutorial_nav">
                    {currentStep > 0 && <button onClick={() => setCurrentStep(currentStep - 1)}>
                        <BsChevronLeft size="1.5rem" strokeWidth="0.07rem"/>
                        <b>Back</b>
                    </button>}
                    <Spacer/>
                    <button>
                        <FiX
                            size="1.75rem"
                            strokeWidth="0.1875rem"
                            onClick={props.onClose}
                        />
                    </button>
                </div>

                {currentStep === 0 && <div className="go-vip-ow_tutorial_body_initial">
                    <div className="go-vip-ow_tutorial_body_initial_header">
                        <h1 className="go-vip-ow_tutorial_body_initial_title">HOW TO EARN</h1>
                        <h1 className="go-vip-ow_tutorial_body_initial_subtitle">XP & REWARDS<br/>WITH OVERWOLF</h1>

                        <FrameButton
                            size="large"
                            className="go-vip-ow_tutorial_body_initial_explore-btn"
                            onClick={() => setCurrentStep(currentStep + 1)}
                        >
                            Explore Now!
                        </FrameButton>
                    </div>
                    <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/govip-ow-devour-overwolf-logo.webp`}
                        alt="devour overwolf logo"
                        className="go-vip-ow_tutorial_body_initial_logo"
                    />
                </div>}
                {currentStep > 0 && renderStep()}
            </div>
        </FrameOneAutoPanel>
    );
}
