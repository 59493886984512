export function capitalizeFirstLetter(str) {
    if (!str) return str;

    // List of words that should not be capitalized unless they're the first word
    const doNotCapitalize = ["of", "the", "and", "in", "on", "at", "for", "with"];

    return str.toLowerCase().split(" ")
        .map((word, index) => {
        // Always capitalize the first word and words not in the doNotCapitalize list
            if (index === 0 || !doNotCapitalize.includes(word)) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }
            return word;
        })
        .join(" ");
}