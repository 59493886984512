import {ButtonHTMLAttributes, ReactElement, useEffect, useState} from "react";
import {GetUserCommunityInfoResponse, GoFrensApi, NftGrouping, NftTracker, Token} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {addError} from "@/redux/meta/metaActions";
import getConfig from "@/utils/getConfig";
import {BiDotsVerticalRounded} from "react-icons/bi";
import {RiVipDiamondLine} from "react-icons/ri";
import {getRankSuffix} from "@/utils/getRankSuffix";
import FrameButton from "@/components/buttons/FrameButton";
import {FiChevronRight} from "react-icons/fi";
import {isDesktop, isTablet} from "react-device-detect";
import GoFrensCommunityHeaderDropdownBody from "@/components/goFrens/GoFrensCommunityHeaderDropdownBody";
import Dropdown from "@/components/Dropdown";
import GoFrensLeaveCommunityModal from "@/components/modals/GoFrensLeaveCommunityModal";
import GoFrensCommunityInfoDrawer from "@/components/goFrens/GoFrensCommunityInfoDrawer";
import DrawerModalView from "@/components/goFrens/communityInfoDrawer/DrawerModalView";
import {useGetNftOwnershipsForUser} from "@/hooks/useGetNftOwnershipsForUser";
import useThemePreference from "@/hooks/useThemePreference";


interface GroupingInfo {
    nftGrouping: NftGrouping;
    nftTrackers: Array<NftTracker>;
    owned: number;
}

function GoFrensCommunityHeader(): ReactElement {
    const DISPLAY_COUNT_THRESHOLD = 5;

    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [
        community,
        setCommunity,
    ] = useState<GetUserCommunityInfoResponse>(undefined);
    const [
        groupingInfo,
        setGroupingInfo,
    ] = useState<GroupingInfo>(undefined);
    const {data: nftOwnershipsResponse} = useGetNftOwnershipsForUser(fullToken as Token);
    const [
        showLeaveCommunityConfirmModal,
        setShowLeaveCommunityConfirmModal,
    ] = useState<boolean>(false);
    const [
        showDrawer,
        setShowDrawer,
    ] = useState<boolean>(false);
    const [
        showModal,
        setShowModal,
    ] = useState<boolean>(false);
    const { isOnDarkMode } = useThemePreference();


    function toggleDrawer(): void {
        setShowDrawer(s => !s);
    }

    function toggleModal(): void {
        setShowModal(s => !s);
    }

    async function getCommunityInfo() {
        try {
            const res = await new GoFrensApi(getConfig(fullToken)).getUserCommunityInfo();
            setCommunity(res);
        } catch (e) {
            dispatch(await addError(e));
        }
    }

    useEffect(() => {
        void getCommunityInfo();
    }, []);

    useEffect(() => {
        if (community && nftOwnershipsResponse) {
            const trackers = nftOwnershipsResponse.nftTrackers?.filter(t => t.groupingId === community.nftGrouping.id);
            const nfts = nftOwnershipsResponse.nftOwnerships?.[0].filter(ownership => ownership.nftGroupingId === community.nftGrouping.id);
            setGroupingInfo({
                nftGrouping: nftOwnershipsResponse.myGroupings?.find(t => t.id === community.nftGrouping.id),
                nftTrackers: trackers,
                owned: nfts.length,
            });
        }
    }, [
        community,
        nftOwnershipsResponse,
    ]);

    function toggleLeaveCommunityModal() {
        setShowLeaveCommunityConfirmModal(prevState => !prevState);
    }

    function scrollToLeaderboard() {
        document.getElementById("leaderboards")?.scrollIntoView({behavior: "smooth"});
    }

    function renderMemberPfp() {

        /*
         * Have to do a lot of calculations to margins based on how many pfps were displayed
         * due to their absolute positioning.
         */
        let lastIndex = community.members.length;
        let counterToBeDisplayed = community.nftCommunityMembers;
        if (lastIndex > DISPLAY_COUNT_THRESHOLD) {
            lastIndex = DISPLAY_COUNT_THRESHOLD;
            counterToBeDisplayed -= DISPLAY_COUNT_THRESHOLD;
        }

        const textCounterToBeDisplayed = `${community.nftCommunityMembers > DISPLAY_COUNT_THRESHOLD
            ? "+"
            : ""}${counterToBeDisplayed} ${counterToBeDisplayed > 1
            ? "Members"
            : "Member"}`;

        return (
            <>
                <div
                    className="gofrens-community-header_content_info_description_members_pfps"
                    style={{marginLeft: isDesktop
                        ? "0"
                        : `${(lastIndex - 1 + 0.5) * -1}rem`}}
                >
                    {community.nftCommunityMembers > DISPLAY_COUNT_THRESHOLD &&
                    <>
                        {community.members.slice(0, DISPLAY_COUNT_THRESHOLD).map((member, index) => {
                            return (
                                <img
                                    className={`gofrens-community-header_content_info_description_members_pfps_${index + 1}`}
                                    src={member.gravatar}
                                    alt="govip player avatar"
                                    key={`pfp-${member.id}`}
                                />
                            );
                        })}
                    </>
                    }
                    {isDesktop &&
                    <p
                        className="gofrens-community-header_content_info_description_members_total-number"
                        style={{
                            marginLeft: community.nftCommunityMembers <= DISPLAY_COUNT_THRESHOLD
                                ? "0"
                                : `${1.875 * lastIndex - 0.1 * lastIndex}rem`,
                        }}
                    >
                        {textCounterToBeDisplayed}
                    </p>
                    }
                </div>
                {!isDesktop &&
                <p
                    className="gofrens-community-header_content_info_description_members_total-number"
                    style={{
                        marginTop: community.nftCommunityMembers <= DISPLAY_COUNT_THRESHOLD
                            ? "-0.675rem"
                            : "",
                    }}
                >
                    {textCounterToBeDisplayed}
                </p>
                }
            </>
        );
    }

    return (
        <>
            {community && <GoFrensLeaveCommunityModal
                isOpen={showLeaveCommunityConfirmModal}
                toggle={toggleLeaveCommunityModal}
                grouping={community.nftGrouping}
            />
            }
            {community && groupingInfo &&
            <>
                <GoFrensCommunityInfoDrawer
                    show={showDrawer}
                    toggle={toggleDrawer}
                    grouping={groupingInfo.nftGrouping}
                    trackers={groupingInfo.nftTrackers}
                    owned={groupingInfo.owned}
                />
                <DrawerModalView
                    show={showModal}
                    toggle={toggleModal}
                    grouping={groupingInfo.nftGrouping}
                    trackers={groupingInfo.nftTrackers}
                    owned={groupingInfo.owned}
                />
            </>
            }
            {community
                ? <div className="gofrens-community-header">
                    <div className="gofrens-community-header_content">
                        <div className="gofrens-community-header_content_info">
                            <img
                                className="gofrens-community-header_content_info_graphic"
                                src={community.nftGrouping.icon.url}
                                alt={`${community.nftGrouping.name} icon`}
                            />
                            <div className="gofrens-community-header_content_info_description">
                                <div className="gofrens-community-header_content_info_description_name">
                                    <p className="gofrens-community-header_content_info_description_name_title">
                                        {community.nftGrouping.name}
                                    </p>
                                    <Dropdown
                                        dropdownBody={
                                            <GoFrensCommunityHeaderDropdownBody
                                                toggleLeaveCommunityModal={toggleLeaveCommunityModal}
                                                toggleCommunityInfoDrawer={() => isTablet
                                                    ? toggleModal()
                                                    : toggleDrawer()}
                                            />
                                        }
                                        dropdownBodyClassName="gofrens-community-header-dropdown"
                                        dropdownToggleElement={
                                            <BiDotsVerticalRounded
                                                className="gofrens-community-header_content_info_description_name_icon"
                                            />
                                        }
                                    />
                                </div>

                                <div className="gofrens-community-header_content_info_description_members">
                                    {renderMemberPfp()}
                                </div>

                            </div>

                        </div>

                        <div className="gofrens-community-header_content_ranking">
                            <p className="gofrens-community-header_content_ranking_title">Current Community Rank</p>

                            <div className="gofrens-community-header_content_ranking_container">
                                <div className="gofrens-community-header_content_ranking_container_rank">
                                    <div className="gofrens-community-header_content_ranking_container_rank_info">
                                        <RiVipDiamondLine
                                            strokeWidth="0.0625rem"
                                            className="gofrens-community-header_content_ranking_container_rank_icon"
                                        />
                                        {!community.nftCommunity.monthlyPoints
                                            ? <h3>Unranked</h3>
                                            : <>
                                                <h3>{`Rank ${community.nftCommunityRank}`}</h3>
                                                <p>{getRankSuffix(community.nftCommunityRank)}</p>
                                            </>
                                        }
                                    </div>
                                    <p className="gofrens-community-header_content_ranking_container_rank_exp">
                                        {`${community.nftCommunity?.monthlyPoints
                                            ? community.nftCommunity.monthlyPoints
                                            : 0} XP`}
                                    </p>
                                </div>

                                <FrameButton
                                    <ButtonHTMLAttributes<HTMLButtonElement>>
                                    color={isOnDarkMode
                                        ? "purple"
                                        : "white-drop-shadow"}
                                    size="narrow"
                                    rightIcon={FiChevronRight}
                                    className="gofrens-community-header_content_ranking_container_button"
                                    onClick={scrollToLeaderboard}
                                >
                                    {isDesktop
                                        ? "Community Leaderboard"
                                        : "Leaderboard"}
                                </FrameButton>
                            </div>

                        </div>

                    </div>
                    <div className="gofrens-header_white-rounded"/>
                </div>
                : <div className="react-loading-skeleton gofrens-header-skeleton">
                    <div className="gofrens-header_white-rounded"/>
                </div>
            }
        </>
    );
}

export default GoFrensCommunityHeader;
