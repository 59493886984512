import BrandLandingOrderHeader from "@/components/brands/BrandLandingOrderHeader";
import classNames from "classnames";
import useWindowSize from "@/hooks/useWindowSize";
import {isMobile, isTablet} from "react-device-detect";
import FrameButton from "@/components/buttons/FrameButton";
import BrandOrderMobileDrawer from "@/components/brands/BrandOrderMobileDrawer";
import {BrandMap, BrandMapColorTheme} from "@devour/client";
import MenuOrderSuccessSwitcher from "../menuOrder/MenuOrderSuccessSwitcher";

interface Props {
    brandMap: BrandMap;
    restaurantId: string;
    menuOrderId: string;
    toggleMobileDrawer: () => void;
    showMobileDrawer: boolean;
}

const BrandMenuOrderSuccessPage = (props: Props) => {
    const {restaurantId, menuOrderId, toggleMobileDrawer, showMobileDrawer} = props;
    const windowSize = useWindowSize()[0];
    const isWindowMobileView = windowSize < 576;
    const isMobileView = isMobile && !isTablet || isWindowMobileView;
    const isBrandMapColorThemeLight = props.brandMap?.colorTheme === BrandMapColorTheme.LIGHT;

    const mobileOrderSuccessContent = <div className="brand-map-restaurant_container is-mobile">
        <MenuOrderSuccessSwitcher
            menuOrderId={menuOrderId}
        />
    </div>;

    return (
        <>
            {isMobileView && <BrandOrderMobileDrawer
                toggle={toggleMobileDrawer}
                isOpen={showMobileDrawer}
                content={mobileOrderSuccessContent}
            />
            }

            <div
                className={classNames("brand-map-restaurant restaurant-map-landing_checkout restaurant-map-landing_ordering_step", {
                    "is-mobile remove-margin": isMobileView,
                })}
            >
                <BrandLandingOrderHeader
                    title={"ORDER COMPLETED"}
                    description={"You have successfully placed your order!"}
                    showOrderMoreButton={true}
                    restaurantId={restaurantId}
                />

                {isMobileView &&
                    <>
                        <FrameButton
                            color={isBrandMapColorThemeLight ? "brand-dark" : "brand-light"}
                            size="large"
                            className="brand-map-restaurant_mobile_button"
                            onClick={() => toggleMobileDrawer()}
                        >
                            Track My Order
                        </FrameButton>
                    </>
                }

                {!isMobileView && <div className="brand-map-restaurant_container">
                    <MenuOrderSuccessSwitcher
                        menuOrderId={menuOrderId}
                    />
                </div>}
            </div>
        </>
    );
};

export default BrandMenuOrderSuccessPage;
