import {GetOverwolfXpSubjectEnum, OverwolfApi, OWGame} from "@devour/client";
import {ReactElement, ReactNode, useCallback, useEffect, useState} from "react";
import {isMobile, isTablet} from "react-device-detect";
import getConfig from "@/utils/getConfig";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {addError} from "@/redux/meta/metaActions";
import FrameButton from "@/components/buttons/FrameButton";
import classNames from "classnames";
import GoVipOWDualRewardModal from "@/components/goVip/GoVipOWDualRewardModal";
import useGetUserOverwolfXP from "@/hooks/useGetUserOverwolfXP";

function GameCard({game, getGameButton}: { game: OWGame, getGameButton: (game: OWGame) => ReactNode }): ReactElement {
    return (
        <div className="go-vip-overwolf_dual-rewards_game">
            <div
                className={classNames("go-vip-overwolf_dual-rewards_game_image", {
                    "go-vip-overwolf_dual-rewards_game_image_black-and-white-filter": !game.isEventSupportReady,
                })}
            >
                {game.thumbnailImage && <img src={game.thumbnailImage} alt={game.name}/>}
            </div>
            <div className="go-vip-overwolf_dual-rewards_game_details">
                <div className="go-vip-overwolf_dual-rewards_game_details_title">
                    <strong>{game.name}</strong>
                </div>
                {getGameButton(game)}
            </div>
        </div>
    );
}

export default function GoVipOWDualRewardingGames(): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const dispatch = useDispatch();
    const isOnMobile = isMobile && !isTablet;
    const [games, setGames] = useState<Array<OWGame>>([]);
    const [showDualRewardModal, setShowDualRewardModal] = useState(false);
    const {data: rewardsData} = useGetUserOverwolfXP(fullToken, GetOverwolfXpSubjectEnum.EVENTREWARD);
    const [game, setGame] = useState(null);

    useEffect(() => {
        void fetchGames();
    }, []);

    async function fetchGames() {
        try {
            const gamesData = await new OverwolfApi(getConfig(fullToken)).getOverwolfDualGames();
            setGames(gamesData);
            console.log("gamesData is: ", gamesData);
        } catch (e) {
            dispatch(await addError(e));
        }
    }

    function gameButtonClickHandler(game) {
        setShowDualRewardModal((prev) => !prev);
        setGame(game);
    }

    const getGameButton = useCallback((game: OWGame): ReactNode => {
        if (!game.isEventSupportReady) {
            return (
                <FrameButton
                    size="normal"
                    className="go-vip-overwolf_dual-rewards_game_coming-soon"
                >
                    Coming Soon
                </FrameButton>
            );
        }

        if (game.userStats?.some(stat => stat.progress > 0)) {
            return (
                <FrameButton
                    size="normal"
                    className="go-vip-overwolf_dual-rewards_game_track-rewards"
                    onClick={() => gameButtonClickHandler(game)}
                >
                    Track Rewards
                </FrameButton>
            );
        }

        return (
            <FrameButton
                size="normal"
                color="purple"
                onClick={() => gameButtonClickHandler(game)}
            >
                Play to Earn
            </FrameButton>
        );
    }, []);

    if (!games.length) {
        return null;
    }

    return (
        <div className="go-vip-overwolf_rewards">
            <div className="go-vip-overwolf_rewards_header">
                <div className="go-vip-overwolf_rewards_header_text">
                    <h3 className="go-vip-overwolf_heading">
                        Dual-Rewarding Games
                        <span>{games.filter(game => game.isEventSupportReady).length}</span>
                    </h3>

                    <div className="go-vip-overwolf_rewards_header_description">
                        Complete special quests {!isOnMobile ? "based on games you play" : ""} to earn XP
                    </div>
                </div>
            </div>

            <div className="go-vip-overwolf_dual-rewards">
                {games.map(game => (
                    <GameCard key={game.id} game={game} getGameButton={getGameButton}/>
                ))}

                <GoVipOWDualRewardModal
                    isOpen={showDualRewardModal}
                    questOnly
                    onClose={() => setShowDualRewardModal((prev) => !prev)}
                    rewards={rewardsData?.transactions ?? []}
                    game={game}
                />
            </div>
        </div>
    );
}
