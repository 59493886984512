import {ReactElement} from "react";
import {isDesktop} from "react-device-detect";
import ProgressBar from "@ramonak/react-progress-bar";
import {useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import useThemePreference from "@/hooks/useThemePreference";

function GoVipExperienceBar(): ReactElement {

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const accountLevel = useSelector((store: IStore) => store.metaStore.accountLevel);
    const { isOnDarkMode } = useThemePreference();
    return (
        <ProgressBar
            height="0.5rem"
            bgColor={isOnDarkMode
                ? "linear-gradient(270deg, #F1EDFF -22.04%, #9B7EFF 80.28%)"
                : "linear-gradient(270deg, #D0B4FF 12.86%, #7F3AF2 80.38%)"}
            baseBgColor={isOnDarkMode
                ? "linear-gradient(180deg, rgba(131, 129, 178, 0.50) 3.49%, rgba(181, 179, 189, 0.23) 87.36%), linear-gradient(180deg, rgba(18, 0, 72, 0.70) 0%, rgba(87, 68, 154, 0.70) 50%, rgba(120, 84, 246, 0.70) 100%)"
                : "#fff"}
            completed={fullToken && accountLevel
                ? accountLevel.experienceGainedAtThisLevel
                : 0}
            maxCompleted={fullToken && accountLevel
                ? accountLevel.experienceGainedAtThisLevel +
                accountLevel.experienceRequiredForNextLevel
                : 0}
            isLabelVisible={false}
        />
    );
}

export default GoVipExperienceBar;
