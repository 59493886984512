import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import classNames from "classnames";
import {isDesktop, isTablet} from "react-device-detect";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import {AccountExperienceTransaction} from "@devour/client";
import {FiX} from "react-icons/fi";
import FrameButton from "@/components/buttons/FrameButton";
import {AnchorHTMLAttributes} from "react";
import useThemePreference from "@/hooks/useThemePreference";
import GoVipOWTimeRewardsModalContent from "@/components/goVip/GoVipOWTimeRewardsModalContent";


interface Props {
    isOpen: boolean;
    onClose: () => void;
    rewards: Array<AccountExperienceTransaction>;
}

export default function GoVipOWTimeBasedRewardsModal(props: Props) {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {isOnDarkMode} = useThemePreference();

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            toggle={props.onClose}
            contentClassName={classNames("go-vip-ow_rewards-modal", {
                "authenticated": !!fullToken,
            })}
            size="xs2"
            modalOnTablet={true}
            fullScreenHeight={!!fullToken}
        >
            <div
                style={{
                    backgroundImage: `url(${import.meta.env.VITE_CDN_URL}/images/govip-ow-time-rewards-modal-bg-${isDesktop || isTablet ? "lg" : "sm"}.webp)`,
                }}
                className="go-vip-ow_rewards-modal_header"
            >
                <button className="reset-button go-vip-ow_rewards-modal_header_close">
                    <FiX
                        size={isOnDarkMode ? "2rem" : "1.5rem"}
                        strokeWidth="0.175rem"
                        onClick={props.onClose}
                    />
                </button>
                <div className="go-vip-ow_rewards-modal_header_text">
                    <img src={`${import.meta.env.VITE_CDN_URL}/images/govip-ow-devour-logo.png`} alt="DevourGO logo"/>
                    <h1>{fullToken ? "TRACK REWARDS" : "PLAY TO EARN"}</h1>

                    <div className="go-vip-ow_rewards-modal_header_text_description">
                        Earn 10 XP for every hour of gameplay!<br />The more you play, the more XP you collect.
                    </div>

                    <div className="go-vip-ow_rewards-modal_header_text_disclaimer">
                        * Max XP you can earn is 1,000 per week.
                    </div>


                </div>
            </div>

            <FrameAutoPanelBody className="go-vip-ow_rewards-modal_body">
                {!fullToken
                    ? <FrameButton
                        <AnchorHTMLAttributes<HTMLAnchorElement>>
                        size="normal"
                        className="go-vip-ow_rewards-modal_body_download-btn"
                        href={"https://www.overwolf.com/app/Devour-DevourPlay"}
                        forwardProps={{
                            target: "_blank",
                            rel: "noopener noreferrer",
                        }}
                    >
                        Download to PC
                    </FrameButton>
                    : <GoVipOWTimeRewardsModalContent rewards={props.rewards}/>
                }
            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}
