import {useQuery} from "@tanstack/react-query";
import {MenuOrdersApi} from "@devour/client";
import getConfig from "@/utils/getConfig";
import { useEffect, useRef } from "react";

export const useMenuOrder = (menuOrderId: string, disableValidationWhenFetched = false) => {
    const isFetched = useRef(false);
    // Define the query function directly inside useQuery
    const result = useQuery({
        queryKey: [
            "menuOrder",
            menuOrderId,
        ],
        queryFn: async () => {
            if (!menuOrderId) {
                return null;
            }
            return new MenuOrdersApi(getConfig()).getMenuOrder({
                id: menuOrderId,
                // validateOrderItems: disableValidationWhenFetched && isFetched.current ? undefined : "true", // turning this off because we currently believe this is redundant
            });
        },
        // Options object
        enabled: Boolean(menuOrderId), // Conditional fetching based on menuOrderId presence
    });

    useEffect(() => {
        if (result.isFetchedAfterMount) {
            isFetched.current = true;
        }
    }, [result.isFetchedAfterMount]);

    return {
        data: result.data,
        refetch: result.refetch,
        isFetching: result.isFetching,
        isFetchedAfterMount: result.isFetchedAfterMount,
        isLoading: result.isLoading,
    };
};
