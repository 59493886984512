import {ReactElement, useState} from "react";
import FrameOneModal from "@/components/modals/modalComponents/FrameOneModal";
import FrameModalHeader from "@/components/modals/modalComponents/FrameModalHeader";
import SpinToWin from "@/components/games/SpinToWin";
import classNames from "classnames";
import {isMobile} from "react-device-detect";
import { useSelector } from "react-redux";
import { IStore } from "@/redux/defaultStore";
export const AnonymousSpinModalShownKey = "spinModalShown";
interface Props {
    isOpen: boolean;
    toggle: () => void;
    anonymousPrompt?: boolean;
}

function GoVipSpin2WinModal(props: Props): ReactElement {
    const [
        spinLocked,
        setSpinLocked,
    ] = useState<boolean>(false);
    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.toggle}
            size={props.anonymousPrompt ? "sm" : "sm2"}
            contentClassName={classNames("go-vip-spin-to-win-modal", {
                "anonymous-spin-2-win": props.anonymousPrompt,
            })}
            disableOverlayDismiss={spinLocked}
        >
            <div
                className={classNames(
                    "go-vip-spin-to-win-modal_close-button",
                    spinLocked && "go-vip-spin-to-win-modal_close-button_hidden",
                )}
            >
                <FrameModalHeader
                    title=""
                    toggle={props.toggle}
                />
            </div>
            <h2 className={"go-vip-spin-to-win-modal_title"}>
                { !props.anonymousPrompt ? "Spin the Wheel" : <>Wait! New Users {isMobile && <br/>}Get a Free Spin 🔥</>}
            </h2>
            <p className={classNames(
                "go-vip-spin-to-win-modal_description",
                isMobile && "go-vip-spin-to-win-modal_description_mobile",
            )}>
                { !props.anonymousPrompt ? "Get your daily level XP for more rewards!" : "Join DevourGO for exclusive rewards"}
            </p>
            <SpinToWin
                toggle={props.toggle}
                anonymousPrompt={props.anonymousPrompt}
                setSpinLocked={(b) => setSpinLocked(b)}
            />
        </FrameOneModal>
    );
}

export default GoVipSpin2WinModal;
