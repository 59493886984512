/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The type of overwolf user session event
 * @export
 * @enum {string}
 */
export enum OWUserSessionEventType {
    LOGIN = 'USER_LOGIN',
    SIGNUP = 'USER_SIGNUP',
    RETURNING = 'USER_RETURNING'
}

export function OWUserSessionEventTypeFromJSON(json: any): OWUserSessionEventType {
    return OWUserSessionEventTypeFromJSONTyped(json, false);
}

export function OWUserSessionEventTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OWUserSessionEventType {
    return json as OWUserSessionEventType;
}

export function OWUserSessionEventTypeToJSON(value?: OWUserSessionEventType | null): any {
    return value as any;
}

