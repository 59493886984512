import {ReactElement} from "react";
import {useParams} from "react-router";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import Checkout from "./Checkout";

function CheckoutPage(): ReactElement {
    const {menuOrderId: paramMenuOrderId} = useParams<{ menuOrderId: string }>();
    const {data: menuOrder} = useMenuOrder(paramMenuOrderId);

    if (!menuOrder) {
        return;
    }

    return (
        <RestaurantContext.Provider value={{
            restaurantId: menuOrder.business,
            menuOrderId: paramMenuOrderId,
        }}>
            <Checkout />
        </RestaurantContext.Provider>
    );
}

export default CheckoutPage;


