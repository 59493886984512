import {ButtonHTMLAttributes, ReactElement, useContext, useState} from "react";
import FrameButton from "./buttons/FrameButton";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {RestaurantContext} from "../pages/restaurants/context/RestaurantContext";
import {toggleLoginOpen, updateLastMenuOrderId} from "../redux/meta/metaActions";
import {HiArrowRight} from "react-icons/hi";
import {BrandMapStates} from "@/pages/brandMap/context/BrandMapContext";
import { useOnLogin } from "@/hooks/useOnLogin";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {IStore} from "@/redux/defaultStore";

interface Props {
    toggle: () => void;
    preCheckoutAction?: () => void;
    disabled?: boolean;
    subtotal?: number;
}

function CartCheckoutButton(props: Props): ReactElement {
    const [hasPendingCheckout, setHasPendingCheckout] = useState<boolean>(false);
    const history = useNavigate();
    const dispatch = useDispatch();
    const fullToken = useSelector<IStore>(state => state.authStore.fullToken);
    const {menuOrderId, embeddedMenu, setCheckoutState} = useContext(RestaurantContext);
    const {data: menuOrder} = useMenuOrder(menuOrderId);

    function onCheckoutAttempt() {
        dispatch(updateLastMenuOrderId(menuOrderId));

        if (props.preCheckoutAction) {
            props.preCheckoutAction();
        }

        if (embeddedMenu) {
            props.toggle();
            setCheckoutState(BrandMapStates.CHECKOUT_IN_PROGRESS);
            return;
        }
        if (fullToken) {
            history(`/checkout/${menuOrderId}`);
        } else {
            setHasPendingCheckout(true);
            dispatch(toggleLoginOpen(true));
        }
    }

    useOnLogin(() => {
        if (hasPendingCheckout && menuOrder) {
            history(`/checkout/${menuOrderId}`);
            setHasPendingCheckout(false);
        }
    }, [menuOrder]);


    return (
        <>
            <FrameButton
                <ButtonHTMLAttributes<HTMLButtonElement>>
                color={props.disabled
                    ? "gray"
                    : "purple"}
                size="large"
                onClick={onCheckoutAttempt}
                showSpinner={true}
                className={`cart-checkout-button ${props.disabled
                    ? " disabled"
                    : ""}`}
            >
                <span>Checkout</span>
                {!props.disabled
                    ? <span>
					${(props.subtotal !== undefined ? props.subtotal : menuOrder.subtotal).toFixed(2)}
                        <HiArrowRight size=".5em"/>
                    </span>
                    : <span>
					$0.00
                        <HiArrowRight size=".5em"/>
                    </span>
                }
            </FrameButton>
        </>
    );
}

export default CartCheckoutButton;
