import {ReactElement, useState} from "react";
import {FiAlertCircle} from "react-icons/fi";
import Share2EarnDetailsModal from "../modals/Share2EarnDetailsModal";
import Share2EarnModal from "../modals/Share2EarnModal";
import GoVipShare2EarnActionCard from "./GoVipShare2EarnActionCard";
import FrameButton from "../buttons/FrameButton";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import Spacer from "../Spacer";
import {useDispatch, useSelector} from "react-redux";
import {addError, decrementModalCount, updateAccountLevel} from "@/redux/meta/metaActions";
import {UsersApi} from "@devour/client";
import {IStore} from "@/redux/defaultStore";
import Toast from "../Toast";
import {useNavigate} from "react-router";
import getConfig from "../../utils/getConfig";
import GoVipPageShare2EarnSkeleton from "../skeletons/GoVipPage/GoVipPageShare2EarnSkeleton";
import {useGetGleamActions} from "@/hooks/useGetGleamActions";
import SignUpToast from "../SignUpToast";

function GoVipShare2Earn(): ReactElement {

    const [
        showShare2EarnDetailsModal,
        setShowShare2EarnDetailsModal,
    ] = useState<boolean>(false);
    const [
        showGleamModal,
        setShowGleamModal,
    ] = useState<boolean>(false);

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const accountLevel = useSelector((store: IStore) => store.metaStore.accountLevel);
    const dispatch = useDispatch();

    const history = useNavigate();
    const [
        showSignupToast,
        setShowSignupToast,
    ] = useState<boolean>(false);

    const [
        expDifference,
        setExpDifference,
    ] = useState<number>(0);
    const [
        showExpToast,
        setShowExpToast,
    ] = useState<boolean>(false);

    const {data: gleamActionsData, refetch: refetchGleamActionData} = useGetGleamActions(fullToken
        ? currentUser?.user?.email
        : "NOT_LOGGED_IN");
    const gleamActions = gleamActionsData?.actions;


    function toggleShare2EarnDetailsModal(): void {
        setShowShare2EarnDetailsModal(!showShare2EarnDetailsModal);
    }

    function handleSignupToastDismissal() {
        setShowSignupToast(false);
    }

    async function calculatePointDifference() {

        try {
            // get the points of the user before the modal closes
            const updatedAccountLevel = await new UsersApi(getConfig(fullToken)).getLevel({
                id: currentUser.user.id,
            });

            // compare it to the state
            const earnedExp = updatedAccountLevel.totalExperience - accountLevel.totalExperience;

            // update redux
            await dispatch(updateAccountLevel(updatedAccountLevel));

            // decrement modal count
            await dispatch(decrementModalCount());

            // if there's a difference then toast
            if (earnedExp > 0) {
                setExpDifference(earnedExp);
                setShowExpToast(true);
            }

        } catch (err) {
            dispatch(await addError(err));
        }

    }

    function toggleGleamModal(): void {
        if (showGleamModal) {
            refetchGleamActionData();
        }
        if (fullToken) {
            void calculatePointDifference();
        }
        setShowGleamModal(!showGleamModal);
    }

    async function handleExpToastDismissal() {
        setShowExpToast(false);
        setExpDifference(0);
    }


    function renderActionCard() {
        return gleamActions.map((action) => <GoVipShare2EarnActionCard
            toggle={fullToken
                ? toggleGleamModal
                : () => setShowSignupToast(true)}
            action={action}
            key={`go-vip-share2earn-action-card-${action.name}`}
        />);
    }

    return (
        <>
            <Toast
                forceWidth="30rem"
                message={`Congrats! You've earned ${expDifference} GoVIP XP with Share2Earn.`}
                isOpen={showExpToast}
                showButton={true}
                buttonMessage="Got it!"
                onDismiss={handleExpToastDismissal}
            />
            <SignUpToast
                isOpen={showSignupToast}
                onDismiss={handleSignupToastDismissal}
            />
            <Share2EarnDetailsModal
                toggle={toggleShare2EarnDetailsModal}
                isOpen={showShare2EarnDetailsModal}
            />
            <Share2EarnModal
                isOpen={showGleamModal}
                toggle={toggleGleamModal}
            />
            <div className="go-vip-share2earn">

                <div className="go-vip-share2earn_header">
                    <div className="go-vip-share2earn_header_row">
                        <h2>
                            Share2Earn
                        </h2>
                        <FiAlertCircle
                            onClick={toggleShare2EarnDetailsModal}
                            className="go-vip-share2earn_header_row_info-icon"
                        />
                        {(isDesktop || isTablet) &&
                        <>
                            <Spacer/>
                            <div
                                className="go-vip-share2earn_show-all-link-container"
                                onClick={fullToken
                                    ? toggleGleamModal
                                    : () => setShowSignupToast(true)}
                            >
                                <p className="go-vip-share2earn_show-all-link-container_link">
                                        Show all
                                </p>
                            </div>
                        </>
                        }
                    </div>
                    <p className="go-vip-share2earn_header_subheader">
                        Level up your account by sharing!
                    </p>
                </div>
                {gleamActions
                    ? <>
                        <div className="go-vip-share2earn_body">
                            {gleamActions.length > 0
                                ? <>
                                    {renderActionCard()}
                                    {isMobile && !isTablet && gleamActions.length > 4 &&
                                    <>
                                        <div className="go-vip-share2earn_body_actions-remaining-tag">
                                            <p>{`+${gleamActions.length - 4} more`}</p>
                                        </div>
                                        <div className="restaurant-page_mobile-fade"/>
                                    </>
                                    }
                                </>
                                : <div className="go-vip-share2earn_body_no-actions-container">
                                    <img
                                        src={import.meta.env.VITE_CDN_URL + "/images/firework.svg"}
                                        className="go-vip-share2earn_body_no-actions-container_graphic"
                                        alt="firework"
                                    />
                                    <p>
                                            Yay! You completed all Share2Earn actions! Stay tuned for more.
                                    </p>
                                </div>
                            }
                        </div>

                        {isMobile && !isTablet &&
                        <FrameButton
                            color="purple"
                            size="normal"
                            onClick={fullToken
                                ? toggleGleamModal
                                : () => setShowSignupToast(true)}
                            className="go-vip-share2earn_show-all-button"
                        >
                                    View all
                        </FrameButton>
                        }
                    </>
                    : <GoVipPageShare2EarnSkeleton/>
                }
            </div>
        </>
    );
}

export default GoVipShare2Earn;
