import getConfig from "@/utils/getConfig";
import {OverwolfApi, OWReward} from "@devour/client";
import {useQuery} from "@tanstack/react-query";

async function fetchOverwolfGameRewards(gameId: string): Promise<OWGames> {
    return await new OverwolfApi(getConfig()).getOverwolfGameRewards({
        id: gameId,
    });
}

function useGetOverwolfGameRewardsQuery(gameId: string, enabled: boolean = true) {
    return {
        queryKey: [
            "overwolf-game-rewards",
            gameId,
        ],
        queryFn: () => gameId ? fetchOverwolfGameRewards(gameId) : null,
        staleTime: 30 * 24 * 60 * 60000,
        enabled: !!gameId && enabled,
    } as const;
}

export default function useGetOverwolfGameRewards(gameId: string, enabled: boolean = true) {
    return useQuery<OWReward[]>(useGetOverwolfGameRewardsQuery(gameId, enabled));
}
