import {ReactElement} from "react";
import {RankedMember} from "@devour/client";
import GoFrensLeaderboardRow from "@/components/goFrens/GoFrensLeaderboardRow";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import useThemePreference from "@/hooks/useThemePreference";
import GoFrensTopMembersEmptyState from "@/components/goFrens/GoFrensTopMembersEmptyState";
import GoFrensTopLeaderboardCommunityTableHeader
    from "@/components/goFrens/topLeaderboard/GoFrensTopLeaderboardCommunityTableHeader";

interface Props {
    rankData: Array<RankedMember>;
    className?: string;
    isModal?: boolean;
}
function GoFrensMyCommunityLeaderboardBoard(props: Props): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: userData} = useGetUserProfile(fullToken);
    const {isOnDarkMode} = useThemePreference();

    const myRankIcon =
        <span className="my-rank-icon">
            Me
        </span>;


    function getBgColor(member: RankedMember) {
        if (props.className === "around-user-ranks") {
            return userData.user?.id === member.id
                ? "light-gray"
                : "white";
        }
        return member.rankWithinCommunity % 2 === 1
            ? "light-gray"
            : "white";

    }

    function renderBoardRow(member: RankedMember) {
        return (
            <GoFrensLeaderboardRow
                key={member.id}
                rank={member.rankWithinCommunity}
                profile={{
                    name: member.nickname
                        ? member.nickname
                        : "GoVIP Player",
                    photo: member.gravatar,
                }}
                accumulatedPoints={member.pointsEarnedForCommunity}
                rightIconToName={
                    props.className === "around-user-ranks" && userData.user?.id === member.id && myRankIcon
                }
                bgColor={`${isOnDarkMode ? "DM-" : ""}${getBgColor(member)}`}
                className={userData.user?.id === member.id && "gofrens-leaderboard-row_my-rank"}
            />
        );
    }

    if (!userData) {
        return;
    }

    // user is not ranked
    if (props.className === "around-user-ranks" && props.rankData.find(u => u.id === userData.user?.id)?.rankWithinCommunity == null) {
        return (
            <div className="my-community-leaderboard_user-unranked">
                <p>Your rank leaderboard will be displayed here along with two other competitors, once you have more
                than 1 XP.</p>
                <GoFrensLeaderboardRow
                    rank={props.rankData[0].rankWithinCommunity}
                    profile={{
                        name: props.rankData[0].nickname
                            ? props.rankData[0].nickname
                            : "GoVIP Player",
                        photo: props.rankData[0].gravatar,
                    }}
                    accumulatedPoints="0 XP"
                    className="gofrens-leaderboard-row_user-unranked"
                    bgColor={isOnDarkMode ? "DM-light-gray" : "light-gray"}
                />
            </div>
        );
    }

    if ((props.className === "members-top-ranks" || props.className === "members-leaderboard-modal-view_body_board") &&
        !props.rankData.length) {
        return <GoFrensTopMembersEmptyState isCurrent={props.className === "members-top-ranks"}/>;
    }

    return (

        /*
         * <div className="my-community-leaderboard">
         *     <GoFrensTopLeaderboardCommunityTableHeader />
         */
        <div className="my-community-leaderboard_body">
            {props.rankData.map(member => renderBoardRow(member))}
        </div>
        // </div>
    );
}

export default GoFrensMyCommunityLeaderboardBoard;