import {
    ButtonHTMLAttributes,
    ChangeEventHandler,
    FormEvent,
    ReactElement,
    ReactNode,
    useContext,
    useEffect, useRef,
    useState,
} from "react";
import ReactDOM from "react-dom";
import {
    GetMenuResponse,
    MenuItem,
    MenuOrderItem,
    MenuCustomization,
    MenuOrderSubItem,
    UtilsApi,
    Address,
    MenuOrder,
} from "@devour/client";
import FrameOneAutoPanel from "./autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelBody from "./autoPanelComponents/FrameAutoPanelBody";
import FrameAutoPanelFooter from "./autoPanelComponents/FrameAutoPanelFooter";
import FrameButton from "../buttons/FrameButton";
import {
    addError,
    decrementLoading,
    incrementLoading,
} from "@/redux/meta/metaActions";
import MenuItemCustomizationFormCustomizationSelection
    from "../MenuItemCustomizationFormCustomizationSelection";
import {AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";
import {calculateTotalLoop} from "@/utils/calculateMenuOrderTotal";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {MdClose} from "react-icons/md";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import {htmlDecode} from "@/utils/htmlDecode";
import classNames from "classnames";
import Toast from "@/components/Toast";
import {useQueryClient} from "@tanstack/react-query";
import {cloneDeep} from "lodash";
import {findMenuCustomizationItemResponse} from "@/utils/validateMenuOrderItem";
import {useCreateMenuOrderItems} from "@/hooks/menuOrder/useCreateMenuOrderItems";
import {useUpdateMenuOrderItems} from "@/hooks/menuOrder/useUpdateMenuOrderItems";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {useRestaurant} from "@/hooks/useRestaurant";

const defaultValues: MenuOrderItem = {
    menuItemId: "",
    quantity: 1,
    price: 0, // Price property is required but will be overwritten by backend
    subtotal: 0, // Subtotal property is required but will be overwritten by backend
    taxRate: 0, // Tax rate property is required but will be overwritten by backend
    tax: 0, // Tax property is required but will be overwritten by backend
    name: "", // Name property is required but will be overwritten by backend
    customizations: [],
};

interface Props {
    isOpen: boolean;
    placeId: string;
    restaurantId: string;
    restaurantMenu: GetMenuResponse;
    menuItem: MenuItem;
    onClose: () => void;
    onDone: (menuOrder: MenuOrder) => void;
    defaultValues?: MenuOrderItem;
    replaceIndex?: number;
}

function MenuItemCustomizationModal(props: Props): ReactElement {
    const dispatch = useDispatch();
    const {restaurantId, menuOrderId, embeddedMenu} = useContext(RestaurantContext);
    const {data: menuOrder} = useMenuOrder(menuOrderId);
    const {data: restaurant} = useRestaurant(restaurantId);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const existingMenuOrderId = menuOrder?.id;
    const [formValues, setFormValues] = useState<MenuOrderItem>(defaultValues);
    const [scrollTop, setScrollTop] = useState<number>(0);
    const [headerImageHeight, setHeaderImageHeight] = useState<number>(0);
    const [invalidCustomizations, setInvalidCustomizations] = useState<Array<string>>([]);
    const [showInvalidCustomizationsToast, setshowInvalidCustomizationsToast] = useState<boolean>(false);
    const queryClient = useQueryClient();
    const isEditing = props.replaceIndex >= 0;
    const {
        mutateAsync: createMenuOrderItems,
    } = useCreateMenuOrderItems({
        restaurantId: restaurant?.id,
    });
    const {
        mutateAsync: updateMenuOrderItems,
    } = useUpdateMenuOrderItems({
        menuOrder: menuOrder,
        menuOrderErrorModal: true,
        backgroundCallback: true,
    });

    const targetElementRef = useRef<Element | null>(null);

    useEffect(() => {
        if (embeddedMenu) {
            targetElementRef.current = document.querySelector(".brand-map-apply-theme_container");
        }
    }, [embeddedMenu]);

    useEffect(() => {
        if (props.isOpen) {
            defaultMenuCustomizations();
            setInvalidCustomizations([]);
        }
    }, [props.isOpen, props.menuItem, props.defaultValues]);

    useEffect(() => {
        if (invalidCustomizations.length > 0) {
            setshowInvalidCustomizationsToast(true);
        }
    }, [invalidCustomizations]);


    function handleToastDismissal(): void {
        setshowInvalidCustomizationsToast(false);
    }

    /**
     * Default menu item customizations.
     *
     */
    function defaultMenuCustomizations(): void {
        if (props.defaultValues) {
            const newCustomizations: Array<MenuOrderSubItem> = props.defaultValues.customizations.filter((customizationItem) => {
                const menuCustomizationItem = findMenuCustomizationItemResponse(customizationItem, props.menuItem.customizations);
                return menuCustomizationItem?.isEnabled;
            }).map(transformMenuOrderSubItem);

            setFormValues({
                ...props.defaultValues,
                customizations: newCustomizations,
            });
            return;
        }
        if (!props.menuItem?.customizations) {
            return;
        }

        const newCustomizations: Array<MenuOrderSubItem> = [];
        for (const customization of props.menuItem.customizations) {
            for (const customizationItem of customization.options) {
                if (customizationItem.defaultQuantity && customizationItem.isEnabled) {
                    // Add this option
                    newCustomizations.push({
                        menuCustomizationItemId: customizationItem.id,
                        quantity: customizationItem.defaultQuantity,
                        // price: 0, // Price property is required but will be overwritten by backend
                        price: customizationItem.price, // Price property is required but will be overwritten by backend
                        taxRate: 0, // Tax rate property is required but will be overwritten by backend
                        name: "", // Name property is required but will be overwritten by backend
                    });
                }
            }
        }

        setFormValues((formValues) => {
            return {
                ...formValues,
                price: props.menuItem.price,
                customizations: newCustomizations,
            };
        });
    }

    function transformMenuOrderSubItem(item: MenuOrderSubItem): MenuOrderSubItem {
        return {
            ...item,
            menuCustomizationItemId: item.externalId || item.menuCustomizationItemId,
            customizations: item.customizations?.map(transformMenuOrderSubItem),
        };
    }

    /**
     * Handle all text input onChange events.
     *
     * @param key
     */
    function inputOnChange(key: keyof MenuOrderItem): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> {
        return (e) => {
            setFormValues((formValues) => {
                return {
                    ...formValues,
                    [key]: e.target.value,
                };
            });
        };
    }

    /**
     * Handle all menu item customization onChange events.
     *
     * @param newCustomizations
     */
    function customizationsOnUpdate(newCustomizations: Array<MenuOrderSubItem>) {
        setFormValues((formValues) => {
            return {
                ...formValues,
                customizations: newCustomizations,
            };
        });
    }

    async function createNewShoppingCart() {
        dispatch(incrementLoading());
        try {
            const findAddressInBook = currentUser?.user?.addresses.find(a => a.placeId === props.placeId);
            let address: Address;
            if (props.placeId) {
                address = await new UtilsApi().getAddressFromPlaceId({
                    placeId: props.placeId,
                });
            } else {
                address = restaurant.address;
            }

            const newMenuOrder = await createMenuOrderItems({
                address: address,
                orderItems: [{
                    ...formValues,
                    menuItemId: props.menuItem.id,
                }],
                deliveryNotes: findAddressInBook?.deliveryInstructions,
                deliveryHandoffInstructions: findAddressInBook?.handoffInstructions,
            });

            props.onDone(newMenuOrder);
        } catch (e) {
            dispatch(await addError(e));
            await queryClient.invalidateQueries({queryKey: ["restaurant-menu", restaurant.id]});
        } finally {
            dispatch(decrementLoading());
        }
    }

    async function updateExistingShoppingCart() {
        dispatch(incrementLoading());

        const newOrderItemsBody: Array<MenuOrderItem> = cloneDeep(menuOrder.orderItems);
        if (isEditing) {
            if (formValues.quantity === 0) {
                // Remove item from cart
                newOrderItemsBody.splice(props.replaceIndex, 1);
            } else {
                // Update existing item in cart
                newOrderItemsBody[props.replaceIndex] = {
                    ...formValues,
                    menuItemId: props.menuItem.id,
                };
            }
        } else {
            // add new item to cart
            newOrderItemsBody.push({
                ...formValues,
                menuItemId: props.menuItem.id,
            });
        }

        const newMenuOrder = await updateMenuOrderItems({
            orderItems: newOrderItemsBody,
        });

        dispatch(decrementLoading());

        if (newMenuOrder) {
            props.onDone(newMenuOrder);
        }
    }

    function onFormSubmit(e: FormEvent<HTMLFormElement>): void {
        e.preventDefault();
        const invalids = updateInvalids();
        // If there are no invalids, submit the form
        if (invalids.length === 0) {
            if (existingMenuOrderId) {
                void updateExistingShoppingCart();
            } else {
                void createNewShoppingCart();
            }
        }
    }

    function updateInvalids() {
        const newInvalids = [];
        for (const customization of props.menuItem.customizations) {
            if (customization.minSelects) {
                const selectedCustomizations = customization.options.filter(c => formValues.customizations.find(f => f.menuCustomizationItemId === c.id));
                if (selectedCustomizations.length < customization.minSelects) {
                    newInvalids.push(customization.id);
                }
            }
        }
        setInvalidCustomizations(newInvalids);
        return newInvalids;
    }


    function renderCustomization(customization: MenuCustomization): ReactNode {
        return (
            <MenuItemCustomizationFormCustomizationSelection
                key={customization.id}
                invalid={invalidCustomizations.indexOf(customization.id) !== -1}
                customization={customization}
                parentCustomizationArray={formValues.customizations}
                onUpdate={customizationsOnUpdate}
                accordion={false}
            />
        );
    }

    /**
     * Increment quantity of item by 1.
     *
     */
    function addQuantity(): void {
        setFormValues((formValues) => {
            return {
                ...formValues,
                quantity: formValues.quantity + 1,
            };
        });
    }

    /**
     * Decrease quantity of item by 1 as long as it isn't going below 0.
     *
     */
    function subtractQuantity(): void {
        if (formValues.quantity > 0) {
            setFormValues((formValues) => {
                return {
                    ...formValues,
                    ...formValues,
                    quantity: formValues.quantity - 1,
                };
            });
        }
    }

    if (!props.restaurantMenu || !props.menuItem) {
        return null;
    }

    const handleRef = (node: HTMLDivElement | null) => {
        if (!node) {
            return;
        }
        const handleScroll = () => {
            setScrollTop(node.scrollTop);
        };
        node.addEventListener("scroll", handleScroll);
    };

    if (!restaurant) {
        return null;
    }

    const modalContent =
        <>
            <Toast
                variant="error"
                message="Oops! Please make sure all required modifiers are selected before adding to your cart."
                isOpen={showInvalidCustomizationsToast}
                showButton={false}
                onDismiss={handleToastDismissal}
                removeMarginAdjustment={true}
            />
            <form
                onSubmit={onFormSubmit}
            >
                <FrameOneAutoPanel
                    isOpen={props.isOpen}
                    toggle={props.onClose}
                    size="sm2"
                    maxHeight={true}
                    contentClassName="menu-item-customization-modal"
                    containerClassName="menu-item-customization-modal_container"
                    modalOnTablet={true}
                    disableFullHeight={true}
                >
                    <FrameAutoPanelBody
                        className="menu-item-customization-modal_body"
                        modalBodyRef={handleRef}
                    >
                        {props.menuItem.images?.length > 0 &&
                            <div className="menu-item-customization-modal_body_img" ref={(ref) => {
                                if (ref) {
                                    setHeaderImageHeight(ref.clientHeight);
                                }
                            }}>
                                <button
                                    className="menu-item-customization-modal_body_img_back"
                                    type="button"
                                    onClick={props.onClose}
                                >
                                    <MdClose/>
                                </button>
                                <img
                                    src={props.menuItem?.images[0].url}
                                    alt={props.menuItem?.name}
                                    onError={({currentTarget}) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = `${import.meta.env.VITE_CDN_URL}/images/placeholderitem.webp`;
                                    }}
                                />
                            </div>
                        }

                        <div className={classNames("menu-item-customization-modal_body_header", {
                            "sticky": scrollTop > headerImageHeight || !props.menuItem.images.length,
                        })}>
                            <h3>{htmlDecode(props.menuItem?.name)}</h3>
                            <span>
                                <MdClose size={"1.25em"} onClick={props.onClose}/>
                            </span>
                        </div>

                        <div className="menu-item-customization-modal_body_details">
                            {!props.menuItem.images?.length
                                ? <br/>
                                : null}
                            <div className={"menu-item-customization-modal_body_details_price"}>
                                ${props.menuItem.price.toFixed(2)}
                            </div>

                            {props.menuItem.description &&
                                <div className="menu-item-customization-modal_body_details_desc">
                                    {htmlDecode(props.menuItem.description)}
                                </div>
                            }
                        </div>

                        <div className="menu-item-customization-form">
                            {props.menuItem.customizations?.sort((a, b) => a.sortOrder - b.sortOrder).map(renderCustomization)}

                            {restaurant?.specialInstructions &&
                                <div className="menu-item-customization-form_notes">
                                    <h4 className="menu-item-customization-form_notes_title">Special
                                        Instructions</h4>
                                    <textarea
                                        placeholder="Add a note..."
                                        value={formValues.notes}
                                        onChange={inputOnChange("notes")}
                                    />
                                </div>
                            }
                        </div>
                    </FrameAutoPanelBody>
                    <FrameAutoPanelFooter>
                        <div className="menu-item-customization-form_quantity">
                            <FrameButton
                                <ButtonHTMLAttributes<HTMLButtonElement>>
                                onClick={subtractQuantity}
                                color={!isEditing && formValues.quantity <= 1
                                    ? "gray"
                                    : "gray-light"}
                                size="icon"
                                leftIcon={AiOutlineMinus}
                                forwardProps={{
                                    type: "button",
                                    disabled: !isEditing && formValues.quantity <= 1,
                                }}
                            />
                            <div className="menu-item-customization-form_quantity_value">
                                {formValues.quantity}
                            </div>
                            <FrameButton
                                <ButtonHTMLAttributes<HTMLButtonElement>>
                                onClick={addQuantity}
                                color="gray-light"
                                size="icon"
                                leftIcon={AiOutlinePlus}
                                forwardProps={{type: "button"}}
                            />
                        </div>

                        <div className="menu-item-customization-modal_submit-container">
                            <FrameButton
                                <ButtonHTMLAttributes<HTMLButtonElement>>
                                color={isEditing && formValues.quantity === 0 ? "danger" : "purple"}
                                size="large"
                                forwardProps={{type: "submit"}}
                                className={`menu-item-customization-modal_submit-container_${isEditing && formValues.quantity === 0 ? "remove-" : ""}button`}
                                showSpinner={true}
                            >
                                <span>
                                    {formValues.quantity === 0 ? "Remove from cart"
                                        : isEditing
                                            ? "Update cart item"
                                            : "Add to cart"}
                                </span>
                                {formValues.quantity > 0 &&
                                    <span>
                                        ${calculateTotalLoop([formValues], 1)?.subtotal?.toFixed(2)}
                                    </span>
                                }
                            </FrameButton>
                        </div>
                    </FrameAutoPanelFooter>
                </FrameOneAutoPanel>
            </form>
        </>;

    /*
     * If on the brandmap page, portal the modal to the .restaurant-map-landing element
     * This fixes the mobile browser issue of the modal being clipped inside of the "order now" section.
     */
    if (embeddedMenu && targetElementRef.current) {
        return ReactDOM.createPortal(modalContent, targetElementRef.current);
    }

    // Render directly without portal if embeddedMenu is false (it's not on brand map)
    return modalContent;
}

export default MenuItemCustomizationModal;
