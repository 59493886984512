import {ButtonHTMLAttributes, ReactElement, useContext, useState} from "react";
import FrameButton from "@/components/buttons/FrameButton";
import {BsCreditCardFill} from "react-icons/bs";
import {IoIosCheckmarkCircle} from "react-icons/io";
import {StripePaymentMethodObject} from "@/types/Stripe";
import CheckoutPaymentRemoveCreditModal
    from "@/components/checkout/checkoutPayments/credit/CheckoutPaymentRemoveCreditModal";
import Skeleton from "@/components/skeletons/Skeleton";
import classNames from "classnames";
import {useDeleteCreditPaymentMethod} from "@/hooks/creditPaymentMethod/useDeleteCreditPaymentMethod";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import useThemePreference from "@/hooks/useThemePreference";

interface Props {
    exitOnDelete: boolean;
    info: string;
    onClose: () => void;
    payment: StripePaymentMethodObject;
    selected: boolean;
    selectCreditCard: () => Promise<void>;
}

function CheckoutPaymentCreditButton(props: Props): ReactElement {
    const { menuOrderId } = useContext(RestaurantContext);
    const { getThemedImageUrl } = useThemePreference();

    const [
        removePayment,
        setRemovePayment,
    ] = useState<string>("");

    /**
     * Use React Query to delete a credit card as the payment method
     */
    const {
        mutate: deleteCreditCard,
        isPending: isDeleteCreditCardLoading,
    } = useDeleteCreditPaymentMethod(menuOrderId);

    return (
        <>
            <CheckoutPaymentRemoveCreditModal // Remove a Credit Card Confirmation Modal
                exitOnDelete={props.exitOnDelete}
                exit={props.onClose} // Closes the credit card modal
                paymentId={removePayment}
                isOpen={!!removePayment} // Checks if string is not empty
                onClose={() => setRemovePayment("")} // Closes Remove Credit Confirmation Modal
                deleteCreditCard={deleteCreditCard}
            />

            <div className="checkout-payments-credit_button_group">
                {isDeleteCreditCardLoading // skeleton the button on deletion
                    ? <Skeleton
                        rows={1}
                        height={50}
                    />
                    : <>
                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            color="white-drop-shadow"
                            size="large"
                            className={classNames("checkout-payments-credit_button", {
                                "checkout-payments-credit_button_selected": props.selected,
                            })}
                            onClick={async () => await props.selectCreditCard()}
                        >

                            <div className="checkout-payments-credit_button_left">
                                <BsCreditCardFill/>

                                <p>{props.info}</p>
                            </div>

                            {props.selected &&
                            <IoIosCheckmarkCircle
                                className="checkout-payments-credit_button_checkmark"
                            />
                            }

                        </FrameButton>

                        <img
                            className="checkout-payments-credit_button_x"
                            src={getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/cancel-lighter-gray.svg`)}
                            alt="x"
                            onClick={() => setRemovePayment(props.payment.id)}
                        />
                    </>
                }
            </div>
        </>
    );
}

export default CheckoutPaymentCreditButton;
