import {OverwolfXPTransaction} from "@devour/client";

interface Props {
    reward: OverwolfXPTransaction;
}

interface OverwolfXPTransactionGame {
    gameId: string;
    image?: string;
    title: string;
    timePlayed: number;
}

export default function GoVipOWTimeBasedRewardCard(props: Props) {
    const {reward} = props;
    function formatDate(date: number) {
        const time = new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        }).format(date);

        const fullDate = new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
        }).format(date);

        return `${time} | ${fullDate}`;
    }

    return (
        <div className="go-vip-ow_time-reward-card">
            <div className="go-vip-ow_time-reward-card_header">
                <strong className="go-vip-ow_time-reward-card_timestamp">{formatDate(reward.createdAt)}</strong>
                <strong className="go-vip-ow_time-reward-card_points">+ {reward.amount} XP</strong>
            </div>
            {reward.games?.length > 0
                ? <div className="go-vip-ow_time-reward-card_games">
                    {reward.games?.map((game: OverwolfXPTransactionGame) =>
                        <div
                            key={`${reward.id}_${game.gameId}`}
                            className="go-vip-ow_time-reward-card_games_game"
                            style={{
                                backgroundImage: `url(${game.image || `${import.meta.env.VITE_CDN_URL}/images/Overwolf-Time-Reward-Default.webp`})`,
                            }}
                        >
                            <div className="go-vip-ow_time-reward-card_games_game_time">{game.timePlayed} min</div>
                            <h5>{game.title}</h5>
                        </div>)}
                </div>
                : <strong className="go-vip-ow_time-reward-card_title">
                    {reward.notes}
                </strong>
            }
        </div>
    );
}