import {createRef, ReactElement, useState} from "react";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import FrameOneModal from "./modalComponents/FrameOneModal";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {AssetsApi, GetUserResponse, UsersApi} from "@devour/client";
import {addError, decrementLoading, incrementLoading, updateCurrentUser} from "../../redux/meta/metaActions";
import Cropper, {ReactCropperElement} from "react-cropper";
import "cropperjs/dist/cropper.css";
import getConfig from "../../utils/getConfig";
import Toast from "../Toast";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";

interface Props {
    image: string;
    isOpen: boolean;
    onClose: () => Promise<void>;
}

function EditImageModal(props: Props): ReactElement {
    const dispatch = useDispatch();
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser) as GetUserResponse;
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const cropperRef = createRef<ReactCropperElement>();
    const [
        showToast,
        setShowToast,
    ] = useState<boolean>(false);

    const {refetch: refetchUserProfile} = useGetUserProfile(fullToken);

    async function confirmCrop(): Promise<void> {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            dispatch(incrementLoading());
            try {
                const cropData: Blob = await new Promise(resolve => {
                    cropperRef.current?.cropper.getCroppedCanvas().toBlob(resolve);
                });

                const assetForm = {
                    asset: cropData,
                    name: `avatar_${currentUser.user.id}`,
                    description: `Avatar for ${currentUser.user.id}`,
                };

                const asset = await new AssetsApi(getConfig(fullToken)).createAsset(assetForm);

                await new UsersApi(getConfig(fullToken)).updateProfilePicture({
                    updateProfilePictureBody: {
                        assetID: asset.id,
                    },
                });

                const userRes = await refetchUserProfile();

                if (userRes.data) {
                    dispatch(updateCurrentUser(userRes.data));
                }
                await props.onClose();
                setShowToast(true);

            } catch (e) {
                dispatch(await addError(e));
            } finally {
                dispatch(decrementLoading());
            }
        }

    }

    function handleToastDismissal() {
        setShowToast(false);
    }

    return (
        <>
            <Toast
                message="Your changes were saved!"
                isOpen={showToast}
                onDismiss={handleToastDismissal}
            />
            <FrameOneModal
                isOpen={props.isOpen}
                toggle={props.onClose}
                contentClassName="edit-image-modal"
                size={"lg"}
            >
                <FrameModalHeader
                    title="Position and Resize your Avatar"
                    toggle={props.onClose}
                />

                <FrameModalBody className="edit-image-modal_body">
                    <Cropper
                        ref={cropperRef}
                        className="edit-image-modal_cropper"
                        aspectRatio={1}
                        zoomTo={0.5}
                        initialAspectRatio={1}
                        src={props.image}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        guides={true}
                    />
                </FrameModalBody>
                <FrameModalFooter>
                    <FrameButton
                        color="purple"
                        size="normal"
                        onClick={confirmCrop}
                    >
						Confirm
                    </FrameButton>
                </FrameModalFooter>
            </FrameOneModal>
        </>
    );
}

export default EditImageModal;
