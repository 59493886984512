import {ReactElement, useContext} from "react";
import {BusinessPeriod, BusinessServiceAvailability, BusinessServiceAvailabilityOverride} from "@devour/client";
import FrameOneAutoPanel from "../../../components/modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelBody from "../../../components/modals/autoPanelComponents/FrameAutoPanelBody";
import moment from "moment-timezone";
import {RestaurantContext} from "../context/RestaurantContext";
import {IoMdInformationCircleOutline} from "react-icons/io";
import {RiMapPin2Line} from "react-icons/ri";
import {FiClock} from "react-icons/fi";
import classNames from "classnames";
import { RxCross2 } from "react-icons/rx";
import RestaurantPageTaxonomies from "@/pages/restaurants/components/RestaurantPageTaxonomies";
import Accordion from "@/components/Accordion";
import {findServiceAvailabilityOverride} from "@/utils/serviceAvailabilities";
import {FaInfoCircle} from "react-icons/fa";
import {useRestaurant} from "@/hooks/useRestaurant";

interface Props {
    onToggle: () => void;
    display: boolean;
}

function RestaurantDetailsModal(props: Props): ReactElement {
    const {restaurantId} = useContext(RestaurantContext);
    const {data: restaurant} = useRestaurant(restaurantId);
    const restaurantTimeZone = restaurant?.timeZone;
    const today = new Date().getDay();

    // An array of 7 moment instances for the upcoming week, starting from today.
    const momentThisWeek = [...Array(7).keys()].map((x) => {
        return moment.tz(restaurantTimeZone).add(x, "day");
    });

    function renderBusinessService(service: BusinessServiceAvailability, passedIndex: number): ReactElement {
        const isToday = today === passedIndex;
        let newServiceTimePeriods: Array<BusinessPeriod> = service.timePeriods;
        let serviceAvilabilityOverride: BusinessServiceAvailabilityOverride | false = false;

        const thisDayMoment = momentThisWeek.find((momentInstance) => momentInstance.day() === passedIndex);
        if (restaurant.serviceAvailabilitiesOverride?.length) {
            serviceAvilabilityOverride = findServiceAvailabilityOverride(restaurantTimeZone, thisDayMoment, restaurant.serviceAvailabilitiesOverride);
            if (serviceAvilabilityOverride) {
                newServiceTimePeriods = serviceAvilabilityOverride.timePeriods;
            }
        }

        return (
            <div
                className="restaurant-details-modal_hours_row"
                key={service.dayOfWeek}
            >
                <div>
                    <p>
                        <strong>
                            {service.dayOfWeek.charAt(0).toUpperCase()}{service.dayOfWeek.slice(1).toLowerCase()}
                            {isToday && " (Today)"}
                        </strong>
                    </p>
                    {serviceAvilabilityOverride &&
                    <p className="restaurant-details-modal_hours_row_override">
                        <FaInfoCircle />
                        Special hours for {thisDayMoment.format("LL")}
                    </p>
                    }
                </div>
                <div>
                    {newServiceTimePeriods?.length
                        ? newServiceTimePeriods.map((period, index) => <div key={`${service.dayOfWeek}-${index}`}>
                            <p className={classNames({
                                "restaurant-details-modal_hours_row_today": isToday,
                            })}>
                                {moment.tz(period.timeStart, "HH:mm", restaurantTimeZone).format("LT")}
                                {" - "}
                                {moment.tz(period.timeEnd, "HH:mm", restaurantTimeZone).format("LT")}
                            </p>
                        </div>)
                        : <p className={classNames({
                            "restaurant-details-modal_hours_row_today": isToday,
                        })}>
                            Closed
                        </p>}
                </div>
            </div>
        );
    }

    function renderInfoAccordionHeader(): ReactElement {
        return (
            <div
                className="restaurant-details-modal_about-row"
            >
                <IoMdInformationCircleOutline className="restaurant-details-modal_about-row_icon"/>
                <p>About</p>
            </div>
        );
    }

    if (!restaurant) {
        return null;
    }

    return (
        <FrameOneAutoPanel
            contentClassName="restaurant-details-modal"
            isOpen={props.display}
            toggle={props.onToggle}
            size="sm"
            maxHeight={true}
            modalOnTablet={true}
        >
            <div className="restaurant-page_header" >
                <button
                    className="restaurant-page_header_favorite-add"
                    title="Close modal"
                    onClick={props.onToggle}
                >
                    <RxCross2 />
                </button>
                <div className="restaurant-page_header_image restaurant-details-modal_header-border-radius">
                    <img
                        src={restaurant.headerImage?.url || `${import.meta.env.VITE_CDN_URL}/images/placeholderitem.webp`}
                        alt={restaurant.name}
                    />
                </div>
                <div className="restaurant-page_header_logo">
                    <img
                        src={restaurant.icon?.url || `${import.meta.env.VITE_CDN_URL}/images/placeholderitem.webp`}
                        alt={restaurant.name}
                    />
                </div>
            </div>

            <div className="restaurant-details-modal_header-padding-adjust">
                <h2>{restaurant.name}</h2>
            </div>

            <FrameAutoPanelBody>

                <div className="restaurant-page_details">
                    <RestaurantPageTaxonomies/>
                </div>

                <div className="restaurant-details-modal_description">
                    <Accordion
                        header={renderInfoAccordionHeader()}
                        body={<>{restaurant.description}</>}
                        showDivider={true}
                        showBorder={true}
                        showChevron={true}
                    />

                </div>

                <div className="restaurant-details-modal_address restaurant-details-modal_section-border">
                    <RiMapPin2Line className="restaurant-details-modal_about-row_icon"/>
                    <a
                        href={`https://www.google.com/maps/place/?q=place_id:${restaurant.address?.placeId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {restaurant.address?.line1}, {restaurant.address?.line2},{" "}
                        {restaurant.address?.locality}, {restaurant.address?.administrativeArea},{" "}
                        {restaurant.address?.postalCode}, {restaurant.address?.country}
                    </a>
                </div>
                <div className="restaurant-details-modal_hours restaurant-details-modal_section-border">
                    <div
                        className="restaurant-details-modal_about-row"
                    >
                        <FiClock className="restaurant-details-modal_about-row_icon"/>
                        <p>{restaurant.isOpen
                            ? "Open Now"
                            : "Closed Now"}</p>
                    </div>
                    <hr/>
                    <div>
                        {(restaurant.serviceAvailabilities.map(renderBusinessService))}
                    </div>
                </div>
            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}

export default RestaurantDetailsModal;

