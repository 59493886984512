import getConfig from "@/utils/getConfig";
import {GetOverwolfUserStatsRewardCategoryEnum, OverwolfApi, OWUserStat, Token} from "@devour/client";
import {useQuery} from "@tanstack/react-query";

interface getOverwolfStatsResponse {
    message: string;
    userStats: OWUserStat[];
    uniqueGameIds: string[];
}

async function fetchOverwolfUserStats(fullToken: Token, gameId: string, rewardCategory: GetOverwolfUserStatsRewardCategoryEnum): Promise<getOverwolfStatsResponse> {
    return await new OverwolfApi(getConfig(fullToken)).getOverwolfUserStats({
        gameId,
        rewardCategory,
    }) as any;
}

function useGetOverwolfUserStatsQuery(fullToken: Token, gameId: string, enabled, category: GetOverwolfUserStatsRewardCategoryEnum) {
    return {
        queryKey: [
            "overwolf-game-user-stats",
            fullToken?.id ?? "unauthenticated",
            gameId,
            category,
        ],
        queryFn: () => gameId ? fetchOverwolfUserStats(fullToken, gameId, category) : null,
        staleTime: 30 * 24 * 60 * 60000,
        enabled: !!gameId && enabled && !!fullToken,
    } as const;
}

export default function useGetOverwolfUserStats(fullToken: Token, gameId: string, enabled: boolean = true, category: GetOverwolfUserStatsRewardCategoryEnum = GetOverwolfUserStatsRewardCategoryEnum.EVENT) {
    return useQuery<getOverwolfStatsResponse>(useGetOverwolfUserStatsQuery(fullToken, gameId, enabled, category));
}
