import {AnchorHTMLAttributes, ReactElement, useState} from "react";
import FrameButton from "@/components/buttons/FrameButton";
import {isDesktop, isTablet} from "react-device-detect";
import GoVipOWTutorialModal from "@/components/goVip/GoVipOWTutorialModal";
import {FaExternalLinkAlt} from "react-icons/fa";
import useWindowSize from "@/hooks/useWindowSize";

export default function GoVipOWDevourPlayBanner(): ReactElement {
    const [showTutorialModal, setShowTutorialModal] = useState<boolean>(false);
    const isOnMobile = !isDesktop && !isTablet;
    const windowSize = useWindowSize()[0];

    const getBannerImageUrl = () => {
        const TABLET_BREAKPOINT = 992;
        const MOBILE_BREAKPOINT = 576;
        let screenSize: string;

        if (isOnMobile || windowSize < MOBILE_BREAKPOINT) {
            screenSize = "SM";
        } else if (isTablet || windowSize < TABLET_BREAKPOINT) {
            screenSize = "MD";
        } else {
            screenSize = "LG";
        }
        return `${import.meta.env.VITE_CDN_URL}/images/GoVIP-DevourPlay-Bnr-${screenSize}.webp`;
    };

    return (
        <>
            <GoVipOWTutorialModal
                isOpen={showTutorialModal}
                onClose={() => setShowTutorialModal(false)}
            />

            <div
                className="go-vip-ow-devourplay-banner"
                style={{
                    backgroundImage: `url(${getBannerImageUrl()})`,
                }}
            >
                <div className="go-vip-ow-devourplay-banner_title">
                    <span className="go-vip-ow-devourplay-banner_title_text">Introducing</span>
                    <img className="go-vip-ow-devourplay-banner_logo" alt="DevourPlay logo"
                        src={`${import.meta.env.VITE_CDN_URL}/images/DevourPlayLogo.png`}/>
                </div>

                <div className="go-vip-ow-devourplay-banner_btns">
                    {!isOnMobile && <FrameButton
                        <AnchorHTMLAttributes<HTMLAnchorElement>>
                        size="narrow"
                        className="go-vip-overwolf_earn-exp_buttons_download"
                        href={"https://www.overwolf.com/app/Devour-DevourPlay"}
                        forwardProps={{
                            target: "_blank",
                            rel: "noopener noreferrer",
                        }}
                    >
                        Download App
                    </FrameButton>}

                    <FrameButton
                        size="narrow"
                        className="go-vip-ow-devourplay-banner_btns_learn"
                        onClick={() => setShowTutorialModal(true)}
                    >
                        Learn More
                    </FrameButton>
                </div>

                <div className="go-vip-ow-devourplay-banner_text">
                    <h3>
                        {
                            isOnMobile && <img
                                className="mobile-ow-logo"
                                src={`${import.meta.env.VITE_CDN_URL}/images/go-vip-overwolf-logo.webp`}
                                alt="overwolf-logo"
                            />
                        }
                        <span>
                            Earn XP and rewards with DevourPlay on Overwolf
                            {
                                !isOnMobile && <img
                                    className="ow-logo"
                                    src={`${import.meta.env.VITE_CDN_URL}/images/go-vip-overwolf-logo.webp`}
                                    alt="overwolf-logo"
                                />
                            }
                        </span>
                    </h3>
                    <div className="go-vip-overwolf_earn-exp_text_description">
                        Download the DevourPlay app from the Overwolf Appstore and start earning XP while playing your
                        favorite games! {isOnMobile ? "" : "What are you waiting for?"}
                    </div>
                </div>

                <a
                    className="go-vip-ow-devourplay-banner_survey"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://share.hsforms.com/1pM-BLE3hR46pP34PIt5nqAnrwoo">
                    Share Your Thoughts
                    <FaExternalLinkAlt/>
                </a>
            </div>
        </>
    );
}
