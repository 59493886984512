import { StrictMode } from "react";
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "./redux";
import App from "./App";
import "./style/index.scss";

import {WagmiConfig} from "wagmi";
import {createWeb3Modal} from "@web3modal/wagmi/react";
import {defaultWagmiConfig} from "@web3modal/wagmi/react/config";
import {arbitrum, base, mainnet, polygon} from "viem/chains";
import {initSentry} from "./utils/sentry";
import initHotjar from "@/utils/hotjar";

const projectId = "726737766a01cd47d7e79d8a3352e859";
const chains = [
    arbitrum,
    mainnet,
    base,
    polygon,
];

const metadata = {
    name: "DevourGo",
    description: "DevourGo",
    url: "https://devourgo.io",
    icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const wagmiConfig = defaultWagmiConfig({
    chains,
    projectId,
    metadata,
});

createWeb3Modal({
    wagmiConfig,
    projectId,
    chains,
});
const metaStore = localStorage.getItem("persist:meta");
if (!metaStore || metaStore?.includes("dark")) {
    document.documentElement.setAttribute("data-theme", "dark");
}
createRoot(document.getElementById("root")).render(<StrictMode>
    <Provider store={store}>
        <PersistGate
            persistor={persistor}
            loading={null}
        >
            <WagmiConfig config={wagmiConfig}>
                <App/>
            </WagmiConfig>

        </PersistGate>
    </Provider>
</StrictMode>);

initSentry();

initHotjar();

