import {
    ButtonHTMLAttributes,
    Dispatch,
    ReactElement,
    SetStateAction,
    useContext,
    useState,
} from "react";
import {
    Address,
    AddressBook,
    BusinessesApi,
    DeliveryHandoffInstructions,
    ErrorType,
    HandoffOptions,
    MenuOrdersApi,
} from "@devour/client";
import FrameButton from "../../buttons/FrameButton";
import moment from "moment-timezone";
import {addError, toggleOrderHandoff} from "@/redux/meta/metaActions";
import getConfig from "@/utils/getConfig";
import {isAddressBook} from "@/utils/typeGuards";
import {MdOutlineStickyNote2} from "react-icons/md";
import {FaMapMarkerAlt, FaStoreAlt} from "react-icons/fa";
import CheckoutDetailsDeliveryNotesModal from "@/components/checkout/checkoutDetails/CheckoutDetailsDeliveryNotesModal";
import RestaurantAddressAutoPanel from "@/pages/restaurants/components/RestaurantAddressAutoPanel";
import {FaChevronRight, FaRegClock} from "react-icons/fa6";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import CheckoutDetailsScheduleTimeModal from "@/components/checkout/checkoutDetails/CheckoutDetailsScheduleTimeModal";
import {isMobile, isTablet} from "react-device-detect";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {createScheduleString} from "@/utils/CheckoutDetailsScheduleTime/createScheduleString";
import CannotDeliverModal from "@/components/modals/CannotDeliverModal";
import Skeleton from "@/components/skeletons/Skeleton";
import {useUpdateMenuOrderScheduleTime} from "@/hooks/menuOrder/useUpdateMenuOrderScheduleTime";
import {useUpdateMenuOrderDeliveryNotes} from "@/hooks/menuOrder/useUpdateMenuOrderDeliveryNotes";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import {BsPersonFill} from "react-icons/bs";
import {useRestaurant} from "@/hooks/useRestaurant";

interface Props {
    setShowContactInfoModal: Dispatch<SetStateAction<boolean>>;
}

function CheckoutDetailsContent(props: Props): ReactElement {
    const { menuOrderId } = useContext(RestaurantContext);

    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const [
        showMenuOrderAddressModal,
        setShowMenuOrderAddressModal,
    ] = useState<boolean>(false);
    const [
        showMenuOrderDeliveryNotesModal,
        setShowMenuOrderDeliveryNotesModal,
    ] = useState<boolean>(false);
    const [
        showMenuOrderScheduledTimeModal,
        setShowMenuOrderScheduledTimeModal,
    ] = useState<boolean>(false);
    const [
        showCannotDeliverModal,
        setShowCannotDeliverModal,
    ] = useState(false);
    const dispatch = useDispatch();
    const {data: menuOrder, refetch: refetchMenuOrder, isLoading: isMenuOrderLoading} = useMenuOrder(menuOrderId);
    const {data: restaurant} = useRestaurant(menuOrder?.business);
    const restaurantTimeZone = restaurant?.timeZone || moment.tz.guess();
    const findSelectedAddressBook = currentUser?.user?.addresses?.find((addressBook) => addressBook.placeId === menuOrder?.address?.placeId);
    const mobileLetterCount = 28;

    const addressCharacterCount = renderAddress().length;

    /**
     * Use React Query to call API and update the menu order's scheduled time
     * isLoading is used to display a skeleton while the mutation is in progress
     */
    const {
        isPending: isMenuOrderScheduleLoading,
    } = useUpdateMenuOrderScheduleTime(menuOrderId);

    /**
     * Use React Query to call API and update the menu order's delivery notes
     * isLoading is used to display a skeleton while the mutation is in progress
     */
    const {
        mutate: updateMenuOrderDeliveryNotes,
        isPending: isMenuOrderDeliveryNotesLoading,
    } = useUpdateMenuOrderDeliveryNotes(menuOrderId);


    /**
     * Render address string
     */
    function renderAddress() {
        return menuOrder?.address.line1 +
            menuOrder?.address.line2 + ", " +
            menuOrder?.address.administrativeArea + ", " +
            menuOrder?.address.locality;
    }

    /**
     * Render delivery instructions
     */
    function renderDeliveryInstructions(): string {
        switch (menuOrder.deliveryHandoffInstructions) {
            case DeliveryHandoffInstructions.HANDITTOME:
                return menuOrder.deliveryNotes
                    ? `Hand it to me | ${menuOrder.deliveryNotes}`
                    : "Hand it to me";
            case DeliveryHandoffInstructions.LEAVEITATMYDOOR:
                return menuOrder.deliveryNotes
                    ? `Leave it at my door | ${menuOrder.deliveryNotes}`
                    : "Leave it at my door";
        }
        if (menuOrder.deliveryNotes) {
            return menuOrder.deliveryNotes;
        }
        return "Add delivery instructions";
    }

    /**
     * Display the scheduled time or the estimated time for the order to be ready
     */
    function scheduledTime(): string {
        if (menuOrder.scheduledTime) {

            const scheduleString = createScheduleString(menuOrder.scheduledTime, restaurantTimeZone, isMobile && !isTablet);

            if (menuOrder.handoff === HandoffOptions.DELIVERY) {
                return `Scheduled at ${scheduleString}`;
            }

            return `Ready at  ${scheduleString}`;
        } else {
            return `${restaurant?.prepTime} - ${restaurant?.prepTime + 10} min`;
        }
    }

    /**
     * Update the menu order's address
     *
     * @param address
     */
    async function handleUpdateAddress(address: AddressBook | Address): Promise<void> {
        if (menuOrder.handoff === HandoffOptions.DELIVERY && !address.line1.trim()) {
            dispatch(await addError({
                type: ErrorType.APP,
                message: "This address is not valid as a delivery destination.",
            }));
            return;
        }

        try {
            if (menuOrder.handoff === HandoffOptions.DELIVERY) {
                const validateDistanceRes = await new BusinessesApi(getConfig()).validateDeliveryDistance({
                    id: address.placeId,
                    restaurant: menuOrder.business,
                });

                if (!validateDistanceRes.canDeliver) {
                    setShowCannotDeliverModal(true);
                    return;
                }
            }

            await new MenuOrdersApi(getConfig()).updateMenuOrder({
                id: menuOrder.id,
                createMenuOrderBody: {
                    address: address,
                    deliveryNotes: isAddressBook(address)
                        ? address?.deliveryInstructions
                        : undefined,
                    deliveryHandoffInstructions: isAddressBook(address)
                        ? address?.handoffInstructions
                        : undefined,
                },
            });
            void refetchMenuOrder();
            setShowMenuOrderAddressModal(false);
        } catch (e) {
            dispatch(await addError(e));
        }
    }

    /**
     * Close add delivery notes modal and refetch menu order data
     */
    async function deliveryNotesOnClose() {
        setShowMenuOrderDeliveryNotesModal(false);
        await refetchMenuOrder();
    }

    function onCannotDeliverChangeAddress(): void {
        setShowCannotDeliverModal(false);
        setShowMenuOrderAddressModal(true);
    }

    async function onCannotDeliverSwitchToPickUp(): Promise<void> {
        dispatch(toggleOrderHandoff(HandoffOptions.PICKUP));
        await new MenuOrdersApi(getConfig()).updateMenuOrder({
            id: menuOrder.id,
            createMenuOrderBody: {
                handoff: HandoffOptions.PICKUP,
            },
        });
        void refetchMenuOrder();
        setShowMenuOrderAddressModal(false);
        setShowCannotDeliverModal(false);
    }

    if (isMenuOrderLoading) {
        return <Skeleton
            rows={menuOrder.handoff === "DELIVERY"
                ? 3
                : 2}
            classes={"checkout-details_content_skeleton"}
        />;
    }

    if (!menuOrder) {
        return null;
    }

    return (
        <>
            <RestaurantAddressAutoPanel
                defaultAddressBook={findSelectedAddressBook}
                isOpen={showMenuOrderAddressModal}
                onClose={() => setShowMenuOrderAddressModal(false)}
                onAddressSubmit={handleUpdateAddress}
                modalOnTablet={true}
            />

            <CannotDeliverModal
                isOpen={showCannotDeliverModal}
                onNewAddress={onCannotDeliverChangeAddress}
                onSwitchToPickUp={onCannotDeliverSwitchToPickUp}
            />

            <CheckoutDetailsDeliveryNotesModal
                isOpen={showMenuOrderDeliveryNotesModal}
                onClose={() => deliveryNotesOnClose()}
                updateMenuOrderDeliveryNotes={updateMenuOrderDeliveryNotes}
            />
            <CheckoutDetailsScheduleTimeModal
                isOpen={showMenuOrderScheduledTimeModal}
                onClose={() => setShowMenuOrderScheduledTimeModal(false)}
            />

            <div className="checkout-details_content">

                {menuOrder.handoff === HandoffOptions.DELIVERY &&
                <div className="checkout-details_content_row">
                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        forwardProps={{type: "button"}}
                        color="ghost"
                        size="normal"
                        className="checkout-details_content_row_button"
                        leftIcon={FaMapMarkerAlt}
                        rightIcon={FaChevronRight}
                        onClick={() => setShowMenuOrderAddressModal(true)}
                    >
                        <strong>Deliver to</strong>
                        {isMobile && !isTablet && addressCharacterCount > mobileLetterCount
                            ? `${renderAddress().slice(0, mobileLetterCount)}...`
                            : `${renderAddress()}`
                        }
                    </FrameButton>
                </div>
                }

                {menuOrder.handoff === HandoffOptions.PICKUP &&
                <div className="checkout-details_content_row">
                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        forwardProps={{type: "button"}}
                        color="ghost"
                        size="normal"
                        className="checkout-details_content_row_button"
                        leftIcon={FaStoreAlt}
                        onClick={() => window.open(`https://www.google.com/maps/place/?q=place_id:${restaurant?.address?.placeId}`, "_blank")}
                    >
                        <strong>Pickup from</strong>
                        {restaurant?.address.line1}{restaurant?.address.line2},{" "}
                        {restaurant?.address.locality}{" "}
                        {restaurant?.address.administrativeArea}
                    </FrameButton>
                </div>
                }

                {isMenuOrderDeliveryNotesLoading
                    ? <Skeleton
                        rows={1}
                        classes={"checkout-details_content_row"}
                    />
                    : menuOrder.handoff === HandoffOptions.DELIVERY &&
                    <div className="checkout-details_content_row">
                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            forwardProps={{type: "button"}}
                            color="ghost"
                            size="normal"
                            className="checkout-details_content_row_button"
                            leftIcon={MdOutlineStickyNote2}
                            rightIcon={FaChevronRight}
                            onClick={() => setShowMenuOrderDeliveryNotesModal(true)}
                        >
                            <strong>Instruction</strong>
                            {renderDeliveryInstructions()}
                        </FrameButton>
                    </div>
                }

                {isMenuOrderScheduleLoading
                    ? <Skeleton
                        rows={1}
                        classes={"checkout-details_content_row"}
                    />
                    : <div className="checkout-details_content_row">
                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            forwardProps={{type: "button"}}
                            color="ghost"
                            size="normal"
                            className="checkout-details_content_row_button"
                            leftIcon={FaRegClock}
                            rightIcon={FaChevronRight}
                            onClick={() => setShowMenuOrderScheduledTimeModal(true)}
                        >
                            <strong>Standard {menuOrder.handoff === HandoffOptions.DELIVERY
                                ? "delivery time"
                                : "pick-up time"}</strong>
                            {scheduledTime()}
                        </FrameButton>
                    </div>
                }

                {isMenuOrderScheduleLoading
                    ? <Skeleton
                        rows={1}
                        classes={"checkout-details_content_row"}
                    />
                    : <div className="checkout-details_content_row">
                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            forwardProps={{type: "button"}}
                            color="ghost"
                            size="normal"
                            className="checkout-details_content_row_button"
                            leftIcon={BsPersonFill}
                            rightIcon={FaChevronRight}
                            onClick={() => props.setShowContactInfoModal(true)}
                        >
                            <strong>Contact Information</strong>
                            {menuOrder.firstName} {menuOrder.lastName} | {menuOrder.phoneNumber?.nationalNumber}
                        </FrameButton>
                    </div>
                }

            </div>
        </>
    );
}

export default CheckoutDetailsContent;
