import { completedTrackingStatuses, ongoingTrackingStatuses, OrderTrackerData, OrderTrackerDataFullfillmentStatus } from "@/types/OrderTrackerData";
import { GeoJsonType, GeoPoint, MenuOrder, Restaurant } from "@devour/client";
import moment from "moment";

export const getFakeTrackerData = (menuOrder: MenuOrder, status: OrderTrackerDataFullfillmentStatus, restaurant: Restaurant): OrderTrackerData => {

    const isCompleted = completedTrackingStatuses.includes(status);
    const isOngoing = ongoingTrackingStatuses.includes(status);
    const merchantLocation = restaurant?.address.location;
    const { encodedPolyline, pointAtRandom } = getPolylineAndRandomPoint({
        lat: merchantLocation.coordinates[1],
        lng: merchantLocation.coordinates[0],
    }, {
        lat: menuOrder.address.location.coordinates[1],
        lng: menuOrder.address.location.coordinates[0],
    });
    return {
        delivery_service: {
            name: "Devour",
            icon: "https://dsp-assets-first.s3.amazonaws.com/logo-74",
            colors: {
                primary: "7859e6",
                secondary: null,
            },
            footer_image: null,
            footer_link: null,
        },
        merchant: {
            name: "Boba Time - 3rd Street",
            address: "8250 W 3rd St #106, Los Angeles, CA 90048, USA",
            location: merchantLocation,
            phone: "+13234243000",
            territory: "Pacific (PST)",
        },
        customer: {
            name: menuOrder.customer,
            address: menuOrder.address.line1,
            phone: menuOrder.phoneNumber.normalized,
            delivery_instructions: menuOrder.deliveryNotes,
            location: {
                type: "Point",
                coordinates: menuOrder.address.location.coordinates,
            },
        },
        runner: isCompleted || isOngoing ? {
            name: "Test runner",
            phone: "+14047241937",
            location: {
                type: "Point",
                coordinates: isOngoing ? [pointAtRandom.lng, pointAtRandom.lat] : [0, 0],
            },
        } : null,
        order: {
            id: "4da2c1a0-65b6-11ef-adc4-d9b108689f20",
            order_number: "66cfe80655ff000f3a39dded",
            details: [
                {
                    itemName: "Dragon Fruit Bowl",
                    quantity: 1,
                    itemPrice: 13,
                    modifiersText: [
                        {
                            name: "Honey Drizzle",
                            price: 0,
                        },
                        {
                            name: "With Boba",
                            price: 0,
                        },
                    ],
                },
                {
                    itemName: "Rose Milk Tea",
                    quantity: 2,
                    itemPrice: 5.7,
                    modifiersText: [
                        {
                            name: "Large",
                            price: 0.75,
                        },
                        {
                            name: "With Boba",
                            price: 0,
                        },
                        {
                            name: "50% Sweet",
                            price: 0,
                        },
                    ],
                },
            ],
            special_instructions: "UberEats Pick-Up Order for DevG | Order ID: 0eb05",
            payment: {
                subtotal: 20.9,
                tip: 2,
            },
            dropoff_photo: "https://tb-static.uber.com/prod/file-upload/uploads/direct-image-capture/e8ce919a-7d9a-44af-a1e4-eeb885c91235",
        },
        fulfillment: {
            status,
            method: "Delivery",
            accepted_at: null,
            prep_time: 8,
            created_at: new Date("2024-06-18T18:12:02.112Z"),
            runner_assigned_at: isCompleted ? new Date() : null,
            runner_est_pickup_at: new Date("2024-06-18T18:44:07.000Z"),
            runner_arrived_pickup: isCompleted ? new Date() : null,
            runner_estimated_dropoff: moment(menuOrder.readyBy)
                .add(5, "minutes")
                .toDate(),
            delivery_route: encodedPolyline,
            runner_picked_up: isCompleted ? new Date() : null,
            runner_arrived_dropoff: isCompleted ? new Date() : null,
            runner_dropped_off: isCompleted ? new Date() : null,
        },
        operator: {
            customer_support_tracking: false,
        },
    };

};
function encodePolyline(points: { lat: number, lng: number }[]): string {
    let prevLat = 0; let
        prevLng = 0;
    let result = "";

    points.forEach(point => {
        const lat = Math.round(point.lat * 1e5);
        const lng = Math.round(point.lng * 1e5);

        const dLat = lat - prevLat;
        const dLng = lng - prevLng;

        prevLat = lat;
        prevLng = lng;

        result += encodeSignedNumber(dLat) + encodeSignedNumber(dLng);
    });

    return result;
}

function encodeSignedNumber(num: number): string {
    let sgnNum = num << 1;
    if (num < 0) {
        sgnNum = ~sgnNum;
    }
    return encodeNumber(sgnNum);
}

function encodeNumber(num: number): string {
    let result = "";
    while (num >= 0x20) {
        result += String.fromCharCode((0x20 | num & 0x1f) + 63);
        num >>= 5;
    }
    result += String.fromCharCode(num + 63);
    return result;
}

// Function to create a polyline with small curvature between two points
function generatePolyline(
    start: { lat: number, lng: number },
    end: { lat: number, lng: number },
    steps: number,
): { lat: number, lng: number }[] {
    const points = [];

    // Calculate lat and lng differences
    const deltaLat = (end.lat - start.lat) / steps;
    const deltaLng = (end.lng - start.lng) / steps;

    for (let i = 0; i <= steps; i++) {
        const lat = start.lat + deltaLat * i;
        const lng = start.lng + deltaLng * i;
        points.push({ lat,
            lng });
    }

    return points;
}

// Function to get a random point along the polyline
function getRandomPoint(points: { lat: number, lng: number }[]): { lat: number, lng: number } {
    const randomIndex = Math.floor(Math.random() * points.length);
    return points[randomIndex];
}

// Main function to generate the polyline and find a random point
function getPolylineAndRandomPoint(
    start: { lat: number, lng: number },
    end: { lat: number, lng: number },
) {
    const steps = 20; // Number of points along the path
    const polylinePoints = generatePolyline(start, end, steps);
    const encodedPolyline = encodePolyline(polylinePoints);
    const pointAtRandom = getRandomPoint(polylinePoints);

    return { encodedPolyline,
        pointAtRandom };
}


function generateGeoPoint(location: GeoPoint, distanceInMiles = 10, bearingInDegrees = 0): GeoPoint {
    const toRadians = degrees => degrees * (Math.PI / 180);
    const toDegrees = radians => radians * (180 / Math.PI);
    const earthRadiusInMiles = 3958.8; // Earth's radius in miles

    const [lon, lat] = location.coordinates;

    const bearing = toRadians(bearingInDegrees);
    const distance = distanceInMiles / earthRadiusInMiles;

    const lat1 = toRadians(lat);
    const lon1 = toRadians(lon);

    const lat2 = Math.asin(Math.sin(lat1) * Math.cos(distance) +
      Math.cos(lat1) * Math.sin(distance) * Math.cos(bearing));

    const lon2 =
      lon1 +
      Math.atan2(
          Math.sin(bearing) * Math.sin(distance) * Math.cos(lat1),
          Math.cos(distance) - Math.sin(lat1) * Math.sin(lat2),
      );

    return {
        type: GeoJsonType.Point,
        coordinates: [toDegrees(lon2), toDegrees(lat2)],
    };
}
