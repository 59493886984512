import { MenuOrder } from "@devour/client";
import moment from "moment";
import { FC } from "react";
import FrameModalBody from "./modals/modalComponents/FrameModalBody";
import FrameModalHeader from "./modals/modalComponents/FrameModalHeader";
import FrameOneModal from "./modals/modalComponents/FrameOneModal";

interface props {
    isOpen: boolean;
    onClose: () => void;
    order: MenuOrder;
}

const OrderTrackDialog: FC<props> = ({isOpen, onClose, order}) => {
    return (
        <FrameOneModal
            isOpen={isOpen}
            toggle={onClose}
            size="lg"
            containerClassName="order-track-dialog"

        >
            <FrameModalHeader
                title={"Track My Order"}
                toggle={onClose}
            />

            <FrameModalBody>
                {order.deliveryId
                    ? <div className="order-history-details-old_status-container">
                        <iframe
                            title="First Delivery Order Tracking"

                            /*
                             *  src={`https://tracker.firstdelivery.com/${order.deliveryId}`}
                             */
                            src={`https://ordertracker.delivery/?orderId=${order.deliveryId}`}
                            // src="https://ordertracker.delivery/8d56c350-3f28-11ef-a583-41c93817f2d8"
                        />
                    </div>
						 : order.orderUuid
                        ? <div className="order-history-details-old_status-container-empty">
                            <p className="order-history-details-old_status-container-empty_msg">
									Your order is being processed.
									Please refresh after a minute to check your delivery status.
                            </p>
                        </div>
						 : <div className="order-history-details-old_status-container-empty">
                            <p className="order-history-details-old_status-container-empty_msg">
									Your order tracking will be available soon.
                            </p>
                        </div>
                }

            </FrameModalBody>
        </FrameOneModal>
    );

};

export default OrderTrackDialog;
