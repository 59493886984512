import { ApiError, DiscordApi, DiscordInfoResponse, Token } from "@devour/client";
import getConfig from "@/utils/getConfig";
import { useMutation, useQuery } from "@tanstack/react-query";
import { addError } from "@/redux/meta/metaActions";
import { useDispatch } from "react-redux";

/**
 * Handles Discord authentication by fetching the OAuth2 URL and redirecting the user.
 * @param fullToken - The authentication token.
 */
export async function handleDiscordAuthenticate(fullToken: Token): Promise<void> {
    // Fetch the Discord OAuth2 URL using the generated client
    const response = await new DiscordApi(getConfig(fullToken)).discordAuth();

    if (response && response.url) {
        window.location.href = response.url;
    }

}

async function fetchDiscordAccount(): Promise<DiscordInfoResponse> {
    return await new DiscordApi(getConfig()).getDiscordAccount();
}

function fetchDiscordAccountQuery() {
    return {
        queryKey: [
            "get-discord-account",
        ],
        queryFn: fetchDiscordAccount,
        keepPreviousData: true,
        staleTime: 30 * 60000,
        refetchOnWindowFocus: false,
    } as const;
}

export function useGetDiscordAccount(): { data: DiscordInfoResponse, refetch: () => void, isLoading: boolean } {
    return useQuery(fetchDiscordAccountQuery());
}

export function useDisconnectDiscordAccount(fullToken: Token) {
    const dispatch = useDispatch();

    return useMutation({
        mutationFn: async () => {
            await new DiscordApi(getConfig(fullToken)).disconnectDiscordAccount();
            window.location.reload();
        },
        onError: async (error) => dispatch(await addError(error as ApiError)),
    });
}