import {ReactElement, useEffect, useState} from "react";
import FrameOneAutoPanel from "./autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "./autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "./autoPanelComponents/FrameAutoPanelBody";
import {UserRankData, UsersApi} from "@devour/client";
import {addError} from "@/redux/meta/metaActions";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import Spacer from "../Spacer";
import {formatNumberWithCommas} from "@/utils/formatNumberWithCommas";
import GoVipLeaderboardRow from "../goVip/GoVipLeaderboardRow";

interface Props {
    leaderboardRank: number;
    shouldShowUserRank: boolean;
    isOpen: boolean;
    onClose: () => void;
}

function GoVipLeaderboardsModal(props: Props): ReactElement {

    const [
        rankedUsers,
        setRankedUsers,
    ] = useState<Array<UserRankData>>(undefined);

    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.isOpen) {
            void getLeaderboardRankings();
        }

    }, [props.isOpen]);

    async function getLeaderboardRankings() {
        try {
            const rankedUsersRes = await new UsersApi().getTopUsersByLeaderboardRank();
            setRankedUsers(rankedUsersRes.users);
        } catch (e) {
            dispatch(await addError(e));
        }
    }

    function renderLeaderboardRows(users: Array<UserRankData>) {
        return users.map((user, index) => <GoVipLeaderboardRow
            user={user}
            isLast={index === users.length - 1}
            key={`govip-leaderboard-row-${user.nickname}-${user.rank}-${user.level}-${user.experiencePoints}`}
        />);
    }

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            toggle={props.onClose}
            contentClassName="go-vip-leaderboards"
            size="sm"
            maxHeight={true}
            modalOnTablet={true}
        >
            <FrameAutoPanelHeader
                title="Leaderboards"
                toggle={props.onClose}
            />

            <div className="go-vip-leaderboards_top-section">
                <hr/>
                <div className="go-vip-leaderboards_top-section_user-row">
                    <img
                        className="go-vip-leaderboards_top-section_user-row_pfp"
                        src={currentUser.user.gravatar}
                        alt="User Profile"
                    />
                    <div>
                        <p className="go-vip-leaderboards_top-section_user-row_tag">{props.shouldShowUserRank
                            ? "Your Rank"
                            : "Your Rank: Unlock at Level 2"}</p>
                        <p className="go-vip-leaderboards_top-section_user-row_rank">{props.shouldShowUserRank
                            ? props.leaderboardRank
                            : "???"}</p>
                    </div>
                    <Spacer/>
                    <div>
                        <p>
                            {`${formatNumberWithCommas(currentUser.user.experiencePoints)} XP`}
                        </p>
                        <p className="go-vip-leaderboards_top-section_user-row_tag
                            go-vip-leaderboards_top-section_user-row_levels"
                        >
                            {`Level ${currentUser.user.level}`}
                        </p>
                    </div>
                </div>
            </div>

            <FrameAutoPanelBody className="go-vip-leaderboards_top-section_body">

                <div className="go-vip-leaderboards_top-section_body_table-header-row">
                    <p className="go-vip-leaderboards_top-section_user-row_rank">Top Players</p>
                    <div className="go-vip-leaderboards_top-section_body_table-header-row_header">
                        <p className="go-vip-leaderboards_top-section_body_table-header-row_header_rank">Rank</p>
                        <p>Name</p>
                        <Spacer/>
                        <p>XP & Level</p>
                    </div>
                </div>
                <hr/>

                {rankedUsers
                    ? renderLeaderboardRows(rankedUsers)
                    : <div className="spinner"/>}

            </FrameAutoPanelBody>

        </FrameOneAutoPanel>
    );
}

export default GoVipLeaderboardsModal;
