import {ReactElement, useEffect, useState} from "react";
import {
    SiteConfigurationKey,
    PaginationInfo, RestaurantSearchResult,
} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {updateLastSearchedPlaceId} from "@/redux/meta/metaActions";
import {useParams} from "react-router";
import RestaurantSearchFilters from "./components/RestaurantSearchFilters";
import RestaurantSearchTopBar from "./components/RestaurantSearchTopBar";
import RestaurantSearchResults from "./components/RestaurantSearchResults";
import {
    defaultRestaurantSearchFilters,
    RestaurantSearchFiltersQuery,
} from "@/utils/restaurantsSearchFilter";
import SiteHeroBanner from "../../components/SiteHeroBanner";
import RestaurantSearchGoFrensPromotions from "./components/RestaurantSearchGoFrensPromotions";
import RestaurantSearchNoResults from "./components/RestaurantSearchNoResults";
import IndustryCollectionBanner from "./components/IndustryCollectionBanner";
import {defaultFrontendPagination, FrontendPagination} from "@/components/paginator/FramePaginator";
import {omit} from "lodash";
import RestaurantSearchDataSkeleton from "../../components/skeletons/RestaurantSearchDataSkeleton";
import RestaurantSearchFiltersSkeleton
    from "../../components/skeletons/RestaurantSearchFiltersSkeleton";
import {useSearchRestaurants} from "@/hooks/useSearchRestaurants";
import {useGetNftOwnershipsForUser} from "@/hooks/useGetNftOwnershipsForUser";
import RestaurantSearchTextNoResults from "@/pages/restaurants/components/RestaurantSearchTextNoResults";
import {useGetGlobalOrderDiscountsForUser} from "@/hooks/useGetGlobalOrderDiscountsForUser";
import {useGetOrderAgainRestaurants} from "@/hooks/useGetOrderAgainRestaurants";
import ViewRestaurantsPanel from "@/pages/restaurants/components/ViewRestaurantsPanel";
import RestaurantSearchOrderAgain from "@/pages/restaurants/components/RestaurantSearchOrderAgain";

export enum ViewStates {
    VIEW_ALL,
    PROMOTIONS,
    ORDER_AGAIN,
}

function restaurantsGoFrensFilter(restaurant: RestaurantSearchResult, selectedNftGroupings: Array<string>): boolean {

    const checkDiscount = (nftGroupingId: string) => restaurant.promos?.find((discount) => discount.isDisplayedGoFrens && discount.nftGroupings?.includes(nftGroupingId));

    if (selectedNftGroupings.some(checkDiscount)) {
        return true;
    }

    const checkExclusive = restaurant.secretMenuItems?.filter((menuItem) => {
        return selectedNftGroupings.find((nftGroupingId) => menuItem.nftGroupings?.includes(nftGroupingId));
    });

    return checkExclusive.length > 0;

}

function RestaurantSearchResultsPage(): ReactElement {
    const dispatch = useDispatch();
    const {placeId} = useParams<{ placeId: string; }>();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const lastSearchedPlaceId = useSelector((store: IStore) => store.metaStore.lastSearchedPlaceId);
    const handoff = useSelector((store: IStore) => store.metaStore.handoff);

    const [
        frontendPagination,
        setFrontendPagination,
    ] = useState<FrontendPagination>(defaultFrontendPagination);
    const [
        restaurantFilters,
        setRestaurantFilters,
    ] = useState<RestaurantSearchFiltersQuery>(defaultRestaurantSearchFilters);
    const [
        viewState,
        setViewState,
    ] = useState<ViewStates>(ViewStates.VIEW_ALL);

    const {data: filteredRestaurantSearchResults} = useSearchRestaurants(placeId, handoff, frontendPagination.offset, frontendPagination.limit, restaurantFilters.textSearch, restaurantFilters.taxonomyIds, fullToken);
    const {data: unfilteredRestaurantSearchResults} = useSearchRestaurants(placeId, handoff);
    const {data: nftOwnershipResponse} = useGetNftOwnershipsForUser(fullToken);
    const {data: globalOrderDiscounts} = useGetGlobalOrderDiscountsForUser(fullToken);
    const resultsWithoutFilters: number = unfilteredRestaurantSearchResults?.paginationInfo?.total;
    const {data: orderAgainRestaurantsData} = useGetOrderAgainRestaurants(placeId, handoff, fullToken);

    const newGroupings: Array<string> = [...new Set(nftOwnershipResponse?.nftOwnerships?.[0]?.map((ownership) => ownership.nftGroupingId))];
    const goFrensRestaurants: Array<RestaurantSearchResult> = filteredRestaurantSearchResults?.restaurantsGoFrens?.filter((restaurant) => restaurantsGoFrensFilter(restaurant, newGroupings));

    if (resultsWithoutFilters === 0 && Boolean(lastSearchedPlaceId)) {
        // If no results, should not auto redirect back to this address. GitHub #287
        dispatch(updateLastSearchedPlaceId(undefined));
    }

    useEffect(() => {
        setFrontendPagination(defaultFrontendPagination);
        setRestaurantFilters(defaultRestaurantSearchFilters);
    }, [
        placeId,
        handoff,
    ]);

    useEffect(() => {
        // Reset pager offset on filter change
        setFrontendPagination({
            ...frontendPagination,
            offset: 0,
        });
    }, [restaurantFilters]);

    function renderSearchResults(): JSX.Element {
        if (!filteredRestaurantSearchResults) {
            return null;
        } else if (filteredRestaurantSearchResults?.restaurantsPaginated.length) {
            return (
                <RestaurantSearchResults
                    restaurants={filteredRestaurantSearchResults?.restaurantsPaginated}
                    restaurantFilters={restaurantFilters}
                    updateRestaurantFilters={setRestaurantFilters}
                    nftOwnershipResponse={nftOwnershipResponse}
                    showTitle={true}
                    pagination={{
                        ...filteredRestaurantSearchResults?.paginationInfo,
                        ...omit(frontendPagination, "frontendRenderKey"),
                    } as PaginationInfo}
                    onPaginationChange={setFrontendPagination}
                />
            );
        } else if (filteredRestaurantSearchResults?.restaurantsPaginated.length === 0 && restaurantFilters.textSearch) {
            return (
                <RestaurantSearchTextNoResults
                    restaurantFilters={restaurantFilters}
                    allRestaurantSearchResults={unfilteredRestaurantSearchResults}
                    nftOwnershipResponse={nftOwnershipResponse}
                />
            );
        } else {
            return (
                <RestaurantSearchNoResults/>
            );
        }
    }

    function renderRestaurantsContent() {
        if (viewState === ViewStates.ORDER_AGAIN && orderAgainRestaurantsData) {
            return (
                <ViewRestaurantsPanel
                    title="All Order Again Restaurants"
                    restaurants={orderAgainRestaurantsData.restaurants}
                    setViewState={setViewState}
                    restaurantFilters={restaurantFilters}
                    nftOwnershipResponse={nftOwnershipResponse}
                />
            );
        } else if (viewState === ViewStates.PROMOTIONS && (globalOrderDiscounts || goFrensRestaurants)) {
            return (
                <ViewRestaurantsPanel
                    title="All Available Promotions"
                    restaurants={goFrensRestaurants}
                    setViewState={setViewState}
                    restaurantFilters={restaurantFilters}
                    nftOwnershipResponse={nftOwnershipResponse}
                    globalOrderDiscounts={globalOrderDiscounts}
                />
            );
        } else { // show all
            const showOrderAgainRestaurants = orderAgainRestaurantsData?.restaurants?.length > 0;
            const isFilterOn = restaurantFilters.taxonomyIds.length > 0 || restaurantFilters.textSearch.length > 0;

            return (
                <>
                    {goFrensRestaurants && !isFilterOn &&
                    <RestaurantSearchGoFrensPromotions
						    globalOrderDiscounts={globalOrderDiscounts}
						    goFrensRestaurants={goFrensRestaurants}
						    setViewState={setViewState}
                    />
                    }


                    {/* Favorite and Order Again restaurants */}
                    {showOrderAgainRestaurants && !isFilterOn &&
                    <div className="restaurants-search-results-page_favorites-order-again">
                        <RestaurantSearchOrderAgain
                            restaurants={orderAgainRestaurantsData.restaurants}
                            setViewState={setViewState}
                            restaurantFilters={restaurantFilters}
                            nftOwnershipResponse={nftOwnershipResponse}
                        />
                    </div>

                    }

                    {!filteredRestaurantSearchResults
                        ? <RestaurantSearchDataSkeleton/>
                        : <div className="restaurants-search-results-page_content">
                            {renderSearchResults()}
                        </div>
                    }
                </>
            );
        }
    }

    return (
        <div className="restaurants-search-results-page">
            {<RestaurantSearchTopBar
                resultsWithoutFiltersCounter={resultsWithoutFilters}
                restaurantFilters={restaurantFilters}
                updateRestaurantFilters={setRestaurantFilters}
            />}

            <div className="restaurants-search-results-page_banner">
                <SiteHeroBanner
                    configKey={SiteConfigurationKey.RESTAURANTSEARCHHERO}
                />
            </div>

            <div className="restaurants-search-results-page_banner">
                <SiteHeroBanner
                    configKey={SiteConfigurationKey.RESTAURANTSEARCHHERO}
                />
            </div>

            {resultsWithoutFilters === undefined
                ? <RestaurantSearchFiltersSkeleton />
			 : resultsWithoutFilters > 0 && viewState === ViewStates.VIEW_ALL &&
			 <RestaurantSearchFilters
				    restaurantFilters={restaurantFilters}
				    updateRestaurantFilters={setRestaurantFilters}
			 />
            }

            <IndustryCollectionBanner nftOwnershipResponse={nftOwnershipResponse}/>
            {renderRestaurantsContent()}
        </div>
    );
}

export default RestaurantSearchResultsPage;
