/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * An object containing URLs to small and large versions of the image.
 * @export
 * @interface OWGameImage
 */
export interface OWGameImage {
    /**
     * URL to the small version of the image.
     * @type {string}
     * @memberof OWGameImage
     */
    small?: string;
    /**
     * URL to the large version of the image.
     * @type {string}
     * @memberof OWGameImage
     */
    large?: string;
}

export function OWGameImageFromJSON(json: any): OWGameImage {
    return OWGameImageFromJSONTyped(json, false);
}

export function OWGameImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): OWGameImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'small': !exists(json, 'small') ? undefined : json['small'],
        'large': !exists(json, 'large') ? undefined : json['large'],
    };
}

export function OWGameImageToJSON(value?: OWGameImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'small': value.small,
        'large': value.large,
    };
}


