import {ReactElement, useContext} from "react";
import {HandoffOptions} from "@devour/client";
import FrameOneAutoPanel from "./autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "./autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "./autoPanelComponents/FrameAutoPanelBody";
import FrameButton from "../buttons/FrameButton";
import FrameAutoPanelFooter from "./autoPanelComponents/FrameAutoPanelFooter";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import {useRestaurant} from "@/hooks/useRestaurant";

interface Props {
    isOpen: boolean;
    onNewAddress: () => void;
    onSwitchToPickUp: () => void;
}

function CannotDeliverModal(props: Props): ReactElement {
    const { menuOrderId, restaurantId } = useContext(RestaurantContext);

    const menuOrders = useSelector((store: IStore) => store.metaStore.menuOrders);
    const menuOrderIdOnRestaurantPage = menuOrders?.[restaurantId];
    const {data: menuOrder} = useMenuOrder(menuOrderIdOnRestaurantPage || menuOrderId);
    const {data: restaurant} = useRestaurant(menuOrder?.business);
    const hasPickup = restaurant?.handoffOptions?.includes(HandoffOptions.PICKUP);

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            size="sm"
            modalOnTablet={true}
        >
            <FrameAutoPanelHeader
                title="Too far to deliver"
            />

            <FrameAutoPanelBody className="address-form-modal_body">
                {hasPickup
                    ? <p>
						The address you entered is too far for this restaurant to deliver. Please enter a new address or
						switch to pick-up.
                    </p>
				 : <p>
						The address you entered is too far for this restaurant to deliver. Please enter a new address.
                    </p>
                }
            </FrameAutoPanelBody>

            <FrameAutoPanelFooter>
                <FrameButton
                    color="purple"
                    size="normal"
                    onClick={props.onNewAddress}
                >
					Change Address
                </FrameButton>

                {hasPickup &&
                <FrameButton
					    color="purple-outline"
					    size="normal"
					    onClick={props.onSwitchToPickUp}
                >
						Switch to Pick-Up
                </FrameButton>
                }
            </FrameAutoPanelFooter>
        </FrameOneAutoPanel>
    );
}

export default CannotDeliverModal;
