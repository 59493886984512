/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OWUserStat,
    OWUserStatFromJSON,
    OWUserStatFromJSONTyped,
    OWUserStatToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetUserStatsResponse
 */
export interface GetUserStatsResponse {
    /**
     * 
     * @type {string}
     * @memberof GetUserStatsResponse
     */
    message?: string;
    /**
     * 
     * @type {Array<OWUserStat>}
     * @memberof GetUserStatsResponse
     */
    userStats?: Array<OWUserStat>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetUserStatsResponse
     */
    uniqueGameIds?: Array<string>;
}

export function GetUserStatsResponseFromJSON(json: any): GetUserStatsResponse {
    return GetUserStatsResponseFromJSONTyped(json, false);
}

export function GetUserStatsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserStatsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'userStats': !exists(json, 'userStats') ? undefined : ((json['userStats'] as Array<any>).map(OWUserStatFromJSON)),
        'uniqueGameIds': !exists(json, 'uniqueGameIds') ? undefined : json['uniqueGameIds'],
    };
}

export function GetUserStatsResponseToJSON(value?: GetUserStatsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'userStats': value.userStats === undefined ? undefined : ((value.userStats as Array<any>).map(OWUserStatToJSON)),
        'uniqueGameIds': value.uniqueGameIds,
    };
}


