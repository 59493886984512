/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OWLogCategory {
    TIME = 'TIME',
    EVENT = 'EVENT'
}

export function OWLogCategoryFromJSON(json: any): OWLogCategory {
    return OWLogCategoryFromJSONTyped(json, false);
}

export function OWLogCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): OWLogCategory {
    return json as OWLogCategory;
}

export function OWLogCategoryToJSON(value?: OWLogCategory | null): any {
    return value as any;
}

