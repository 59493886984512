import FrameButton from "@/components/buttons/FrameButton";
import {IoClose} from "react-icons/io5";
import {AnchorHTMLAttributes} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {toggleOverwolfDiscordBanner} from "@/redux/meta/metaActions";


export default function OverwolfDiscordBanner() {
    const dispatch = useDispatch();
    const isOverwolfDiscordBannerDismissed = useSelector((store: IStore) => store.metaStore.isOverwolfDiscordBannerDismissed);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser?.user);

    if (!currentUser || currentUser.discord || isOverwolfDiscordBannerDismissed) {
        return null;
    }

    function dismissBanner() {
        dispatch(toggleOverwolfDiscordBanner(true));
    }

    return (
        <div className="overwolf-govip_discord">
            <img
                src={`${import.meta.env.VITE_CDN_URL}/images/DevourPlayLogoWhite.webp`}
                alt="Devour logo"
                className="overwolf-govip_discord_logo"
            />

            <h5>Join Our Discord For More Rewards! 🔥🔥🔥</h5>

            <div className="overwolf-govip_discord_buttons">
                <FrameButton
                    <AnchorHTMLAttributes<HTMLAnchorElement>>
                    size="narrow"
                    color="white-drop-shadow-devour"
                    className="overwolf-govip_discord_buttons_join"
                    href="https://discord.gg/devourplay"
                    forwardProps={{
                        target: "_blank",
                        rel: "noopener noreferrer",
                    }}
                >
                    Join Now
                </FrameButton>

                <button
                    onClick={dismissBanner}
                    className="overwolf-govip_discord_buttons_close reset-button"
                >
                    <IoClose size="1.4rem"/>
                </button>
            </div>
        </div>

    );
}