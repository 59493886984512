import {ReactElement, useContext} from "react";
import {HandoffOptions, MenuOrder, Restaurant} from "@devour/client";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {RestaurantContext} from "../context/RestaurantContext";
import {getDistanceFromLatLonInKm} from "@/utils/getDistanceFromLatLonInKm";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {useRestaurant} from "@/hooks/useRestaurant";

export const kmInMile = 1.609;

export const MINS_PER_MILE = 3;


/**
 * Pickup distance between customer and restaurant, in km.
 */
export function getDeliveryDistance(restaurant: Restaurant, menuOrder: MenuOrder): number {
    if (restaurant?.distance) {
        return restaurant.distance;
    }
    if (restaurant && menuOrder) {
        const originLng = restaurant.address.location.coordinates[0];
        const originLat = restaurant.address.location.coordinates[1];
        const destinationLng = menuOrder.address.location.coordinates[0];
        const destinationLat = menuOrder.address.location.coordinates[1];
        return getDistanceFromLatLonInKm(originLat, originLng, destinationLat, destinationLng);
    }
    return 0;
}


function RestaurantHandoffOptionsDetails(): ReactElement {

    const {restaurantId, menuOrderId} = useContext(RestaurantContext);
    const {data: restaurant} = useRestaurant(restaurantId);
    const {data: menuOrder} = useMenuOrder(menuOrderId);
    const handoff = useSelector((store: IStore) => store.metaStore.handoff);


    if (!restaurant) {
        return null;
    }
    return (
        <div className="restaurant-handoff-options_info">
            <div className="restaurant-handoff-options_info_left">
                {handoff === HandoffOptions.DELIVERY
                    ? <div>
                        <div className="restaurant-handoff-options_info_value">
                            {menuOrder?.deliveryCharge !== undefined
                                ? `$${menuOrder.deliveryCharge?.toFixed(2)}`
                                : restaurant?.deliveryCharge !== undefined
                                    ? `$${restaurant.deliveryCharge?.toFixed(2)}`
                                    : "-"}
                        </div>
                        <div className="restaurant-handoff-options_info_label">
							Delivery fee
                        </div>
                    </div>
				 : <div>
                        <div className="restaurant-handoff-options_info_value">
                            {(getDeliveryDistance(restaurant, menuOrder) / kmInMile).toFixed(1)} miles
                        </div>
                        <div className="restaurant-handoff-options_info_label">
							From you
                        </div>
                    </div>
                }
            </div>

            <div className="restaurant-handoff-options_info_divider"/>

            <div className="restaurant-handoff-options_info_right">
                {handoff === HandoffOptions.DELIVERY
                    ? <div>
                        <div className="restaurant-handoff-options_info_value">
                            {restaurant.prepTime + Math.ceil(getDeliveryDistance(restaurant, menuOrder) / kmInMile) * MINS_PER_MILE} mins
                        </div>
                        <div className="restaurant-handoff-options_info_label">
							Avg. time
                        </div>
                    </div>
				 : <div>
                        <div className="restaurant-handoff-options_info_value">
                            {restaurant.prepTime} mins
                        </div>
                        <div className="restaurant-handoff-options_info_label">
							Avg. time
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default RestaurantHandoffOptionsDetails;
