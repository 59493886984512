import {ButtonHTMLAttributes, ReactElement, useEffect, useState} from "react";
import {
    GetNftCommunityFromGroupingResponse,
    GoFrensApi, NftCommunity,
    NftGrouping,
    NftTracker,
} from "@devour/client";
import {FaArrowRight} from "react-icons/fa";
import FrameButton from "@/components/buttons/FrameButton";
import {TiStarFullOutline} from "react-icons/ti";
import GoFrensRepCommunityModal from "@/components/modals/GoFrensRepCommunityModal";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {ReactComponent as Timer} from "../../../svgs/timer.svg";
import {addError, decrementLoading, incrementLoading} from "@/redux/meta/metaActions";
import getConfig from "@/utils/getConfig";

interface Props {
    grouping: NftGrouping;
    owned: number;
    tracker: NftTracker;
    show: boolean;
}

function DrawerFooterComponents(props: Props): ReactElement {
    const [
        showModal,
        setShowModal,
    ] = useState<boolean>(false);
    const [
        cooldownTime,
        setCooldownTime,
    ] = useState<number>(0);
    const [
        community,
        setCommunity,
    ] = useState<NftCommunity>(undefined);

    const dispatch = useDispatch();

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    const {data: userData} = useGetUserProfile(fullToken);

    useEffect(() => {
        function getCooldownDays() {
            const now: Date = new Date();
            const lastJoined = new Date(userData.user?.leftCommunityAt);
            const lastJoinedMonth = lastJoined.getMonth();
            const currentMonth = now.getMonth();
            const lastJoinedYear = lastJoined.getFullYear();
            const currentYear = now.getFullYear();

            // there hasn't been a transition to a new year
            // user unable to join until start of next month, set cooldown time. Otherwise, cooldown is 0.
            if (lastJoined && lastJoinedYear === currentYear && currentMonth <= (lastJoinedMonth || 0)) {
                const beginningOfNextMonth = new Date(currentYear, currentMonth + 1, 1);
                const timeDiffInMs = beginningOfNextMonth.getTime() - now.getTime();
                // return cooldown time in days
                return Math.ceil(timeDiffInMs / (1000 * 60 * 60 * 24));
            } else {
                return 0;
            }
        }

        if (userData && props.show) {
            setCooldownTime(getCooldownDays());
            void getCommunityInfo();
        }
    }, [
        userData,
        props.show,
    ]);

    async function getCommunityInfo(): Promise<void> {
        dispatch(incrementLoading());
        try {
            const res: GetNftCommunityFromGroupingResponse = await new GoFrensApi(getConfig(fullToken)).getNftCommunityFromGrouping({
                id: props.grouping.id,
            });
            setCommunity(res.community);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    function isCooldownBypassed(): boolean {
        return community && userData?.user?.previousCommunity && community.id === userData.user.previousCommunity;
    }

    function getButtonContent() {
        if (!userData || !community || props.owned <= 0 || userData.user?.community === community.id) {
            return "Go to OpenSea";
        }

        if (!userData.user?.community && cooldownTime > 0 && !isCooldownBypassed()) {
            const cooldownText = `(${cooldownTime} day${cooldownTime > 1
                ? "s"
                : ""} left)`;
            return <>Rep this community <span className="cooldown-btn_time">{cooldownText}</span></>;
        }

        return `Rep ${props.grouping.name}`;
    }

    function disableButton(): boolean {
        // Open sea link shows when user does not own an NFT, or if the user owns an NFT and is a member of that community
        // If those are true, and there is no open sea link
        // Then disable the button
        if (!props.owned || community && userData?.user?.community === community.id) {
            if (!props.tracker?.openSea) {
                return true;
            }
        }
        return false;
    }

    function displayDisabledStyles(): string {
        return props.owned && cooldownTime && !isCooldownBypassed() ? "cooldown-btn" : "";
    }

    function toggleModal(): void {
        setShowModal(!showModal);
    }

    return (
        <>
            <GoFrensRepCommunityModal
                isOpen={showModal}
                toggle={toggleModal}
                grouping={props.grouping}
            />
            <FrameButton
                <ButtonHTMLAttributes<HTMLButtonElement>>
                color="purple"
                size="large"
                rightIcon={displayDisabledStyles()
                    ? null
                    : props.owned > 0 && !userData?.user?.community
                        ? TiStarFullOutline
                        : FaArrowRight}
                leftIcon={displayDisabledStyles()
                    ? Timer
                    : null}
                // forwardProps={{disabled: disableButton()}}
                showSpinner={true}
                className={`rep-button ${displayDisabledStyles()}`}
                onClick={() => {
                    if (props.owned > 0 && !userData?.user?.community || isCooldownBypassed()) {
                        toggleModal();
                    } else if (props.tracker?.openSea) {
                        window.open(props.tracker.openSea, "_blank");
                    }
                }}
            >
                {getButtonContent()}
            </FrameButton>
        </>
    );
}

export default DrawerFooterComponents;
