import FrameButton from "@/components/buttons/FrameButton";
import MagicLoginModal from "@/components/modals/MagicLoginModal";
import useGetOverwolfArt, { OverwolfArtType } from "@/hooks/useGetOverwolfArt";
import useThemePreference from "@/hooks/useThemePreference";
import { store } from "@/redux";
import { IStore } from "@/redux/defaultStore";
import { toggleSidebar } from "@/redux/meta/metaActions";
import { ThemePreference } from "@/types/Theme";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import OverwolfGameLogo from "./components/OverwolfGameLogo";
import OverwolfGoVipAccountLevel from "./components/OverwolfGoVipAccountLevel";
import { FaExternalLinkAlt } from "react-icons/fa";
import GoVipOWDualRewardingGames from "@/components/goVip/GoVipOWDualRewardingGames";
import GoVipOWTimeBasedGames from "@/components/goVip/GoVipOWTimeBasedGames";
import { isDesktop } from "react-device-detect";
import FrameOneSwitchInput from "@/components/inputs/FrameOneSwitchInput";
import { GoVipLevelsCardData } from "@/components/goVip/goVipText";
import GoVipLevelsCard from "@/components/goVip/GoVipLevelsCard";
import { getGoVipLevelsText } from "@/components/modals/GoVipLevelsModal";
import { FiChevronRight } from "react-icons/fi";
import { useGate } from "statsig-react";
import { useNavigate } from "react-router";

export enum DiscoverView {
    HOW_TO_EARN,
    LEVELS,
}

export default function OverwolfDiscoverPage() {
    const {value: dpayStatus} = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const { getOverwolfArt } = useGetOverwolfArt();
    const dispatch = useDispatch();
    const { setTheme } = useThemePreference();
    const [discoverView, setDiscoverView] = useState<DiscoverView>(DiscoverView.HOW_TO_EARN);
    const goVipLevelsText = getGoVipLevelsText(dpayStatus);
    const navigate = useNavigate();

    // Close the sidebar menu if open
    useEffect(() => {
        dispatch(toggleSidebar(false));
        const originalTheme = store.getState().metaStore.themePreference;
        setTheme(ThemePreference.DARK);

        return () => {
            if (originalTheme) {
                setTheme(originalTheme);
            }
        };
    }, []);

    function renderLevelCards(levelData: Array<GoVipLevelsCardData>) {
        return levelData.map((level) =>
            <GoVipLevelsCard levelsData={level} key={`govip-level-card-${level.level}`}/>);
    }

    return (
        <>
            <div
                className="overwolf-discover-page"
                style={{
                    backgroundImage: `url(${getOverwolfArt(OverwolfArtType.bannerImage)})`,
                }}
            >
                <MagicLoginModal isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)} />

                <div className="overwolf-discover-page_content">
                    <div className="overwolf-discover-page_header">
                        <OverwolfGameLogo />
                        <h1>HOW TO START</h1>
                    </div>

                    <OverwolfGoVipAccountLevel />

                    <div className="overwolf-discover-page_banner">
                        <img className="overwolf-discover-page_banner_img" src={`${import.meta.env.VITE_CDN_URL}/images/overwolf-discover-page-banner-img.png`} alt="discover-banner-img" />
                        <h5 className="overwolf-discover-page_banner_text">Play your favorite games and get rewarded in {isDesktop && <br/>} XP to level-up to earn Fuel to place food orders!</h5>
                    </div>

                    <FrameOneSwitchInput<DiscoverView>
                        className="overwolf-discover-page_switch"
                        name="overwolf-discover-page-toggle"
                        value={discoverView}
                        onToggle={setDiscoverView}
                        options={[
                            {
                                render: "How to Earn",
                                value: DiscoverView.HOW_TO_EARN,
                            },
                            {
                                render: "Levels",
                                value: DiscoverView.LEVELS,
                            },
                        ]}
                    />

                    {discoverView === DiscoverView.HOW_TO_EARN && <div className="go-vip-overwolf">
                        <GoVipOWTimeBasedGames/>

                        <GoVipOWDualRewardingGames/>
                    </div>}

                    {discoverView === DiscoverView.LEVELS && <div className="overwolf-discover-page_levels">
                        <div className="overwolf-discover-page_levels_header">
                            <div className="overwolf-discover-page_levels_header_titles">
                                <h3>Power Up Your Play</h3>
                                <p>Level up, score rewards! Climb the ranks and unlock epic loot: Devour swag, exclusive merch, food discounts, and mystery loot boxes.</p>
                            </div>
                            <hr />
                            <div className="overwolf-discover-page_levels_header_how-to-level-up">
                                <h4>How to Level Up</h4>
                                <span> <span className="purple-title">Earn XP:</span> Order food, share, play and more </span>
                                <span> <span className="purple-title">Claim Rewards:</span> Reach a level, grab FUEL and prizes</span>
                                <span> <span className="purple-title">Dominate the Leaderboards:</span>Top the charts for extra rewards</span>
                                <span> <span className="purple-title">Fuel Your Fun:</span> Spend your FUEL to order food</span>
                            </div>
                            <hr />
                        </div>
                        <div className="go-vip-levels_body_cards-container">

                            {renderLevelCards(goVipLevelsText.slice(0, 3))}

                            <div className="overwolf-discover-page_levels_mint-now-banner">
                                <div className="overwolf-discover-page_levels_mint-now-banner_info">
                                    <h5>Level up your rewards!</h5>
                                    <p>Purchase The Industry Pass to unlock level 4+ and claim the top prizes.</p>
                                </div>
                                <FrameButton
                                    className="overwolf-discover-page_levels_mint-now-banner_action"
                                    color={"devourplay-gradient"}
                                    size="narrow"
                                    rightIcon={FiChevronRight}
                                    onClick={() => navigate("/the-industry-pass")}
                                >
										Mint now
                                </FrameButton>

                            </div>


                            {renderLevelCards(goVipLevelsText.slice(3, goVipLevelsText.length))}

                        </div>
                    </div>}
                    <div className="overwolf-discover-page_footer">

                        <div className="overwolf-survey_divider" />

                        <div className="overwolf-survey">
                            <a
                                href="https://share.hsforms.com/1pM-BLE3hR46pP34PIt5nqAnrwoo"
                                className="go-vip-ow-devourplay-banner_survey"
                                target="_blank"
                                rel="noreferrer"
                            >
                            Give Feedback <FaExternalLinkAlt />
                            </a>
                            <div className="overwolf-survey_text">
                            We're excited to bring you the initial version of our app and would love your feedback to
                            make it even better.
                            </div>
                        </div>
                    </div>
                </div>

                {!fullToken &&
                    <FrameButton
                        size="large"
                        className="overwolf-discover-page_login-btn overwolf-discover-page_button_purple-blue-gradient-shadow"
                        onClick={() => setIsLoginModalOpen(true)}
                    >
                        Login or Sign up
                    </FrameButton>
                }
            </div>
        </>
    );
}
