import {ReactElement, useContext, useState} from "react";
import {GetMenuResponse, MenuOrder, MenuOrderItem, MenuOrderSubItem} from "@devour/client";
import {htmlDecode} from "@/utils/htmlDecode";
import {TbMinus, TbTrashXFilled} from "react-icons/tb";
import {HiPlus} from "react-icons/hi";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import getRestaurantMenuItemImage from "@/utils/getRestaurantMenuItemImage";
import MenuItemCustomizationModal from "@/components/modals/MenuItemCustomizationModal";
import {findMenuItemResponse} from "@/utils/validateMenuOrderItem";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {useRestaurant} from "@/hooks/useRestaurant";

interface Props {
    restaurantMenu: GetMenuResponse;
    menuOrderItem: MenuOrderItem;
    index: number;
    onRemoveItem: () => void;
    onUpdateQuantity: (index: number, quantity: number) => void;
    onExternalUpdate: (menuOrder: MenuOrder) => void;
}

function DevourCartCard(props: Props): ReactElement {

    const {menuOrderId, restaurantId} = useContext(RestaurantContext);
    const {data: restaurant} = useRestaurant(restaurantId);
    const {data: menuOrder} = useMenuOrder(menuOrderId);
    const [
        editItemPanel,
        setEditItemPanel,
    ] = useState<boolean>(false);
    const [
        frontEndPrice,
        setFrontEndPrice,
    ] = useState<number>(calculateMenuOrderItemPrice(props.menuOrderItem));

    function calculateMenuOrderItemPrice(item: MenuOrderItem | MenuOrderSubItem, itemQuantity?: number): number {
        const customizationPrice = item.customizations?.map(c => calculateMenuOrderItemPrice(c))
            .reduce((sum, curr) => sum + curr, 0) || 0;
        return (itemQuantity || item.quantity) * (item.price + customizationPrice);
    }

    if (!props.menuOrderItem || !props.restaurantMenu) {
        return null;
    }
    const menuItem = findMenuItemResponse(props.menuOrderItem, props.restaurantMenu.menus);
    const menuItemImage = getRestaurantMenuItemImage(props.restaurantMenu, props.menuOrderItem);

    function quantityOnChange(quantity: number): void {
        setFrontEndPrice(calculateMenuOrderItemPrice(props.menuOrderItem, quantity));
        if (quantity === 0) {
            props.onRemoveItem();
        } else {
            props.onUpdateQuantity(props.index, quantity);
        }
    }

    function onMenuItemCustomizationModalDone(menuOrder: MenuOrder) {
        setEditItemPanel(false);
        setFrontEndPrice(calculateMenuOrderItemPrice(menuOrder.orderItems[props.index], menuOrder.orderItems[props.index].quantity));
        props.onExternalUpdate(menuOrder);
    }

    if (!restaurant) {
        return null;
    }

    return (
        <>
            <MenuItemCustomizationModal
                isOpen={editItemPanel}
                placeId={menuOrder.address.placeId}
                restaurantId={restaurant?.id}
                restaurantMenu={props.restaurantMenu}
                menuItem={menuItem}
                onClose={() => setEditItemPanel(false)}
                defaultValues={props.menuOrderItem}
                replaceIndex={props.index}
                onDone={onMenuItemCustomizationModalDone}
            />
            <div
                className="devour-cart-card"
                onClick={() => setEditItemPanel(true)}
            >
                {menuItemImage &&
                    <div className="devour-cart-card_image">
                        <img
                            src={menuItemImage}
                            alt={props.menuOrderItem.name}
                            onError={({currentTarget}) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = `${import.meta.env.VITE_CDN_URL}/images/empty.png`;
                            }}
                        />
                    </div>
                }
                <div className="devour-cart-card_details">
                    <h5>{htmlDecode(props.menuOrderItem?.name)}</h5>
                    <div className="devour-cart-card_item-price">
                        ${frontEndPrice.toFixed(2)}
                    </div>

                    <p>
                        {props.menuOrderItem.customizations.map((customization, index) => {
                            return (
                                <span key={`order-item-${index}`}>
                                    {customization.quantity > 1 && `${customization.quantity}x `}
                                    {htmlDecode(customization?.name)}
                                    {index + 1 < props.menuOrderItem.customizations.length && <br/>}
                                </span>
                            );
                        })}
                    </p>
                    {props.menuOrderItem.notes && <p>{props.menuOrderItem.notes}</p>}
                </div>
                <div className="devour-cart-card_quantity">
                    <div
                        className="devour-cart-card_quantity_button"
                        onClick={(e) => {
                            e.stopPropagation();
                            quantityOnChange(props.menuOrderItem.quantity - 1);
                        }}
                    >
                        {props.menuOrderItem.quantity === 1
                            ? <TbTrashXFilled/>
                            : <TbMinus/>}
                    </div>
                    <div className="devour-cart-card_quantity_number">
                        {props.menuOrderItem.quantity}
                    </div>
                    <div
                        className="devour-cart-card_quantity_button"
                        onClick={(e) => {
                            e.stopPropagation();
                            quantityOnChange(props.menuOrderItem.quantity + 1);
                        }}
                    >
                        <HiPlus/>
                    </div>
                </div>
            </div>
        </>
    );
}


export default DevourCartCard;
