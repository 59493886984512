import {ReactElement, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {magic} from "@/utils/magic";
import {logout} from "@/redux/auth/authActions";
import {useLocation, useNavigate} from "react-router-dom";
import { sendMessageToOW } from "@/hooks/useOverwolfInterop";

function MagicSessionManager(): ReactElement {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    async function handleSessionCheck(): Promise<void> {
        try {
            const isMagicActive = await magic.user.isLoggedIn();

            if (!location?.pathname.includes("sign-up") && isMagicActive && !fullToken?.token) {
                console.log("Logging out - isMagicActive: ", isMagicActive);
                console.log("fullToken exists: ", !!fullToken?.token);
                await magic.user.logout();
            } else if (fullToken?.token && isMagicActive === false) {
                console.log("Logging out because Magic is inactive - isMagicActive", isMagicActive);
                console.log("fullToken exists: ", !!fullToken?.token);
                sendMessageToOW({type: "de:logout"});
                dispatch(logout());
                navigate("/log-in");
            }
        } catch (e) {
            console.error("Error checking Magic session: ", e);
        }

    }

    useEffect(() => {
        void handleSessionCheck();
    }, [
        fullToken?.token,
        location?.pathname,
    ]);

    return null;
}

export default MagicSessionManager;
