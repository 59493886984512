/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetDevourStatsResponseData
 */
export interface GetDevourStatsResponseData {
    /**
     * The user\'s GoVIP level
     * @type {number}
     * @memberof GetDevourStatsResponseData
     */
    goVipLevel?: number;
    /**
     * The user\'s GoVIP experience points
     * @type {number}
     * @memberof GetDevourStatsResponseData
     */
    goVipXP?: number;
    /**
     * Boolean of whether the user has the industry pass
     * @type {boolean}
     * @memberof GetDevourStatsResponseData
     */
    industryPassHolder?: boolean;
}

export function GetDevourStatsResponseDataFromJSON(json: any): GetDevourStatsResponseData {
    return GetDevourStatsResponseDataFromJSONTyped(json, false);
}

export function GetDevourStatsResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDevourStatsResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'goVipLevel': !exists(json, 'goVipLevel') ? undefined : json['goVipLevel'],
        'goVipXP': !exists(json, 'goVipXP') ? undefined : json['goVipXP'],
        'industryPassHolder': !exists(json, 'industryPassHolder') ? undefined : json['industryPassHolder'],
    };
}

export function GetDevourStatsResponseDataToJSON(value?: GetDevourStatsResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'goVipLevel': value.goVipLevel,
        'goVipXP': value.goVipXP,
        'industryPassHolder': value.industryPassHolder,
    };
}


