import {useLocation} from "react-router";
import {FC, useEffect} from "react";
import ReactGA from "react-ga4";

const gaTrackingCode = import.meta.env.VITE_GOOGLE_ANALYTICS_TRACKING_CODE;

const NavigationTracking: FC = () => {
    const location = useLocation();

    useEffect(() => {
        if (gaTrackingCode) {
            ReactGA.initialize(gaTrackingCode);
        }
    }, []);

    useEffect(() => {
        if (gaTrackingCode) {
            ReactGA.send({
                hitType: "pageview",
                page: location.pathname,
            });
        }
    }, [location.pathname]);

    return null;
};

export default NavigationTracking;