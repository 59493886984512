/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetDevourStatsResponseData,
    GetDevourStatsResponseDataFromJSON,
    GetDevourStatsResponseDataFromJSONTyped,
    GetDevourStatsResponseDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetDevourStatsResponse
 */
export interface GetDevourStatsResponse {
    /**
     * 
     * @type {GetDevourStatsResponseData}
     * @memberof GetDevourStatsResponse
     */
    data?: GetDevourStatsResponseData;
}

export function GetDevourStatsResponseFromJSON(json: any): GetDevourStatsResponse {
    return GetDevourStatsResponseFromJSONTyped(json, false);
}

export function GetDevourStatsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDevourStatsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : GetDevourStatsResponseDataFromJSON(json['data']),
    };
}

export function GetDevourStatsResponseToJSON(value?: GetDevourStatsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GetDevourStatsResponseDataToJSON(value.data),
    };
}


