import {ReactElement, useState} from "react";
import FrameButton from "../buttons/FrameButton";
import {FiChevronRight} from "react-icons/fi";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {restaurantSearchRedirect} from "@/utils/restaurantSearchRedirect";
import useWindowSize from "@/hooks/useWindowSize";
import GoVipLevelsModal from "@/components/modals/GoVipLevelsModal";
import useThemePreference from "@/hooks/useThemePreference";

function getWindowBreakpoint(windowWidth: number): string {
    if (windowWidth <= 375) {
        return "XS";
    }
    if (windowWidth > 375 && windowWidth <= 576) {
        return "SM";
    } if (windowWidth > 576 && windowWidth <= 834) {
        return "MD";
    } if (windowWidth > 834 && windowWidth <= 1280) {
        return "LG";
    }
    return "XL";

}

function getTextTitle(size: string): string {
    if (size === "XS" || size === "SM") {
        return "Unlock rewards";
    }

    return "Earn XP to unlock rewards";
}

function GoVipEarnPointsCard(): ReactElement {
    // const windowBreakpoint = getWindowBreakpoint(useWindowSize()[0]);
    const navigate = useNavigate();
    const lastSearchedPlaceId = useSelector((store: IStore) => store.metaStore.lastSearchedPlaceId);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);

    const [
        showGoVipLevelsModal,
        setShowGoVipLevelsModal,
    ] = useState<boolean>(false);

    const windowSize = useWindowSize()[0];

    const size = getWindowBreakpoint(windowSize);

    const { getThemedImageUrl } = useThemePreference();

    function getAssetLink(size: string): string {
        return getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/promo-bnr-FUEL-${size === "XS" ? "SM" : size === "XL" ? "LG" : size}.webp`);
    }

    function getBackgroundLink(size: string): string {
        return getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/GoVIPRewardsInfoBannerBackground-${size}.webp`);
    }

    const assetLink = getAssetLink(size);
    const backgroundLink = getBackgroundLink(size);

    async function handleOrderFoodRouteForAuthedUser(): Promise<void> {
        const redirectRoute = await restaurantSearchRedirect(currentUser?.user?.addresses, lastSearchedPlaceId);
        if (redirectRoute) {
            navigate(redirectRoute);
        } else {
            navigate("/restaurants");
        }
    }

    const handleLearnMoreClick = () => {
        setShowGoVipLevelsModal((prev) => !prev);
    };

    return (
        <div
            className="earn-points-card"
            style={{backgroundImage: `url(${backgroundLink})`}}
        >
            <div className="earn-points-card_text">

                <div className="earn-points-card_text_title">
                    <p className={size}>
                        {getTextTitle(size)}
                    </p>
                </div>
                <div className="earn-points-card_text_body">
                    <p className={size}>
                        Earn 1 XP for each dollar spent on DevourGo
                        {!(size === "XS" || size === "SM") ? " and watch your XP stack up. Order now to start earning!" : ""}
                    </p>
                </div>

                {(size === "SM" || size === "XS") &&
                    <p className="earn-points-card_text_learn-more">
                        <button
                            className="reset-link"
                            onClick={handleLearnMoreClick}
                        >
                            Learn More
                        </button>
                    </p>
                }
                <div className="earn-points-card_text_button-container">
                    <FrameButton
                        color="purple"
                        size="narrow"
                        className="earn-points-card_text_button-container_order-button"
                        rightIcon={FiChevronRight}
                        onClick={handleOrderFoodRouteForAuthedUser}
                    >
                        Order Now
                    </FrameButton>
                    {!(size === "XS" || size === "SM") &&
                        <FrameButton
                            color="purple-text-no-border"
                            size="narrow"
                            className="earn-points-card_text_button-container_learn-more-button"
                            onClick={handleLearnMoreClick}
                        >
                            Learn More
                        </FrameButton>
                    }
                </div>

            </div>

            <div className={`earn-points-card_image earn-points-card_image_${size}`}>
                <img
                    className={size}
                    src={assetLink}
                    alt={"Earn XP"}
                />
            </div>

            <GoVipLevelsModal
                isOpen={showGoVipLevelsModal}
                toggle={handleLearnMoreClick}
            />
        </div>
    );
}

export default GoVipEarnPointsCard;