import { useQuery } from "@tanstack/react-query";

interface GameStatus {
    game_id: number
    state: number
    disabled: boolean
    is_vgep: boolean
    vgep_prefix: any
    min_gep_version: string
}


async function fetchOverwolfGameStatuses(): Promise<GameStatus[]> {
    return await (await fetch("https://game-events-status.overwolf.com/gamestatus_prod.json")).json();
}

function useGetOverwolfGameEventsDisabledQuery(gameId: string) {
    return {
        queryKey: [
            "overwolf-disabled-games",
        ],
        queryFn: () => fetchOverwolfGameStatuses(),
        staleTime: 60 * 60000,
        enabled: !!gameId,
    } as const;
}

export default function useGetOverwolfGameEventsDisabled(gameId: string) {
    const { data } = useQuery<GameStatus[]>(useGetOverwolfGameEventsDisabledQuery(gameId));
    const state = data?.find((game) => game.game_id === parseFloat(gameId))?.state ?? 1;

    /*
     * 1 is available
     * 0 is unsupported
     * 2 is may be unavailable
     * 3 is unavailable
     */
    const gameEventsDisabled = state !== 1;

    return { gameEventsDisabled,
        state };
}
