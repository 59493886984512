/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DiscordInfo
 */
export interface DiscordInfo {
    /**
     * Discord user ID
     * @type {string}
     * @memberof DiscordInfo
     */
    id?: string;
    /**
     * Discord username
     * @type {string}
     * @memberof DiscordInfo
     */
    username?: string;
}

export function DiscordInfoFromJSON(json: any): DiscordInfo {
    return DiscordInfoFromJSONTyped(json, false);
}

export function DiscordInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscordInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'username': !exists(json, 'username') ? undefined : json['username'],
    };
}

export function DiscordInfoToJSON(value?: DiscordInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'username': value.username,
    };
}


