import {ReactElement} from "react";
import {AccountExperienceTransaction} from "@devour/client";
import GoVipOWTimeBasedRewardCard from "@/components/goVip/GoVipOWTimeBasedRewardCard";
import useWindowSize from "@/hooks/useWindowSize";

interface Props {
    rewards: Array<AccountExperienceTransaction>;
}

export default function GoVipOWTimeRewardsModalContent(props: Props) {
    const screenWidth = useWindowSize()[0];

    function renderEmptyRewards(): ReactElement {
        const emptyDescription: ReactElement = screenWidth <= 400
            ? <>No XP yet, but the more you play,<br/>the more you earn. Jump into a game and<br/>play for an hour to earn your first XP!</>
            : <>No XP yet, but the more you play,<br />the more you earn. Jump into a game and play<br />for an hour to earn your first XP!</>;

        return (
            <div className="go-vip-ow_rewards-modal_body_empty">
                <img src={`${import.meta.env.VITE_CDN_URL}/images/govip-ow-time-rewards-modal-empty.webp`} alt="GoVIP Overwolf Rewards Empty"/>
                <h5>Haven't played enough?</h5>
                <span className="go-vip-ow_rewards-modal_body_empty_description">
                    {emptyDescription}
                </span>
            </div>
        );
    }

    return (
        <>
            {props.rewards?.length > 0
                ? <div className="go-vip-ow_rewards-modal_body_list">
                    {props.rewards.map((reward: AccountExperienceTransaction) =>
                        <GoVipOWTimeBasedRewardCard reward={reward} key={reward.id}/>)}
                </div>
                : renderEmptyRewards()
            }
        </>
    );
}