import {OverwolfApi, OWUserSessionEventType} from "@devour/client";
import getConfig from "@/utils/getConfig";
import { store } from "@/redux";

export async function logOverwolfUserEvent(eventType: OWUserSessionEventType): Promise<void> {
    let referralCampaign;
    if ([OWUserSessionEventType.RETURNING, OWUserSessionEventType.SIGNUP].includes(eventType)) {
        referralCampaign = store.getState().metaStore?.overwolfUserInfo?.installParams?.campaign ?? undefined;
    }
    try {
        await new OverwolfApi(getConfig()).logOWUserEvent({
            createOWUserEventBody: {
                eventType,
                referralCampaign,
            },
        });
    } catch (e) {}
}
