import {ReactElement, useContext, useEffect, useState} from "react";
import {MenuOrdersApi} from "@devour/client";
import FrameOneSwitchInput, {FrameOneSwitchInputOption} from "../../inputs/FrameOneSwitchInput";
import {useDispatch, useSelector} from "react-redux";
import getConfig from "../../../utils/getConfig";
import {addError} from "@/redux/meta/metaActions";
import Skeleton from "react-loading-skeleton";
import DeliveryTipModal from "@/components/modals/DeliveryTipModal";
import {IStore} from "@/redux/defaultStore";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";


type TipOption = number | "custom";
const tipOptions: Array<TipOption> = [
    0.15,
    0.18,
    0.2,
    "custom",
];

interface FormValues {
    tipDeliveryPercent: TipOption;
}

const defaultValues: FormValues = {
    tipDeliveryPercent: 0.15,
};

function CheckoutDeliveryTips(): ReactElement {
    const dispatch = useDispatch();
    const {menuOrderId} = useContext(RestaurantContext);
    const {data: menuOrder, refetch: refetchMenuOrder} = useMenuOrder(menuOrderId);
    const isMenuOrderUpdating = useSelector((store: IStore) => store.metaStore.isMenuOrderUpdating);

    const [
        isMenuOrderTipUpdating,
        setIsMenuOrderTipUpdating,
    ] = useState<boolean>(false);
    const [
        formValues,
        setFormValues,
    ] = useState<FormValues>(defaultValues);
    const [
        showCustomTipModal,
        setShowCustomTipModal,
    ] = useState<boolean>(false);
    const tipSwitchInputs: Array<FrameOneSwitchInputOption<TipOption>> = tipOptions.map((option) => {
        if (option === "custom") {
            return {
                render: "Custom",
                value: "custom",
            };
        } else {
            const tipDeliveryAmount = Math.round(menuOrder?.subtotal * option * 100) / 100;
            return {
                render: `${option * 100}%`,
                value: tipDeliveryAmount,
            };
        }
    });

    useEffect(() => {
        if (menuOrder) {
            const tipSwitchSelected: FrameOneSwitchInputOption<TipOption> = tipSwitchInputs.find((option) => option.value === menuOrder.tipDelivery);
            const tipSwitchSelectedValue: TipOption = tipSwitchSelected
                ? tipSwitchSelected.value as number
                : "custom";
            setFormValues({
                ...formValues,
                tipDeliveryPercent: tipSwitchSelectedValue,
            });
        }
    }, [menuOrder]);

    async function onUpdateTip(tipDeliveryAmount: number): Promise<void> {
        let isStillLoading: boolean = true;
        try {
            // This is to let the sliding animation play before skeleton is active
            setTimeout(() => {
                if (isStillLoading) {
                    setIsMenuOrderTipUpdating(true);
                }
            }, 400); // .4s @_frame-one-switch-input.scss
            await new MenuOrdersApi(getConfig()).updateMenuOrder({
                id: menuOrder.id,
                createMenuOrderBody: {
                    tipDelivery: tipDeliveryAmount,
                },
            });
            await refetchMenuOrder();
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            isStillLoading = false;
            setIsMenuOrderTipUpdating(false);
        }
    }

    function onTipDeliveryPercentChange(tipDeliveryAmount: TipOption): void {
        if (tipDeliveryAmount === "custom") {
            setShowCustomTipModal(true);
        } else {
            setFormValues({
                ...formValues,
                tipDeliveryPercent: tipDeliveryAmount,
            });
            void onUpdateTip(tipDeliveryAmount);
        }
    }

    function onCustomTipModalClose(amount: number | null): void {
        if (amount != null) {
            void onUpdateTip(amount);
        }
        setShowCustomTipModal(false);
    }

    return (
        <div className="checkout-summary_tips">
            <DeliveryTipModal
                isOpen={showCustomTipModal}
                menuOrder={menuOrder}
                onClose={onCustomTipModalClose}
            />

            <div className="checkout-summary_totals_row">
                <span>Delivery Tip</span>
                <span>
                    {menuOrder?.callbackComplete && !isMenuOrderUpdating && !isMenuOrderTipUpdating
                        ? `$${menuOrder.tipDelivery.toFixed(2)}`
                        : <Skeleton height={17.5} width={40}/>
                    }
                </span>
            </div>

            <div className="checkout-summary_tips_switch">
                {menuOrder?.callbackComplete && !isMenuOrderUpdating
                    ? <FrameOneSwitchInput
                        <TipOption>
                        name="tip-delivery"
                        value={formValues.tipDeliveryPercent}
                        onToggle={onTipDeliveryPercentChange}
                        options={tipSwitchInputs}
                    />
                    : <div className="checkout-summary_tips-skeleton react-loading-skeleton" />
                }
            </div>
        </div>
    );
}

export default CheckoutDeliveryTips;
