/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OWClaimLog
 */
export interface OWClaimLog {
    /**
     * 
     * @type {number}
     * @memberof OWClaimLog
     */
    target: number;
    /**
     * 
     * @type {number}
     * @memberof OWClaimLog
     */
    date: number;
    /**
     * 
     * @type {number}
     * @memberof OWClaimLog
     */
    amountReceived: number;
}

export function OWClaimLogFromJSON(json: any): OWClaimLog {
    return OWClaimLogFromJSONTyped(json, false);
}

export function OWClaimLogFromJSONTyped(json: any, ignoreDiscriminator: boolean): OWClaimLog {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'target': json['target'],
        'date': json['date'],
        'amountReceived': json['amountReceived'],
    };
}

export function OWClaimLogToJSON(value?: OWClaimLog | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'target': value.target,
        'date': value.date,
        'amountReceived': value.amountReceived,
    };
}


