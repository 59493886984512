import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "@/components/modals/autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {StripePaymentMethodObject} from "@/types/Stripe";
import {ActiveDpay, ActivePaymentMethod} from "@/utils/getMenuOrderPaymentMethod";
import {ButtonHTMLAttributes, useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {BsCreditCardFill} from "react-icons/bs";
import {capitalize} from "lodash";
import FrameButton from "@/components/buttons/FrameButton";
import {NumericFormat} from "react-number-format";
import {AiOutlinePlus} from "react-icons/ai";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import Spacer from "@/components/Spacer";
import {IoWallet} from "react-icons/io5";
import {useGetTransactions} from "@/hooks/useGetTransactions";
import {roundNumber} from "@/utils/roundNumber";
import {NumberFormatValues} from "react-number-format/types/types";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";

interface Props {
    isOpen: boolean;
    toggle: () => void;
    selectedStripePayment: StripePaymentMethodObject;
    selectedDpayMethod: ActiveDpay;
    paymentMethod: ActivePaymentMethod;
    dpayBalance: number;
    wcTag?: string;
    updateCartWithDpayAmount: (dpay: number) => Promise<void>;
}

function CheckoutPaymentSplitPayModal(props: Props) {
    const MINIMUM_STRIPE_AMOUNT = 0.5;
    const MINIMUM_DPAY_AMOUNT = 0.01;

    const {menuOrderId} = useContext(RestaurantContext);

    const currentUser = useSelector((store: IStore) => store.metaStore?.currentUser);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    const {data: menuOrder} = useMenuOrder(menuOrderId);
    const {data: transactionData} = useGetTransactions(fullToken, currentUser?.user?.id);
    const grandTotal = menuOrder?.grandTotal;

    const [
        dpayAmount,
        setDpayAmount,
    ] = useState<number>(0);
    const [
        stripeAmount,
        setStripeAmount,
    ] = useState<number>(0);
    const [
        maxFiatAmountAllowed,
        setMaxFiatAmountAllowed,
    ] = useState<number>(0);

    const fiatDpayBalance = convertDpayToFiat(props.dpayBalance);

    useEffect(() => {
        if (grandTotal && props.isOpen && menuOrder) {
            updateAmounts(props.paymentMethod === ActivePaymentMethod.SPLIT && menuOrder.dpay
                ? convertDpayToFiat(menuOrder.dpay)
                : undefined);
        }
    }, [
        props.isOpen,
        grandTotal,
        menuOrder,
    ]);

    useEffect(() => {
        if (transactionData && grandTotal && fiatDpayBalance) {
            setMaxFiatAmountAllowed(Math.min(fiatDpayBalance, grandTotal - MINIMUM_STRIPE_AMOUNT));
        }
    }, [
        fiatDpayBalance,
        transactionData,
        grandTotal,
    ]);

    function convertFiatToDpay(fiat: number, dpayBalance: number) {

        const calculatedDpay = fiat / menuOrder.dpayFiatAtOrderTime;
        const dpayPerCent = 0.01 / menuOrder.dpayFiatAtOrderTime;

        if (calculatedDpay &&
            calculatedDpay - dpayBalance > 0 &&
            calculatedDpay - dpayBalance <= dpayPerCent) {
            return dpayBalance;
        }

        return calculatedDpay;
    }

    function convertDpayToFiat(dpay: number): number {
        return roundNumber(dpay * menuOrder.dpayFiatAtOrderTime);
    }

    function roundStripe(amount: number): number {
        return Number(amount.toFixed(2));
    }

    function updateAmounts(dpayInFiat?: number, creditAmount?: number): void {
        let dpayAmount: number;
        let stripeAmount: number;
        if (dpayInFiat == undefined && creditAmount == undefined) {
            // default amounts to half
            const halfTotal = grandTotal / 2;

            // calculate dpay amount first, then the rest will be charged as Stripe total
            dpayAmount = Math.min(halfTotal, maxFiatAmountAllowed);

            // round in case of odd amounts
            stripeAmount = roundStripe(grandTotal - dpayAmount);

            // assign remainder to dpay, this should be the "real" dpay amount after rounding stripe totals
            dpayAmount = grandTotal - stripeAmount;

        } else if (dpayInFiat != undefined) {
            dpayAmount = Math.max(Math.min(dpayInFiat, maxFiatAmountAllowed), MINIMUM_DPAY_AMOUNT);
            stripeAmount = roundStripe(grandTotal - dpayAmount);
            dpayAmount = grandTotal - stripeAmount;

        } else if (creditAmount != undefined) {

            // calculate dpay amount first in case dpay balance is not enough to cover the rest of the total
            const creditAmountAllowed = Math.max(Math.min(creditAmount, grandTotal - MINIMUM_DPAY_AMOUNT), MINIMUM_STRIPE_AMOUNT);
            dpayAmount = Math.min(grandTotal - creditAmountAllowed, maxFiatAmountAllowed);
            stripeAmount = roundStripe(grandTotal - dpayAmount);
            dpayAmount = grandTotal - stripeAmount;
        }

        setDpayAmount(dpayAmount);
        setStripeAmount(stripeAmount);
    }

    function dpayOnChange(values: NumberFormatValues): void {
        setDpayAmount(values.floatValue);
    }

    function creditOnChange(values: NumberFormatValues) {
        setStripeAmount(values.floatValue);
    }

    function dpayOffFocus(): void {
        updateAmounts(dpayAmount);
    }

    function creditOffFocus(): void {
        updateAmounts(null, stripeAmount);
    }

    function renderContent() {
        return !!menuOrder &&
            props.selectedStripePayment &&
            transactionData;
    }

    function renderDpayMethod() {
        if (props.selectedDpayMethod === ActiveDpay.EXTERNAL) {
            return (
                <div className="checkout-split-pay-modal_body_field_label">
                    <img
                        className="checkout-split-pay-modal_body_field_label_icon_wc-icon"
                        src={import.meta.env.VITE_CDN_URL + "/images/wallet-connect-icon.webp"}
                        alt="Wallet connect icon"
                    />
                    <strong>External Wallet</strong>
                    <div className="checkout-payments-dpay-modal_body_wc-tag">
                        <p>{props.wcTag}</p>
                    </div>
                </div>
            );
        } else if (props.selectedDpayMethod === ActiveDpay.MAGIC) {
            return (
                <div className="checkout-split-pay-modal_body_field_label">
                    <IoWallet
                        className="checkout-payment-option_card_icon checkout-payments-dpay-modal_body_wallet-icon"/>
                    <strong>DevourGO Wallet</strong>
                </div>
            );
        } else {
            return (
                <div className="checkout-split-pay-modal_body_vdpay">
                    <div className="checkout-split-pay-modal_body_field_label">
                        <img
                            className="checkout-split-pay-modal_body_field_label_icon"
                            src={import.meta.env.VITE_CDN_URL + "/images/FUEL.webp"}
                            alt="DPAY icon"
                        />
                        <strong>My {import.meta.env.VITE_TOKEN_NAME} Account</strong>
                    </div>
                    <div className="checkout-split-pay-modal_body_field_label-tag">
                        <span>My Balance:
                            <span
                                className="checkout-split-pay-modal_body_field_label-tag_balance"> ${fiatDpayBalance}</span>
                        </span>
                    </div>
                </div>
            );
        }
    }

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            toggle={props.toggle}
            contentClassName="checkout-split-pay-modal"
            modalOnTablet={true}
            size="sm2"
        >
            <FrameAutoPanelHeader
                title="Split Pay"
                isTitleBold={true}
                toggle={props.toggle}
                showLeftChevron={true}
                showXTopRight={true}
                tooltipContent="The default amount is half of the total payment, but you can change it.
                    Any remaining balance will be automatically charged to the other specified payment method."
            >
                {isDesktop && <hr/>}
            </FrameAutoPanelHeader>
            <FrameAutoPanelBody className="checkout-split-pay-modal_body">
                {renderContent() &&
                    <>
                        <div className="checkout-split-pay-modal_body_order-total">
                            <div className="checkout-split-pay-modal_body_order-total_text">
                                Total amount being split
                            </div>
                            <div className="checkout-split-pay-modal_body_order-total_balance">
                                ${menuOrder.grandTotal.toFixed(2)}
                            </div>
                        </div>

                        <div className="checkout-split-pay-modal_body_field">
                            <div className="checkout-split-pay-modal_body_field_label">
                                <div className="checkout-split-pay-credit-container">
                                    <div className="checkout-split-pay-credit-container_inner">
                                        <BsCreditCardFill/>
                                        <strong>{capitalize(props.selectedStripePayment.card.brand)} ****{props.selectedStripePayment.card.last4}</strong>
                                        {isMobile && !isTablet && <Spacer/>}
                                        <FrameButton
                                            <ButtonHTMLAttributes<HTMLButtonElement>>
                                            color="gray"
                                            size="narrow"
                                            className="checkout-payment-option_button"
                                            onClick={props.toggle}
                                        >
                                            Edit
                                        </FrameButton>
                                    </div>
                                    <p className="checkout-split-pay-credit-container_disclaimer">
                                        Min amount: $0.50
                                    </p>
                                </div>
                            </div>
                            <div className="checkout-split-pay-modal_body_field_input">
                                <NumericFormat
                                    value={stripeAmount}
                                    prefix="$"
                                    onValueChange={creditOnChange}
                                    onBlur={creditOffFocus}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    allowNegative={false}
                                    thousandSeparator=","
                                />
                                <p>USD</p>
                            </div>
                        </div>
                        <div className="checkout-split-pay-modal_body_divider"><AiOutlinePlus/></div>

                        <div className="checkout-split-pay-modal_body_field checkout-split-pay-modal_body_dpay">
                            {renderDpayMethod()}
                            <div className="checkout-split-pay-modal_body_field_input">
                                <NumericFormat
                                    value={dpayAmount}
                                    prefix="$"
                                    onValueChange={dpayOnChange}
                                    onBlur={dpayOffFocus}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    allowNegative={false}
                                    thousandSeparator=","
                                />
                                <p>USD</p>
                            </div>
                        </div>

                        <FrameButton
                            color="purple"
                            size="large"
                            onClick={() => props.updateCartWithDpayAmount(convertFiatToDpay(dpayAmount, props.dpayBalance))}
                            forwardProps={{
                                disabled: !dpayAmount,
                            }}
                            className="checkout-split-setup-modal_body_continue"
                        >
                            Continue
                        </FrameButton>

                    </>

                }

            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}

export default CheckoutPaymentSplitPayModal;
