import {ReactElement} from "react";
import FrameButton from "../buttons/FrameButton";
import { Link } from "react-router-dom";
import FrameOneAutoPanel from "./autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "./autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "./autoPanelComponents/FrameAutoPanelBody";

interface Props {
    isOpen: boolean;
    toggle: () => void;
}

function Share2EarnDetailsModal(props: Props): ReactElement {

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            toggle={props.toggle}
            contentClassName="share2earn-card-info-modal"
        >
            <FrameAutoPanelHeader
                title="Share2Earn Details"
                toggle={props.toggle}
            />

            <FrameAutoPanelBody className="share2earn-card-info-modal_body">
                <p>
					Earn 10 XP or more per entry. Some entries can be earned daily.
                </p>

                <p>
					To receive GoVIP XP from Share2Earn, the email you use to register for Share2Earn <strong>must
					match</strong> the email you used to register for DevourGO.
                </p>

                <p>
					Earning of XP is subject to <Link style={{color: "#7859E6"}} to="/terms-of-use">Terms and
					Conditions</Link>
                </p>

                <div className="share2earn-card-info-modal_body_btn-container">
                    <FrameButton
                        color="purple"
                        size="normal"
                        onClick={props.toggle}
                        className="share2earn-card-info-modal_body_btn-container_button"
                    >
						Close
                    </FrameButton>
                </div>
            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}

export default Share2EarnDetailsModal;
