/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    OWClaimLog,
    OWClaimLogFromJSON,
    OWClaimLogFromJSONTyped,
    OWClaimLogToJSON,
    OWGame,
    OWGameFromJSON,
    OWGameFromJSONTyped,
    OWGameToJSON,
    OWLogCategory,
    OWLogCategoryFromJSON,
    OWLogCategoryFromJSONTyped,
    OWLogCategoryToJSON,
    OWReward,
    OWRewardFromJSON,
    OWRewardFromJSONTyped,
    OWRewardToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Collection contains the user\'s reward stats/progress.
 * @export
 * @interface OWUserStat
 */
export interface OWUserStat {
    /**
     * The ID of the user for which this reward is applicable.
     * @type {string}
     * @memberof OWUserStat
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof OWUserStat
     */
    gameId?: string;
    /**
     * 
     * @type {OWGame}
     * @memberof OWUserStat
     */
    game?: OWGame;
    /**
     * Whether the game-based reward has been claimed.
     * @type {boolean}
     * @memberof OWUserStat
     */
    isClaimed?: boolean;
    /**
     * 
     * @type {OWReward}
     * @memberof OWUserStat
     */
    reward: OWReward;
    /**
     * 
     * @type {OWLogCategory}
     * @memberof OWUserStat
     */
    rewardCategory: OWLogCategory;
    /**
     * The progress towards the target.
     * @type {number}
     * @memberof OWUserStat
     */
    progress: number;
    /**
     * The target to reach to claim the reward.
     * @type {number}
     * @memberof OWUserStat
     */
    target: number;
    /**
     * The UNIX timestamp of the start of the week (Mon 12AM EST)
     * @type {number}
     * @memberof OWUserStat
     */
    weekStart?: number;
    /**
     * The progress towards the target for the current week.
     * @type {number}
     * @memberof OWUserStat
     */
    weeklyProgress?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OWUserStat
     */
    isWeeklyLimitFeedbackDismissed?: boolean;
    /**
     * 
     * @type {Array<OWClaimLog>}
     * @memberof OWUserStat
     */
    claimLogs?: Array<OWClaimLog>;
    /**
     * The timestamp when the event was created.
     * @type {Date}
     * @memberof OWUserStat
     */
    createdAt: Date;
    /**
     * The timestamp when the event was last updated.
     * @type {Date}
     * @memberof OWUserStat
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof OWUserStat
     */
    id: string;
}

export function OWUserStatFromJSON(json: any): OWUserStat {
    return OWUserStatFromJSONTyped(json, false);
}

export function OWUserStatFromJSONTyped(json: any, ignoreDiscriminator: boolean): OWUserStat {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'gameId': !exists(json, 'gameId') ? undefined : json['gameId'],
        'game': !exists(json, 'game') ? undefined : OWGameFromJSON(json['game']),
        'isClaimed': !exists(json, 'isClaimed') ? undefined : json['isClaimed'],
        'reward': OWRewardFromJSON(json['reward']),
        'rewardCategory': OWLogCategoryFromJSON(json['rewardCategory']),
        'progress': json['progress'],
        'target': json['target'],
        'weekStart': !exists(json, 'weekStart') ? undefined : json['weekStart'],
        'weeklyProgress': !exists(json, 'weeklyProgress') ? undefined : json['weeklyProgress'],
        'isWeeklyLimitFeedbackDismissed': !exists(json, 'isWeeklyLimitFeedbackDismissed') ? undefined : json['isWeeklyLimitFeedbackDismissed'],
        'claimLogs': !exists(json, 'claimLogs') ? undefined : ((json['claimLogs'] as Array<any>).map(OWClaimLogFromJSON)),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'id': json['id'],
    };
}

export function OWUserStatToJSON(value?: OWUserStat | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'gameId': value.gameId,
        'game': OWGameToJSON(value.game),
        'isClaimed': value.isClaimed,
        'reward': OWRewardToJSON(value.reward),
        'rewardCategory': OWLogCategoryToJSON(value.rewardCategory),
        'progress': value.progress,
        'target': value.target,
        'weekStart': value.weekStart,
        'weeklyProgress': value.weeklyProgress,
        'isWeeklyLimitFeedbackDismissed': value.isWeeklyLimitFeedbackDismissed,
        'claimLogs': value.claimLogs === undefined ? undefined : ((value.claimLogs as Array<any>).map(OWClaimLogToJSON)),
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'id': value.id,
    };
}


