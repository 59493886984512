import {ReactElement, useState} from "react";
import {isDesktop} from "react-device-detect";
import {FaArrowRight} from "react-icons/fa";
import useWindowSize, {getScreenWidthStr, WindowBreakpointSizes} from "@/hooks/useWindowSize";
import FrameButton from "@/components/buttons/FrameButton";
import {GetOverwolfXpSubjectEnum} from "@devour/client";
import GoVipOWTimeBasedRewardsModal from "@/components/goVip/GoVipOWTimeBasedRewardsModal";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import useGetUserOverwolfXP from "@/hooks/useGetUserOverwolfXP";


export default function GoVipOWTimeBasedGames(): ReactElement {
    const windowSize = useWindowSize();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    let windowWidth = getScreenWidthStr(windowSize[0]);
    windowWidth = windowWidth === WindowBreakpointSizes.XSMALL ? WindowBreakpointSizes.SMALL : windowWidth;
    const [showRewardsModal, setShowRewardsModal] = useState(false);
    const {data: rewardsData} = useGetUserOverwolfXP(fullToken, GetOverwolfXpSubjectEnum.TIMEREWARD);

    function toggleRewardsModal() {
        setShowRewardsModal((prev) => !prev);
    }

    return (
        <div className="go-vip-overwolf_rewards">
            <GoVipOWTimeBasedRewardsModal
                isOpen={showRewardsModal}
                onClose={toggleRewardsModal}
                rewards={rewardsData?.transactions ?? []}
            />
            <div className="go-vip-overwolf_rewards_header">
                <div className="go-vip-overwolf_rewards_header_text">
                    <h3 className="go-vip-overwolf_heading">
                        Online Games to Boost XP
                        <span>2,257</span> {/*  Hard-coded number just to show many games are supported */}
                    </h3>

                    <div className="go-vip-overwolf_rewards_header_description">
                        Earn 10 XP for every hour of gameplay!
                        {isDesktop ? " The more you play, the more XP you collect" : ""}
                    </div>
                </div>

                <a
                    className="go-vip-overwolf_rewards_header_games-link"
                    href="https://www.overwolf.com/supported-games/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    View supported games <FaArrowRight size="22.3px"/>
                </a>
            </div>

            <div
                style={{
                    backgroundImage: `url(${import.meta.env.VITE_CDN_URL}/images/go-vip-ow-time-based-bnr-${windowWidth}.webp)`,
                }}
                className="go-vip-overwolf_rewards_time-based"
            >
                <FrameButton
                    size="normal"
                    className="go-vip-overwolf_rewards_time-based_btn"
                    color="purple"
                    onClick={() => setShowRewardsModal(true)}
                >
                    {rewardsData?.transactions.length > 0 ? "Track Rewards" : "Play to Earn"}
                </FrameButton>
            </div>
        </div>
    );
}