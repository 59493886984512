import { ReactElement, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "@/redux/defaultStore";
import { addError, removeDpayPriceExpiryTime, removeMenuOrder, updateAccountLevel } from "@/redux/meta/metaActions";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { HandoffOptions, OrderDiscountType, UsersApi } from "@devour/client";
import OrderHistoryDetails from "../OrderHistoryDetails";
import getConfig from "../../utils/getConfig";
import Toast from "../Toast";
import { useMenuOrder } from "@/hooks/menuOrder/useMenuOrder";
import DevourTopNav from "../DevourTopNav";
import MenuOrderSuccessSummary from "./MenuOrderSuccessSummary";
import { BiArrowBack } from "react-icons/bi";
import MenuOrderSuccessTracker from "./MenuOrderSuccessTracker";
import {useRestaurant} from "@/hooks/useRestaurant";
import { cancelledList, menuOrderFinalizedStatuses } from "@/pages/menu-orders/MenuOrderListPage";

interface Props {
    menuOrderId?: string;
}

function MenuOrderSuccessPage(props: Props): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const user = useSelector((store: IStore) => store.metaStore.currentUser?.user);
    const menuOrders = useSelector((store: IStore) => store.metaStore.menuOrders);
    const [showToast, setShowToast] = useState<boolean>(true);

    const history = useNavigate();
    let { menuOrderId } = useParams<{ menuOrderId: string }>();
    menuOrderId = menuOrderId || props.menuOrderId;
    const {data: menuOrder, isFetchedAfterMount, isLoading, refetch} = useMenuOrder(menuOrderId, true);
    const {data: restaurant} = useRestaurant(menuOrder?.business);
    const orderFetchIntervalKey = useRef(null);
    const isOrderFinalized = menuOrderFinalizedStatuses.includes(menuOrder?.status);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(removeDpayPriceExpiryTime());
    }, []);

    /**
     * If order id exists, call the api to get details. If it doesn't exist, re-direct to order history.
     *
     */
    useEffect(() => {
        /*
         * Update level data after an order is placed and user navigates to this page.
         * If they navigate back nothing will change.
         */
        async function updateLevelData() {
            try {
                const levelData = await new UsersApi(getConfig(fullToken)).getLevel({
                    id: user.id,
                });
                dispatch(updateAccountLevel(levelData));
            } catch (err) {
                dispatch(await addError(err));
            }
        }

        if (!menuOrderId) {
            history("/menu-orders");
        } else {
            if (fullToken) {
                void updateLevelData();
            }
            void fetchAndRemoveMenuOrder();
        }
    }, [menuOrderId]);

    useEffect(() => {
        if (!isOrderFinalized) {
            orderFetchIntervalKey.current = setInterval(() => {
                if (!isLoading) {
                    refetch();
                }
            }, 5000);
        }
        return () => {
            if (orderFetchIntervalKey.current) {
                clearInterval(orderFetchIntervalKey.current);
            }
        };
    }, [isLoading, isOrderFinalized]);

    /**
     * Remove the just-completed order from the redux store & call our api to get the details for this menu order & save to state.
     *
     */
    async function fetchAndRemoveMenuOrder(): Promise<void> {
        for (const restaurantId in menuOrders) {
            if (menuOrders[restaurantId] === menuOrderId) {
                dispatch(removeMenuOrder(restaurantId));
            }
        }
    }

    // Mirrors the function on the backend, so that the values are consistent
    function calculateExpEarned() {
        const discountTypeOrderCount: Array<number> = menuOrder.discounts
            .filter((d) => d.type === OrderDiscountType.ORDER)
            .map((item) => item.amount);
        const discountTypeOrderFiat: number = discountTypeOrderCount.length
            ? discountTypeOrderCount.reduce((a, b) => a + b)
            : 0;
        const realSubtotal: number = menuOrder.subtotal - discountTypeOrderFiat;
        return Math.ceil(realSubtotal);
    }

    return (
        <div>
            <DevourTopNav text="My Orders" />
            <div className="order-success-page">
                {!isFetchedAfterMount &&
                    <div className="order-success-page_loader">
                        <div className="spinner" />
                    </div>
                }

                <div className="order-success-page_container">
                    {isFetchedAfterMount && menuOrder && restaurant &&
                    <>
                        {!cancelledList.includes(menuOrder.status) && <Toast
                            message={`By placing this order, you earned ${Math.round(calculateExpEarned())} GoVIP XP!`}
                            isOpen={showToast}
                            showButton={true}
                            buttonMessage={!props.menuOrderId ? "Order Again" : "Close"}
                            buttonFunction={() => {
                                if (!props.menuOrderId) {
                                    history(`${restaurant?.url}/${menuOrder.address?.placeId}`);
                                }
                                setShowToast(false);
                            }}
                            duration={5000}
                            removeMarginAdjustment={props.menuOrderId && true}
                        />}
                        <div className="order-success-page_back-button" onClick={() => history("/menu-orders")}>
                            <BiArrowBack />
                            <h5>
                            Back
                            </h5>
                        </div>
                        <div className="order-success-page_content">
                            <div className="order-success-page_content_left">
                                <MenuOrderSuccessTracker order={menuOrder} />
                                <OrderHistoryDetails order={menuOrder} />
                            </div>
                            <div className="order-success-page_content_right">
                                <MenuOrderSuccessSummary order={menuOrder} />
                            </div>
                        </div>
                    </>
                    }
                </div>
            </div>
        </div>
    );
}

export default MenuOrderSuccessPage;
