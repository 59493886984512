import {ReactElement, useEffect, useState} from "react";
import {GoArrowRight} from "react-icons/go";
import useWindowSize, {WindowBreakpointSizes} from "@/hooks/useWindowSize";
import {useLocation, useNavigate} from "react-router";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {Token} from "@devour/client";
import {useGetRedeemableNftOwnerships} from "@/hooks/useGetRedeemableNftOwnerships";
import GoVipRewardsInventoryDrawer from "@/components/goVip/GoVipRewardsInventoryDrawer";
import SignUpToast from "../SignUpToast";


interface GoVipRewardsBannerInfoCardMetadata {
    icon: string;
    text: string;
}

const goVipRewardsBannerInfo: GoVipRewardsBannerInfoCardMetadata[] = [
    {
        icon: "1. ",
        text: "Click on My Rewards to view your unopened Lootbox inventory",
    },
    {
        icon: "2. ",
        text: "Click on a Lootbox to claim the prize inside",
    },
    {
        icon: "3. ",
        text: "Confirm that you want to open (burn) the Lootbox to claim your reward",
    },
    {
        icon: "4. ",
        text: "Enjoy the prize inside!",
    },
];

function GoVipRewardsBanner(): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const windowBreakpoint = getWindowBreakpoint(useWindowSize()[0]);
    const history = useNavigate();
    const location = useLocation();
    const openNftRewardsDrawer = location.state?.openNftRewardsDrawer;
    const [
        showToast,
        setShowToast,
    ] = useState<boolean>(false);
    const [
        showDrawer,
        setShowDrawer,
    ] = useState<boolean>(false);
    const {data: redeemableNftsResponse} = useGetRedeemableNftOwnerships(fullToken as Token);

    useEffect(() => {
        if (openNftRewardsDrawer && redeemableNftsResponse && fullToken) {
            setShowDrawer(true);
            history({}, {replace: true}); // to remove location.state
        }
    }, [
        openNftRewardsDrawer,
        redeemableNftsResponse,
    ]);

    function getWindowBreakpoint(windowWidth: number): WindowBreakpointSizes {
        if (windowWidth < 768) {
            return WindowBreakpointSizes.SMALL;
        } else if (windowWidth >= 768 && windowWidth < 992) {
            return WindowBreakpointSizes.MEDIUM;
        } else if (windowWidth >= 992 && windowWidth <= 1285) {
            return WindowBreakpointSizes.LARGE;
        } else {
            return WindowBreakpointSizes.XLARGE;
        }
    }

    function handleToastDismissal(): void {
        setShowToast(false);
    }

    function toggleInventoryDrawer(): void {
        if (!fullToken) {
            setShowToast(true);
        } else {
            setShowDrawer(s => !s);
        }
    }

    function renderRewardBannerInfoLines(data: GoVipRewardsBannerInfoCardMetadata): ReactElement {
        return (
            <div
                className="go-vip-rewards_banner_content_right_info_line"
                key={data.text}
            >
                <div
                    className="go-vip-rewards_banner_content_right_info_line_text"
                >
                    {data.icon}</div>

                <div className="go-vip-rewards_banner_content_right_info_line_text">
                    {data.text}
                </div>
            </div>
        );
    }

    return (
        <div className="go-vip-rewards">
            <SignUpToast
                isOpen={showToast}
                onDismiss={handleToastDismissal}
            />

            {redeemableNftsResponse &&
                <GoVipRewardsInventoryDrawer
                    redeemableNfts={redeemableNftsResponse.nftOwnerships}
                    show={showDrawer}
                    toggle={toggleInventoryDrawer}
                />
            }

            <div className="go-vip-rewards_banner">
                <img className="go-vip-rewards_banner_image"
                    src={`${import.meta.env.VITE_CDN_URL}/images/govip-rewards-banner-${windowBreakpoint}.webp`}
                    alt={"govip-rewards-banner"}/>
                <div className="go-vip-rewards_banner_content">
                    <div className="go-vip-rewards_banner_content_left">
                        <h3>Redeem & Claim Rewards!</h3>
                        <div className="go-vip-rewards_banner_content_left_subheader">
                            Open a Lootbox in my rewards to claim prizes inside.
                        </div>
                        <button
                            className="go-vip-rewards_banner_content_left_btn reset-button"
                            onClick={toggleInventoryDrawer}
                        >
                            My Rewards
                            <GoArrowRight/>
                        </button>
                    </div>
                    <div className="go-vip-rewards_banner_content_right">
                        <div className="go-vip-rewards_banner_content_right_header">
                            How do I open my Lootbox Reward?
                        </div>
                        <div className="go-vip-rewards_banner_content_right_info">
                            {goVipRewardsBannerInfo.map(renderRewardBannerInfoLines)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GoVipRewardsBanner;
