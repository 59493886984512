import {ReactElement, ReactNode, useRef} from "react";
import classNames from "classnames";

interface DragScrollProps {
    children: ReactNode,
    className?: string,
    id?: string
}

/**
 * Generic component to enable scrolling by dragging the container
 * @param props
 * @constructor
 */
export default function DragScroll(props: DragScrollProps): ReactElement {
    const {children, className, ...dragScrollProps} = props;
    const containerRef = useRef<HTMLDivElement>(null);
    const isDraggingRef = useRef(false);
    const DRAG_THRESHOLD = 5; // Minimum distance to consider as a drag

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        const slider = containerRef.current;

        if (!slider) {
            return;
        }

        const startPos = {
            left: slider.scrollLeft,
            top: slider.scrollTop,
            x: e.clientX,
            y: e.clientY,
        };

        isDraggingRef.current = false;

        const handleMouseMove = (e: MouseEvent) => {
            const dx = e.clientX - startPos.x;
            const dy = e.clientY - startPos.y;

            if (Math.abs(dx) > DRAG_THRESHOLD) {
                isDraggingRef.current = true;
                slider.scrollLeft = startPos.left - dx;
                slider.scrollTop = startPos.top - dy;
            }
        };

        const handleMouseUp = () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
            document.removeEventListener("mouseleave", handleMouseUp);

            setTimeout(() => {
                isDraggingRef.current = false;
            }, 0); // Slight delay to reset the dragging flag
        };

        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
        document.addEventListener("mouseleave", handleMouseUp);
    };

    const handleClickCapture = (e: React.MouseEvent<HTMLDivElement>) => {
        if (isDraggingRef.current) {
            e.stopPropagation(); // Prevent click event propagation if dragging occurred
            e.preventDefault(); // Prevent default click action
        }
    };

    return (
        <div
            className={classNames("drag-scroll", props.className)}
            onMouseDown={handleMouseDown}
            onClickCapture={handleClickCapture}
            ref={containerRef}
            {...dragScrollProps}
        >
            {props.children}
        </div>
    );
}