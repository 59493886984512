import {ReactElement, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {IoWalletSharp} from "react-icons/io5";
import {formatGoVIPNames} from "@/utils/formatGoVIPNames";
import {FaGear} from "react-icons/fa6";
import {BsPersonFill} from "react-icons/bs";
import {useNavigate} from "react-router-dom";
import EditProfilePictureModal from "@/components/modals/EditProfilePictureModal";
import {logout} from "@/redux/auth/authActions";
import {FaMoon} from "react-icons/fa";
import useThemePreference from "@/hooks/useThemePreference";
import {ThemePreference} from "@/types/Theme";
import {BiLogOut} from "react-icons/bi";
import ToggleSwitch from "./ToggleSwitch";
import {IoIosHelpCircle} from "react-icons/io";
import {toggleChatOpen} from "@/redux/meta/metaActions";
import {sendMessageToOW} from "@/hooks/useOverwolfInterop";

interface Props {
    renderProfilePicture: () => ReactElement;
    toggleWalletDropdown: () => void;
}

function DevourAccountDropdownMenuBody(props: Props): ReactElement {

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: userProfileData} = useGetUserProfile(fullToken);
    const { isOnDarkMode, setTheme} = useThemePreference();
    const history = useNavigate();
    const dispatch = useDispatch();

    const [
        count,
        setCount,
    ] = useState<number>(0);
    const [
        editProfileModal,
        setEditProfileModal,
    ] = useState<boolean>(false);

    function toggleEditProfileModal() {
        setEditProfileModal(!editProfileModal);
    }

    function handleLogOut() {
        sendMessageToOW({type: "de:logout"});
        dispatch(logout());
        history("/");
    }

    const handleDarkModeToggle = () => {
        setTheme(isOnDarkMode
            ? ThemePreference.LIGHT
            : ThemePreference.DARK);
    };

    return (
        <>
            {fullToken &&
            <EditProfilePictureModal
                isOpen={editProfileModal}
                onClose={toggleEditProfileModal}
                onProfilePictureUpdate={() => setCount(count + 1)}
            />
            }
            <div className="devour-top-nav_dropdown_account_profile-info">
                {props.renderProfilePicture()}
                <p>
                    {formatGoVIPNames(userProfileData?.user?.firstName, userProfileData?.user?.lastName, userProfileData?.user?.nickname)}
                </p>
            </div>
            <hr/>
            <div className="devour-top-nav_dropdown_account_options">
                <div
                    className="devour-top-nav_dropdown_account_options_row"
                    onClick={() => history("/account")}
                >
                    <FaGear/>
                    <p>Account</p>
                </div>
                <button
                    className="reset-button devour-top-nav_dropdown_account_options_row"
                    onClick={toggleEditProfileModal}
                >
                    <BsPersonFill/>
                    <p>Edit Profile</p>
                </button>
                <button
                    className="reset-button devour-top-nav_dropdown_account_options_row"
                    onClick={props.toggleWalletDropdown}
                >
                    <IoWalletSharp/>
                    <p>Manage Wallets</p>
                </button>
                <button
                    className="reset-button devour-top-nav_dropdown_account_options_row"
                    onClick={() => dispatch(toggleChatOpen(true))}
                >
                    <IoIosHelpCircle/>
                    <p>Help Chat</p>
                </button>
                <div className="devour-top-nav_dropdown_account_options_row no-hover">
                    <FaMoon/>
                    <p>Dark Mode</p>
                    <div className="devour-top-nav_dropdown_account_options_row_input">
                        <ToggleSwitch onChange={handleDarkModeToggle} checked={isOnDarkMode}/>
                    </div>

                </div>
            </div>
            <hr/>
            <div
                className="devour-top-nav_dropdown_account_options_row devour-top-nav_dropdown_account_options_logout"
                onClick={handleLogOut}
            >
                <BiLogOut/>
                <p>Log Out</p>
            </div>
        </>
    );
}

export default DevourAccountDropdownMenuBody;
