import {OverwolfXPTransaction} from "@devour/client";
import {isDesktop} from "react-device-detect";
import GoVipOWTimeBasedRewardCard from "@/components/goVip/GoVipOWTimeBasedRewardCard";


interface Props {
    rewards: Array<OverwolfXPTransaction>;
}

export default function OverwolfGoVipTimeRewards(props: Props) {
    if (!props.rewards?.length) {
        return (
            <div className="overwolf-govip_rewards_time_empty">
                <img
                    src={`${import.meta.env.VITE_CDN_URL}/images/govip-ow-time-rewards-modal-empty.webp`}
                    alt="Overwolf Time Rewards Empty"
                />
                <h5>Haven’t played enough?</h5>
                <span>
                    No XP yet, but the more you play, the more you earn. {isDesktop && <br/>}Jump into a game and play for an hour to earn your first XP!
                </span>
            </div>
        );
    }

    return (
        <div className="overwolf-govip_rewards_time_list">
            {props.rewards.map((reward: OverwolfXPTransaction) =>
                <GoVipOWTimeBasedRewardCard
                    reward={reward}
                    key={reward.id}/>)}
        </div>
    );
}