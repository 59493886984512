import { useGate } from "statsig-react";
import MenuOrderSuccessPage from "./MenuOrderSuccess";
import MenuOrderSuccessPageOld from "./MenuOrderSuccessOld";
import { FC } from "react";

interface props {
    menuOrderId?: string;
}

// Temp intermediary component used to easily switch between the old and the new design of the MenuOrderSuccess component
const MenuOrderSuccessSwitcher: FC<props> = (props) => {
    const { value: shouldUseNewTracker, isLoading } = useGate(import.meta.env.VITE_ENABLE_NEW_ORDER_TRACKER ?? "enable_new_order_tracker");
    if (isLoading) {
        return <></>;
    }
    return shouldUseNewTracker ? <MenuOrderSuccessPage {...props} /> : <MenuOrderSuccessPageOld {...props} />;
};

export default MenuOrderSuccessSwitcher;
