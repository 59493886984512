import {useDispatch, useSelector} from "react-redux";
import React, {ButtonHTMLAttributes, ReactElement, useEffect, useState} from "react";
import {
    addError,
    decrementLoading, decrementModalCount,
    incrementLoading,
    incrementModalCount,
    toggleHideBottomNav,
} from "@/redux/meta/metaActions";
import classNames from "classnames";
import {FaArrowLeft} from "react-icons/fa6";
import {GetNftRewardResponse, NftOwnership, NftsApi, Token} from "@devour/client";
import FrameButton from "@/components/buttons/FrameButton";
import GoVipRewardNftCard from "@/components/goVip/GoVipRewardNftCard";
import {IStore} from "@/redux/defaultStore";
import getConfig from "@/utils/getConfig";
import GoVipRewardsSelectedNft from "@/components/goVip/GoVipRewardsSelectedNft";
import {useGetRedeemableNftOwnerships} from "@/hooks/useGetRedeemableNftOwnerships";
import useThemePreference from "@/hooks/useThemePreference";

interface Props {
    redeemableNfts: Array<NftOwnership>;
    show: boolean;
    toggle: () => void;
}

function GoVipRewardsInventoryDrawer(props: Props): ReactElement {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {refetch: refetchRedeemableNftOwnerships} = useGetRedeemableNftOwnerships(fullToken as Token);
    const { isOnDarkMode, getThemedImageUrl } = useThemePreference();
    const [
        selectedNft,
        setSelectedNft,
    ] = useState<NftOwnership>(undefined);
    const [
        selectedReward,
        setSelectedReward,
    ] = useState<GetNftRewardResponse>(undefined);
    const showSelectedNft = selectedNft && selectedReward;

    useEffect(() => {
        if (props.show) {
            void refreshNftTokenUri();
            dispatch(incrementModalCount()); // prevent the body from being scrollable when drawer is open
            setSelectedNft(undefined);
            setSelectedReward(undefined);
            toggleBottomNav(true);

        } else {
            toggleBottomNav(false);
        }

        return () => {
            toggleBottomNav(false);
            dispatch(decrementModalCount());
        };

    }, [props.show]);

    function toggleBottomNav(hide: boolean) {
        dispatch(toggleHideBottomNav(hide));
    }

    async function refreshNftTokenUri(): Promise<void> {
        try {
            await new NftsApi(getConfig(fullToken)).refreshNftTokenUriRaw();
        } catch {
            // fail silently
        } finally {
            await refetchRedeemableNftOwnerships();
        }
    }

    async function getSelectedNftReward(nft: NftOwnership): Promise<void> {
        if (!nft) {
            return;
        }

        dispatch(incrementLoading());
        try {
            const res = await new NftsApi(getConfig(fullToken)).getNftReward({
                id: nft.nftTrackerId,
            });
            setSelectedNft(nft);
            setSelectedReward(res);
        } catch (e) {
            await addError(e);
        } finally {
            dispatch(decrementLoading());
        }
    }

    function handleGoBack(e: React.MouseEvent | React.TouchEvent): void {
        e.stopPropagation();

        if (selectedReward) {
            setSelectedNft(undefined);
            setSelectedReward(undefined);
        } else {
            props.toggle();
        }
    }

    function renderEmptyState(): ReactElement {
        return (
            <>
                <div className="govip-rewards-drawer_inventory_subheader">
                    Open loot boxes to claim prizes!
                </div>
                <div className="govip-rewards-drawer_inventory_empty">
                    <div className="govip-rewards-drawer_inventory_empty_question-mark">
                        <img
                            src={getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/govip-rewards-question-mark.svg`)}
                            alt="GoVIP rewards question mark"
                        />
                    </div>
                    <h3>Empty Inventory</h3>
                    <div className="govip-rewards-drawer_inventory_empty_message">
                        Oops! There are no loot box NFTs in your inventory. Level up to 3 to nab a loot box NFT #1
                        provided by DevourGO!
                    </div>
                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        color={isOnDarkMode
                            ? "purple"
                            : "purple-blue-gradient"}
                        size="normal"
                        onClick={props.toggle}
                    >
                        Start Earning XP
                    </FrameButton>
                </div>
            </>
        );
    }

    return (
        <>
            <div
                className={classNames("govip-rewards-drawer-overlay", {
                    "is-active": props.show,
                })}
                onClick={props.toggle}
            />
            <div
                className={classNames("govip-rewards-drawer", {
                    "is-active": props.show,
                })}
            >
                <div className="govip-rewards-drawer_nav">
                    <div onClick={handleGoBack}>
                        <FaArrowLeft />
                        {selectedReward && "My Rewards"}
                    </div>
                </div>

                <div className={classNames(
                    "govip-rewards-drawer_reward-wrapper",
                    { "active": showSelectedNft },
                )}>
                    {showSelectedNft &&
                    <GoVipRewardsSelectedNft
                        reward={selectedReward}
                        ownership={selectedNft}
                        toggleDrawer={props.toggle}
                    />
                    }
                </div>
                {!showSelectedNft &&
                <div className="govip-rewards-drawer_inventory">
                    <div className="govip-rewards-drawer_inventory_header">
                        <h3>My Rewards</h3>
                        <span className="govip-rewards-drawer_inventory_header_count">{props.redeemableNfts?.length}</span>
                    </div>
                    {props.redeemableNfts?.length > 0
                        ? <>
                            <div className="govip-rewards-drawer_inventory_subheader">
                                    Select a loot box to redeem or transfer!
                            </div>
                            <div className="govip-rewards-drawer_inventory_nfts">
                                {props.redeemableNfts.map(nft => <div
                                    key={nft.id}
                                    className="govip-rewards-drawer_inventory_nfts_card"
                                    onClick={() => void getSelectedNftReward(nft)}
                                >
                                    <GoVipRewardNftCard nft={nft} />
                                </div>)}
                            </div>
                        </>
                        : renderEmptyState()}
                </div>
                }
            </div>
        </>
    );
}

export default GoVipRewardsInventoryDrawer;