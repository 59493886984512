
export enum OrderTrackerDataFullfillmentStatus {
    PendingVendor = "pending_vendor", // Order is waiting for vendor to accept. Can't be updated to this. Returned on POST /orders.
    PendingAssign = "pending_assign", // Order is waiting to be dispatched to a runner. Can't be updated to this. Returned on POST /orders.
    PendingMerchant = "pending_merchant", // Order is waiting for merchant to accept. Can't be updated to this. Returned on POST /orders.
    Scheduled = "scheduled", // Order is scheduled for future. Can't be updated to this. Returned on POST /orders.
    RunnerAssigned = "runner_assigned", // Runner has been assigned - payload required. You can send this multiple times if a new runner is assigned in the course of delivery. Any completed statuses by the previous runner will be rolled back on reassign.
    EnRoutePickup = "en_route_pickup", // Runner on way to pickup.
    ArrivedPickup = "arrived_pickup", // Runner arrived at pickup
    PickedUp = "picked_up", // Runner picked up order
    EnRouteDropoff = "en_route_dropoff", // Runner on way to dropoff
    ArrivedDropoff = "arrived_dropoff", // Runner arrived at drop off
    InProgress = "in_progress", // Order is in progress
    Completed = "completed", // Order is completed
    Cancelled = "cancelled", // Order is cancelled
}


export const pendingTrackingStatuses = [
    OrderTrackerDataFullfillmentStatus.PendingAssign,
    OrderTrackerDataFullfillmentStatus.PendingMerchant,
    OrderTrackerDataFullfillmentStatus.PendingVendor,
    OrderTrackerDataFullfillmentStatus.Cancelled,
];

export const cookingTrackingStatuses = [
    OrderTrackerDataFullfillmentStatus.Scheduled,
    OrderTrackerDataFullfillmentStatus.RunnerAssigned,
];

export const ongoingTrackingStatuses = [
    OrderTrackerDataFullfillmentStatus.InProgress,
    OrderTrackerDataFullfillmentStatus.EnRoutePickup,
    OrderTrackerDataFullfillmentStatus.ArrivedPickup,
    OrderTrackerDataFullfillmentStatus.PickedUp,
    OrderTrackerDataFullfillmentStatus.EnRouteDropoff,
];

export const completedTrackingStatuses = [
    OrderTrackerDataFullfillmentStatus.ArrivedDropoff,
    OrderTrackerDataFullfillmentStatus.Completed,
];


export interface OrderTrackerData {
    delivery_service: DeliveryService;
    merchant: Customer;
    customer: Customer;
    runner?: Runner;
    order: Order;
    fulfillment: Fulfillment;
    operator: Operator;
}

interface Customer {
    name: string;
    address: string;
    phone: string;
    delivery_instructions?: string;
    location: Location;
    territory?: string;
}

interface Location {
    type: string;
    coordinates: number[];
}

interface DeliveryService {
    name: string;
    icon: string;
    colors: Colors;
    footer_image: null;
    footer_link: null;
}

interface Colors {
    primary: string;
    secondary: null;
}

interface Fulfillment {
    status: OrderTrackerDataFullfillmentStatus;
    method: string;
    accepted_at: null;
    prep_time: number;
    created_at: Date;
    runner_assigned_at?: Date;
    runner_est_pickup_at?: Date;
    runner_arrived_pickup?: Date;
    runner_estimated_dropoff: Date;
    delivery_route: string;
    runner_picked_up?: Date;
    runner_arrived_dropoff?: Date;
    runner_dropped_off?: Date;
}

interface Operator {
    customer_support_tracking: boolean;
}

interface Order {
    id: string;
    order_number: string;
    details: Detail[];
    special_instructions: string;
    payment: Payment;
    dropoff_photo?: string;
}


interface Runner {
    name: string
    phone: string
    location: {
        type: string
        coordinates: number[]
    }
}

interface Detail {
    itemName: string;
    quantity: number;
    itemPrice: number;
    modifiersText: ModifiersText[];
}

interface ModifiersText {
    name: string;
    price: number;
}

interface Payment {
    subtotal: number;
    tip: number;
}
