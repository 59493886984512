import {GetNftRewardResponse, NftOwnership, NftRewardPrize, Token} from "@devour/client";
import Tooltip from "@/components/Tooltip";
import {ReactElement, useEffect, useState} from "react";
import {BsFillInfoCircleFill} from "react-icons/bs";
import {useDispatch, useSelector} from "react-redux";
import GoVipRedeemNftConfirmModal from "@/components/modals/GoVipRedeemNftConfirmModal";
import Toast from "@/components/Toast";
import GoVipRewardsSelectedNftFooter from "@/components/goVip/GoVipRewardsSelectedNftFooter";
import classNames from "classnames";
import {redeemRewardNft} from "@/utils/rewardNft/redeemRewardNft";
import {IStore} from "@/redux/defaultStore";
import {addError} from "@/redux/meta/metaActions";
import {useGetRedeemableNftOwnerships} from "@/hooks/useGetRedeemableNftOwnerships";
import {useGetNftOwnershipsForUser} from "@/hooks/useGetNftOwnershipsForUser";
import {useGetTransactionsPaginated} from "@/hooks/useGetTransactionsPaginated";

interface Props {
    reward: GetNftRewardResponse;
    ownership: NftOwnership;
    toggleDrawer: () => void;
}

export enum RewardNftRedemptionState {
    NOT_INITIATED,
    IN_PROGRESS,
    DONE,
}

function GoVipRewardsSelectedNft(props: Props) {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const {reward} = props;
    const rewardMetadata = reward.nftReward;
    const prizes = reward.prizes;

    const [
        showConfirmModal,
        setShowConfirmModal,
    ] = useState<boolean>(false);
    const [
        showToast,
        setShowToast,
    ] = useState<boolean>(false);
    const [
        prizeWon,
        setPrizeWon,
    ] = useState<NftRewardPrize>(undefined);
    const [
        redemptionState,
        setRedemptionState,
    ] = useState<RewardNftRedemptionState>(RewardNftRedemptionState.NOT_INITIATED);
    const [
        revealPrize,
        setRevealPrize,
    ] = useState<boolean>(false);
    const {refetch: refetchRedeemableNftOwnerships} = useGetRedeemableNftOwnerships(fullToken as Token);
    const {refetch: refetchNftOwnerships} = useGetNftOwnershipsForUser(fullToken as Token);
    const {refetch: refetchTransactionsPaginated} = useGetTransactionsPaginated(fullToken, currentUser?.user?.id);

    const sparklingEffectTimeout: number = rewardMetadata.transitionEffectEnabled
        ? 1500
        : 0;
    const rewardImage = rewardMetadata.image?.url ?? import.meta.env.VITE_CDN_URL + "/images/govip-nft-rewards-default-image.gif";

    useEffect(() => {
        resetProgress();
    }, []);

    function resetProgress(): void {
        setPrizeWon(undefined);
        setRedemptionState(RewardNftRedemptionState.NOT_INITIATED);
        setRevealPrize(false);
        setShowToast(false);
    }

    function toggleRedeemConfirmModal(open: boolean = false): void {
        setShowConfirmModal(open);
    }

    async function onConfirmRedeem(): Promise<void> {
        setShowToast(true);
        setShowConfirmModal(false);
        setRedemptionState(RewardNftRedemptionState.IN_PROGRESS);
        let prize: NftRewardPrize;

        try {
            prize = await redeemRewardNft(props.ownership.nftId, rewardMetadata, fullToken);
            setRevealPrize(true);
            setShowToast(false);
            setTimeout(() => {
                setPrizeWon(prize);
                setRedemptionState(RewardNftRedemptionState.DONE);
            }, sparklingEffectTimeout);
        } catch (e) {
            resetProgress();
            dispatch(await addError(e));
        } finally {
            void refetchRedeemableNftOwnerships();
            void refetchNftOwnerships();
            if (prize?.amount > 0) {
                void refetchTransactionsPaginated();
            }
        }
    }

    function handleToastDismissal(): void {
        setShowToast(false);
    }

    function renderTooltipContent(): ReactElement {
        const outsideContent: ReactElement =
            <div className="govip-rewards-drawer_reward_tooltip_label">
                <BsFillInfoCircleFill />
                What's inside?
            </div>;
        return (
            <Tooltip
                containerElementClassname="govip-rewards-drawer_reward"
                outsideContent={outsideContent}
                className="govip-rewards-drawer_reward_tooltip"
            >
                <div className="govip-rewards-drawer_reward_tooltip_header">
                    <BsFillInfoCircleFill />
                    What's Inside the Loot Box?
                </div>

                <div className="govip-rewards-drawer_reward_tooltip_info">
                    Each prize in our collection is accompanied by a specified quantity limit. A prize will be randomly
                    selected from below.
                </div>

                <div className="govip-rewards-drawer_reward_tooltip_prizes">
                    <div className="govip-rewards-drawer_reward_tooltip_prizes_row row-header">
                        <span>Prize</span>
                        <span>Quantity</span>
                    </div>
                    <hr/>
                    {prizes.map(prize => <div
                        className="govip-rewards-drawer_reward_tooltip_prizes_row"
                        key={prize.id}
                    >
                        <span className="govip-rewards-drawer_reward_tooltip_prizes_row_name">
                            {prize.name}
                        </span>
                        <span className="govip-rewards-drawer_reward_tooltip_prizes_row_quantity">
                            {prize.quantity}
                        </span>
                    </div>)}
                </div>
            </Tooltip>
        );
    }

    function renderPrizeInfo(): ReactElement {
        const today = new Date();
        const month = today.toLocaleString("en-us", { month: "short" });
        const day = today.getDate();
        const year = today.getFullYear();
        const prizeName = prizeWon.prizeRevealName;
        const description = prizeWon.description
            ? prizeWon.description
            : `You have successfully redeemed ${rewardMetadata.nftTracker.name} NFT and claimed ${prizeName}. 
            ${prizeWon.amount > 0
        ? `${prizeName} will be loaded onto your FUEL Account. Enjoy!`
        : "You'll receive an email from DevourGO admins within the next 2 business days to claim your prize.Enjoy!"}`;

        return (
            <div className="govip-rewards-drawer_reward_info">
                <div className="govip-rewards-drawer_reward_info_origin">
                    <span>Prize</span>
                </div>
                <div className="govip-rewards-drawer_reward_info_title">{prizeName}</div>
                <div className="govip-rewards-drawer_reward_info_nftId">
                    {`Redeemed on ${month} ${day}, ${year}`}</div>
                <div className="govip-rewards-drawer_reward_info_description">{description}</div>
            </div>
        );
    }

    function getRewardImageUrl(): string {
        return redemptionState === RewardNftRedemptionState.NOT_INITIATED || !rewardMetadata.redemptionAnimation
            ? rewardImage
            : rewardMetadata.redemptionAnimation.url;
    }

    return (
        <>
            <Toast
                message="Please DO NOT refresh! We are getting your prize ready."
                isOpen={showToast}
                showButton={false}
                onDismiss={handleToastDismissal}
                variant="warning"
                remainIndefinitely={true}
                removeMarginAdjustment={true}
            />
            <GoVipRedeemNftConfirmModal
                isOpen={showConfirmModal}
                redeem={() => void onConfirmRedeem()}
                toggle={() => toggleRedeemConfirmModal()}
            />
            <div className="govip-rewards-drawer_reward">
                <div className="govip-rewards-drawer_reward_top">
                    <div className={classNames(
                        "govip-rewards-drawer_reward_top_image-container",
                        "winning-image", {
                            "show": prizeWon,
                        },
                    )}>
                        {prizeWon &&
                        <img
                            src={prizeWon.winningImage?.url ?? rewardImage}
                            alt="NFT Prize"
                            className="prize-img"
                        />
                        }
                    </div>

                    <div className={classNames(
                        "govip-rewards-drawer_reward_top_image-container",
                        "reward-image", {
                            "show": !prizeWon,
                        },
                    )}>
                        <img
                            src={getRewardImageUrl()}
                            alt="NFT Reward"
                        />

                        <img
                            src={import.meta.env.VITE_CDN_URL + "/images/govip-rewards-light-effect.gif"}
                            alt="Light Effect"
                            className={classNames("light-effect", {
                                "show": revealPrize && !prizeWon && rewardMetadata.transitionEffectEnabled,
                            })}
                        />
                    </div>

                    {!revealPrize && renderTooltipContent()}
                </div>
                {prizeWon
                    ? renderPrizeInfo()
                    : <div className="govip-rewards-drawer_reward_info">
                        <div className="govip-rewards-drawer_reward_info_origin">
                            <span>{rewardMetadata.origin}</span>
                        </div>
                        <div className="govip-rewards-drawer_reward_info_title">{rewardMetadata.title}</div>
                        <div className="govip-rewards-drawer_reward_info_nftId">{`#${props.ownership.nftId}`}</div>
                        <div className="govip-rewards-drawer_reward_info_description">
                            {rewardMetadata.description}
                        </div>
                    </div>
                }

            </div>

            <div className="govip-rewards-drawer_footer">
                <GoVipRewardsSelectedNftFooter
                    toggleConfirmModal={toggleRedeemConfirmModal}
                    redemptionState={redemptionState}
                    prize={prizeWon}
                    toggleDrawer={props.toggleDrawer}
                />
            </div>
        </>

    );
}

export default GoVipRewardsSelectedNft;