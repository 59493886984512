import {ReactElement, useContext} from "react";
import {HandoffOptions} from "@devour/client";
import {useDispatch} from "react-redux";
import {toggleOrderHandoff} from "@/redux/meta/metaActions";
import {RestaurantContext} from "../context/RestaurantContext";
import HandoffToggle from "@/components/HandoffToggle";
import {useRestaurant} from "@/hooks/useRestaurant";

function RestaurantHandoffOptions(): ReactElement {

    const dispatch = useDispatch();
    const {restaurantId} = useContext(RestaurantContext);
    const {data: restaurant} = useRestaurant(restaurantId);

    if (!restaurant) {
        return null;
    }

    return (
        <HandoffToggle
            onHandoffToggle={(option: HandoffOptions) => dispatch(toggleOrderHandoff(option))}
            disabledDelivery={!restaurant.handoffOptions.includes(HandoffOptions.DELIVERY)}
            disabledPickup={!restaurant.handoffOptions.includes(HandoffOptions.PICKUP)}
        />
    );
}

export default RestaurantHandoffOptions;
