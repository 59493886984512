import {useQuery} from "@tanstack/react-query";
import {
    BusinessesApi,
    HandoffOptions,
    SearchRestaurantsResponse, Token,
} from "@devour/client";
import getConfig from "@/utils/getConfig";

/**
 *
 * @param placeId
 * @param handoff
 * @param offset
 * @param limit
 * @param search
 * @param taxonomyIds
 */
async function fetchRestaurants(placeId: string, handoff: HandoffOptions, offset: number, limit: number, search: string, taxonomyIds: string[]): Promise<SearchRestaurantsResponse> {
    return await new BusinessesApi(getConfig()).searchRestaurants({
        offset: offset,
        limit: limit,
        placeId: placeId,
        handoff: handoff,
        search: search,
        taxonomyIds: taxonomyIds,
    });
}

function searchRestaurantsQuery(placeId: string, handoff: HandoffOptions, offset: number, limit: number, search: string, taxonomyIds: string[], fullToken?: Token) {
    return {
        queryKey: [
            "user-get-restaurants",
            offset,
            limit,
            placeId,
            handoff,
            search,
            taxonomyIds?.join("-"),
            fullToken?.id ?? "unauthenticated",
        ],
        queryFn: () => fetchRestaurants(placeId, handoff, offset, limit, search, taxonomyIds),
        staleTime: 60000,
        enabled: !!placeId,
    } as const;
}

export function useSearchRestaurants(placeId: string, handoff: HandoffOptions, offset?: number, limit?: number, search?: string, taxonomyIds?: string[], fullToken?: Token) {
    return useQuery(searchRestaurantsQuery(placeId, handoff, offset, limit, search, taxonomyIds, fullToken));
}
