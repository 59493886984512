import { useLayoutEffect, useState } from "react";

export enum WindowBreakpointSizes {
    XSMALL = "xs",
    SMALL = "sm",
    MEDIUM = "md",
    LARGE = "lg",
    XLARGE = "xlg",
}

export function getScreenWidthStr(windowWidth): WindowBreakpointSizes {
    if (windowWidth >= 1920) {
        return WindowBreakpointSizes.XLARGE;
    } if (windowWidth >= 1280) {
        return WindowBreakpointSizes.LARGE;
    } if (windowWidth >= 768) {
        return WindowBreakpointSizes.MEDIUM;
    } if (windowWidth >= 414) {
        return WindowBreakpointSizes.SMALL;
    }

    return WindowBreakpointSizes.XSMALL;
}

function useWindowSize() {
    const [
        size,
        setSize,
    ] = useState([
        0,
        0,
    ]);

    useLayoutEffect(() => {
        function updateSize() {
            setSize([
                window.innerWidth,
                window.innerHeight,
            ]);
        }

        window.addEventListener("resize", updateSize);
        updateSize();

        return () => window.removeEventListener("resize", updateSize);
    }, []);

    return size;
}

export default useWindowSize;
