/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The type of game event
 * @export
 * @enum {string}
 */
export enum OWGameEventType {
    KILL = 'KILL',
    ASSIST = 'ASSIST',
    DEATH = 'DEATH',
    DEFEAT = 'DEFEAT',
    MATCHEND = 'MATCH_END',
    MATCHENDVICTORY = 'MATCH_END_VICTORY',
    MATCHSTART = 'MATCH_START',
    HEADSHOT = 'HEADSHOT',
    PLAYTIME = 'PLAYTIME'
}

export function OWGameEventTypeFromJSON(json: any): OWGameEventType {
    return OWGameEventTypeFromJSONTyped(json, false);
}

export function OWGameEventTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OWGameEventType {
    return json as OWGameEventType;
}

export function OWGameEventTypeToJSON(value?: OWGameEventType | null): any {
    return value as any;
}

