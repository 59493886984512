import {CSSProperties, FC, PropsWithChildren, ReactElement, ReactNode, useEffect, useState} from "react";
import {isDesktop} from "react-device-detect";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import classNames from "classnames";
import {IoClose} from "react-icons/io5";
import {ToastPosition, ToastVariant, variantsConfig} from "@/types/ToastConfig";
import {BrandMapColorTheme} from "@devour/client";
import useThemePreference from "@/hooks/useThemePreference";

export interface ToastProps {
    variant: ToastVariant;
    message: string | ReactNode;
    isOpen: boolean;
    forceWidth?: string;
    duration?: number;
    showButton?: boolean;
    buttonMessage?: string;
    buttonFunction?: () => void | Promise<void>; // only fires when the button is clicked
    onDismiss?: () => void | Promise<void>; // always fires when toast is dismissed
    remainIndefinitely?: boolean;
    toastPosition?: ToastPosition;
    removeMarginAdjustment?: boolean;
    backgroundImageUrl?: string;
    icon?: FC;
    textColor?: BrandMapColorTheme;
    forceDarkMode?: boolean;
    className?: string;
    style?: CSSProperties;
}

function Toast(props: PropsWithChildren<ToastProps>): ReactElement {
    const { isOnDarkMode } = useThemePreference();

    const Icon: FC = props.icon;

    // This is to handle remain indefinitely Toasts persisting
    const [initialToastDisplayed, setInitialToastDisplayed] = useState<boolean>(false);

    const [dismissCalled, setDismissCalled] = useState<boolean>(false);
    const [id, setId] = useState<string>("");
    const [isDarkMode, setIsDarkMode] = useState<boolean>(props.forceDarkMode ?? isOnDarkMode);
    const sideBarOpen = useSelector((store: IStore) => store.metaStore.sidebarOpen);

    const textColor = props.textColor === BrandMapColorTheme.LIGHT
        ? "white"
        : "black";
    /**
     * See:
     * https://stackoverflow.com/questions/61339668/screen-orientation-in-reactjs
     *
     * Detects if landscape or portrait, adds a bottom margin to the Toast if portrait to avoid it
     * sitting on top of the nav bar.
     * Reason we need detection is that in the GoVIP game screens, which are all in landscape
     * mode, we don't want this margin to be applied.
     */
    const isLandscape = () => window.matchMedia("(orientation:landscape)").matches,
        [
            orientation,
            setOrientation,
        ] = useState<"landscape" | "portrait">(isLandscape()
            ? "landscape"
            : "portrait"),
        onWindowResize = () => {
            clearTimeout(window.resizeLag);
            window.resizeLag = setTimeout(() => {
                delete window.resizeLag;
                setOrientation(isLandscape()
                    ? "landscape"
                    : "portrait");
            }, 200);
        };

    useEffect(() => (
        onWindowResize(),
        window.addEventListener("resize", onWindowResize),
        () => window.removeEventListener("resize", onWindowResize)
    ), []);

    useEffect(() => {
        if (document.body.classList.contains("brand-light")) {
            setIsDarkMode(false);
        } else if (document.body.classList.contains("brand-dark")) {
            setIsDarkMode(true);
        }
    }, []);

    useEffect(() => {
        if (props.isOpen) {
            setInitialToastDisplayed(true);

            setId(toast(
                () => <div className={classNames("toast_content", `theme-${props.variant}`, {
                    "dark": isDarkMode,
                })}>
                    <div className="toast_content_message">
                        {Icon
                            ? <Icon />
                            : variantsConfig[props.variant]?.()?.icon
                                ? <img
                                    src={variantsConfig[props.variant]().icon}
                                    alt="toast-icon"
                                />
                                : null}
                        {props.message}
                    </div>
                    <div className="toast_content_button">
                        {props.showButton
                            ? <button
                                className="toast_content_button_function"
                                onClick={async () => {
                                    // call button function if there is one
                                    if (props.buttonFunction) {
                                        await props.buttonFunction();
                                    }
                                    // call dismissal
                                    setDismissCalled(true);
                                }}
                            >
                                {props.buttonMessage
                                    ? props.buttonMessage
                                    : "Dismiss"}
                            </button>
                            : <IoClose
                                className="toast_content_button_dismissal"
                                onClick={() => setDismissCalled(true)}
                            />
                        }
                    </div>
                </div>
                , {
                    duration: Infinity,
                    position: props?.toastPosition
                        ? props.toastPosition
                        : "bottom-center",
                    className: `toast ${props.className}`,
                    style: {
                        ...variantsConfig[props.variant]?.(isDarkMode, props.backgroundImageUrl, textColor)?.styles,
                        padding: "1rem 0.625rem",
                        borderRadius: "0.625rem",
                        marginBottom: isDesktop
                            ? "3rem"
                            : orientation === "portrait"
                                ? "4rem"
                                : "0",
                        marginLeft: !props.removeMarginAdjustment && isDesktop && !sideBarOpen
                            ? "15.625rem"
                            : "0", // this adjusts the Toast for the sidebar, but can be overridden if needed
                        width: props.forceWidth
                            ? props.forceWidth
                            : "fit-content",
                        ...props.style,
                    },
                },
            ));

            if (!props.remainIndefinitely) {
                // closing toast this way in order to run onDismiss functions, after the duration it will call dismissal
                setTimeout(() => setDismissCalled(true), props.duration);
            }
        } else if (!props.isOpen) {
            toast.dismiss(id);
        }

    }, [
        props.isOpen,
        props.toastPosition,
    ]);

    useEffect(() => {
        if (!props.isOpen && props.remainIndefinitely && initialToastDisplayed) {
            setDismissCalled(true);
            setInitialToastDisplayed(false);
        }

    }, [
        props.isOpen,
        props.remainIndefinitely,
        initialToastDisplayed,
    ]);

    useEffect(() => {
        // if dismiss was called and there is a dismiss function, call it and dismiss the toast
        if (dismissCalled) {
            if (props.onDismiss) {
                void props.onDismiss();
            }
            toast.dismiss(id);
            setDismissCalled(false);
        }
    }, [dismissCalled]);

    useEffect(() => {

        return () => {
            // clean up all toasts when component (page) unmounts, e.g. navigation
            toast.dismiss();
        };

    }, []);

    return null;
}

Toast.defaultProps = {
    variant: "success",
    duration: 3500,
};

export default Toast;


