/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccountExperienceTransaction,
    AccountExperienceTransactionFromJSON,
    AccountExperienceTransactionFromJSONTyped,
    AccountExperienceTransactionToJSON,
    AccountExperienceTransactionSubject,
    AccountExperienceTransactionSubjectFromJSON,
    AccountExperienceTransactionSubjectFromJSONTyped,
    AccountExperienceTransactionSubjectToJSON,
    AccountExperienceTransactionType,
    AccountExperienceTransactionTypeFromJSON,
    AccountExperienceTransactionTypeFromJSONTyped,
    AccountExperienceTransactionTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface OverwolfXPTransaction
 */
export interface OverwolfXPTransaction extends AccountExperienceTransaction {
    /**
     * A list of games associated with the XP transaction.
     * @type {Array<object>}
     * @memberof OverwolfXPTransaction
     */
    games?: Array<object>;
}

export function OverwolfXPTransactionFromJSON(json: any): OverwolfXPTransaction {
    return OverwolfXPTransactionFromJSONTyped(json, false);
}

export function OverwolfXPTransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): OverwolfXPTransaction {
    return json;
}

export function OverwolfXPTransactionToJSON(value?: OverwolfXPTransaction | null): any {
    return value;
}


